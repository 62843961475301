import router from "@/routes";
import posthog from "posthog-js";
import {
  languages,
  languagesInitial,
  routeNames
} from "@/constants";
import { authService } from "@/services";

export const actions = {
  async logOut(
    { commit, dispatch, rootGetters, rootState },
    hardLogoutSSOUsers = false
  ) {
    const isUserLoggedInViaSSO =
      !rootGetters?.["user/isLoggedInViaThePlatform"];

    if (!isUserLoggedInViaSSO) {
      await authService.deauthenticate();
    }

    posthog.reset();

    // Expire/Remove cookie for authentication of download/print pages (php) - PD-13218 & PD-13219
    document.cookie = `__Host_token=${rootState?.auth?.token};Path=/;Secure;SameSite=Strict; ; expires= Thu, 21 Aug 2014 20:00:00 UTC`;

    commit("loader/setIsAppLoading", true, { root: true });
    localStorage.clear();
    commit("user/resetUser", null, { root: true });
    commit("platformSettings/resetPlatformSettings", null, {
      root: true
    });
    commit("userSettings/resetUserSettings", null, {
      root: true
    });
    commit("auth/resetAuth", null, {
      root: true
    });
    commit("thirdParty/resetThirdParty", null, {
      root: true
    });
    commit("actions/resetActions", null, {
      root: true
    });
    commit("roles/resetRoles", null, {
      root: true
    });
    commit("company/resetCompany", null, {
      root: true
    });
    commit("country/resetCountries", null, {
      root: true
    });
    commit("posthog/resetPosthogStore", null, {
      root: true
    });
    commit("permissions/resetPermissions", null, {
      root: true
    });
    commit("tasks/resetTasks", null, {
      root: true
    });

    await dispatch("platformSettings/fetchPublicPlatformSettings", null, {
      root: true
    });

    if (router?.currentRoute?.value?.name !== routeNames.LOGIN) {
      await router.push({ name: routeNames.LOGIN });
    }

    commit("loader/setIsAppLoading", false, {
      root: true
    });

    if (isUserLoggedInViaSSO && hardLogoutSSOUsers) {
      window.location = `${window?.location?.origin}/logout.php`;
    }
  },
  async logIn({ commit, dispatch }, payload) {
    const response = await authService.authenticate(payload);
    const formTranslations =
      Array.isArray(response.data.formTranslations) &&
      response.data.formTranslations?.length
        ? response.data.formTranslations
        : [languages[languagesInitial.ENGLISH]];
    const formDefaultLanguage = languages[response.data.contact.language]
      ? languages[response.data.contact.language]
      : languages[languagesInitial.ENGLISH];

    commit("SET_FORM_TRANSLATIONS", formTranslations, {
      root: true
    });
    commit("auth/setToken", response.data.authToken, {
      root: true
    });

    // Add cookie for authentication of download/print pages (php) - PD-13218 & PD-13219
    document.cookie = `__Host_token=${response.data.authToken};Path=/;Secure;SameSite=Strict;`;

    commit("user/setFormDefaultLanguage", formDefaultLanguage, {
      root: true
    });
    commit("SET_FORM_TRANSLATIONS", response.data.formTranslations, {
      root: true
    });
    commit("company/setCompanyViews", response.data.platformViews, {
      root: true
    });
    commit("user/setPlatformVersion", response.data.platformVersion, {
      root: true
    });
    commit("user/setUserFirstName", response.data.userFirstName, {
      root: true
    });
    commit("user/setUserLastName", response.data.userLastName, {
      root: true
    });
    commit("user/setRegistrationStatus", response.data.userRegistrationStatus, {
      root: true
    });
    commit("user/setUserTourStatus", response.data.userTourStatus, {
      root: true
    });
    commit("user/setUserFullName", response.data.userFullName, {
      root: true
    });
    commit("user/setEmail", response.data.email, {
      root: true
    });
    commit("user/setMemberGroupId", response.data.memberGroupId, {
      root: true
    });
    commit("user/setLandingPage", response.data.landingPage, {
      root: true
    });
    commit("user/setPasswordCreated", response.data.passwordCreated, {
      root: true
    });
    commit("company/setCompanyId", response.data.companyId, {
      root: true
    });
    commit("user/setRoles", response.data.roles, {
      root: true
    });
    commit("permissions/setPermissions", response.data.permissions, {
      root: true
    });
    commit("user/setLoginType", response.data.loginType, {
      root: true
    });
    commit("user/setUserId", response.data.memberId, {
      root: true
    });
    commit("company/setCompanyName", response.data.companyName, {
      root: true
    });
    commit("company/setTenantId", response.data.tenantId, {
      root: true
    });
    commit("user/setThirdPartyId", response.data.thirdPartyId, {
      root: true
    });

    dispatch("auth/fetchEB360AppData", null, { root: true });

    return response;
  },
  async fetchEB360AppData({ dispatch, commit, rootGetters }) {
    commit("loader/setIsAppLoading", true, { root: true });
    if (!rootGetters?.["user/isUserThirdParty"]) {
      await dispatch("platformSettings/fetchPlatformUserList", null, {
        root: true
      });
      await dispatch("company/fetchCompanyUsers", null, {
        root: true
      });
    }
    await dispatch("platformSettings/fetchPlatformSettings", null, {
      root: true
    });
    await dispatch("company/fetchCompanyFeatures", null, {
      root: true
    });
    commit("loader/setIsAppLoading", false, {
      root: true
    });

    await dispatch("languages/fetchLanguages", null, {
      root: true
    });

    commit("posthog/setDefaultProperties", null, {
      root: true
    });
  }
};
