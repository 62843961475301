import { widgetsService } from "@/services";

export const actions = {
  async fetchWidgetList({ commit, rootState }) {
    const { tenantId, companyId } = rootState.company;
    try {
      commit("widget/setIsWidgetListLoading", true, {
        root: true
      });

      const data = await widgetsService.fetchWidgetList({
        tenantId,
        companyId
      });

      commit("widget/setWidgetList", data, {
        root: true
      });

      return data;
    } catch (error) {
      return error;
    } finally {
      commit("widget/setIsWidgetListLoading", false, {
        root: true
      });
    }
  }
};
