import { endpoints } from "@/constants";
import { HTTP, makeAuthorizationHeader } from "@/utils";
import { makeAuthUserObject } from "./dto/auth.dto";

const authenticate = async (formData) => {
  const response = await HTTP(
    "post",
    endpoints.AUTHENTICATION,
    formData,
    makeAuthorizationHeader()
  );
  response.data = makeAuthUserObject(response.data);
  return response;
};

const deauthenticate = async () =>
  HTTP("post", endpoints.DEAUTHENTICATE, [], makeAuthorizationHeader());

const forgotPassword = (formData) =>
  HTTP("post", endpoints.FORGOT_PASSWORD, formData, makeAuthorizationHeader());

const changePassword = (formData) =>
  HTTP("post", endpoints.CHANGE_PASSWORD, formData, makeAuthorizationHeader());

export const authService = {
  authenticate,
  deauthenticate,
  forgotPassword,
  changePassword
};
