import { thirdPartyService } from "@/services";
import { isEmpty } from "lodash";
import { ultimateBeneficialOwnersKeysAPIDTO } from "@/constants";

export const actions = {
  async fetchUBOEntityData({ commit, rootState }, payload) {
    try {
      commit("uboEntity/setUBOEntityDataIsLoading", true, {
        root: true
      });

      const data = await thirdPartyService.fetchUBOEntityData({
        [ultimateBeneficialOwnersKeysAPIDTO.COMPANY_ID]:
          rootState.company.companyId,
        ...payload
      });

      if (!isEmpty(data)) {
        commit("uboEntity/setUBOEntityData", data, {
          root: true
        });
      } else {
        commit(
          "uboEntity/setUBOEntityErrorMessage",
          "No results found",
          { root: true }
        );
      }

      return data;
    } catch ({ status }) {
      const errorMessage = `Something went wrong, please try again.${
        status ? ` Error with status of ${status}.` : ""
      } If the issue persists please contact support`;

      commit(
        "uboEntity/setUBOEntityErrorMessage",
        errorMessage,
        {
          root: true
        }
      );
    } finally {
      commit("uboEntity/setUBOEntityDataIsLoading", false, {
        root: true
      });
    }
  },
  async fetchUBODueDiligenceReportData({ commit, rootState }, payload) {
    try {
      commit(
        "uboEntity/setUBODueDiligenceReportDataIsLoading",
        true,
        {
          root: true
        }
      );

      const { data } = await thirdPartyService.fetchUBODueDiligenceReportData({
        companyId: rootState.company.companyId,
        ...payload
      });

      if (!isEmpty(data)) {
        commit(
          "uboEntity/setUBODueDiligenceReportData",
          data,
          {
            root: true
          }
        );
      } else {
        commit(
          "uboEntity/setUBODueDiligenceErrorMessage",
          "No results found",
          { root: true }
        );
      }

      return data;
    } catch ({ status }) {
      const errorMessage = `Something went wrong, please try again.${
        status ? ` Error with status of ${status}.` : ""
      } If the issue persists please contact support`;

      commit(
        "uboEntity/setUBODueDiligenceErrorMessage",
        errorMessage,
        {
          root: true
        }
      );
    } finally {
      commit(
        "uboEntity/setUBODueDiligenceReportDataIsLoading",
        false,
        {
          root: true
        }
      );
    }
  }
};
