import {
  variableType,
  expectedDataEntitiesTypes,
  expectedDataTypes,
  expectedDataKeysDTO,
  expectedDataKeysAPIDTO,
  unsupportedExpectedDataSources
} from "@/constants";
import { makeOptionsForSelect } from "@/molecules/Select/Select.dto";
import {
  isExpectedDataEntityType,
  isExpectedDataPrimitiveType,
  isExpectedDataInstanceSubType,
  isExpectedDataInstanceType,
  makeUUID,
  makeMultiSelectType
} from "@/utils";
import { getActionOptionUniqueType } from "@/organisms/AddEditAction/AddEditAction.logic";

const makeOptionsDTO = ({ data } = {}) => {
  if (
    data?.[expectedDataKeysAPIDTO.DATA]?.[expectedDataKeysAPIDTO.SUB_TYPE] ===
    expectedDataEntitiesTypes.EDD_REPORT
  ) {
    return data?.[expectedDataKeysAPIDTO.DATA]?.[
      expectedDataKeysAPIDTO.OPTIONS
    ].map(({ text, value } = {}) => ({
      text,
      value: value?.[expectedDataKeysAPIDTO.EDD_REPORT_TYPE_ID]
    }));
  }

  return data?.[expectedDataKeysAPIDTO.DATA]?.[expectedDataKeysAPIDTO.OPTIONS];
};

const makeParsedExpectedDataDTO = ({
  name,
  attributes,
  _makeOptionsDTO = makeOptionsDTO
} = {}) => ({
  [expectedDataKeysDTO.NAME]: name,
  [expectedDataKeysDTO.ATTRIBUTES]:
    attributes?.reduce?.((acc, { type, data } = {}) => {
      if (data?.context?.source === unsupportedExpectedDataSources.USER) {
        return acc;
      }

      const baseExpectedData = {
        [expectedDataKeysDTO.TYPE]: type,
        [expectedDataKeysDTO.DATA]: {
          [expectedDataKeysDTO.TYPE]: data?.[expectedDataKeysAPIDTO.TYPE],
          [expectedDataKeysDTO.DATA]: {}
        }
      };

      if (type === variableType.PROXY) {
        baseExpectedData[expectedDataKeysDTO.TYPE] = variableType.VARIABLE;
        baseExpectedData[expectedDataKeysDTO.DATA][expectedDataKeysDTO.TYPE] =
          variableType.PROXY;
        baseExpectedData[expectedDataKeysDTO.DATA][expectedDataKeysDTO.DATA] = {
          [expectedDataKeysDTO.NAME]: data?.[expectedDataKeysAPIDTO.NAME],
          [expectedDataKeysDTO.ENTITY]: data?.[expectedDataKeysAPIDTO.ENTITY],
          [expectedDataKeysDTO.CONTEXT]: {
            [expectedDataKeysDTO.FORM_ID]:
              data?.[expectedDataKeysAPIDTO.CONTEXT]?.[
                expectedDataKeysAPIDTO.FORM_ID
              ]
          },
          [expectedDataKeysDTO.PROXY_NAME]:
            data?.[expectedDataKeysAPIDTO.PROXY_NAME],
          [expectedDataKeysDTO.ATTRIBUTE_NAME]:
            data?.[expectedDataKeysAPIDTO.ATTRIBUTE_NAME],
          [expectedDataKeysDTO.REFERENCE_URL]:
            data?.[expectedDataKeysAPIDTO.REFERENCE_URL],
          [expectedDataKeysDTO.IS_READ_ONLY]: true
        };
      } else {
        baseExpectedData[expectedDataKeysDTO.DATA][expectedDataKeysDTO.DATA] = {
          [expectedDataKeysDTO.NAME]:
            data?.[expectedDataKeysAPIDTO.DATA]?.[expectedDataKeysAPIDTO.NAME],
          [expectedDataKeysDTO.IS_ENUM]:
            data?.[expectedDataKeysAPIDTO.DATA]?.[
              expectedDataKeysAPIDTO.IS_ENUM
            ],
          [expectedDataKeysDTO.IS_READ_ONLY]:
            data?.[expectedDataKeysAPIDTO.DATA]?.[
              expectedDataKeysAPIDTO.IS_READ_ONLY
            ],
          [expectedDataKeysDTO.IS_CUSTOM_ATTRIBUTE]:
            data?.[expectedDataKeysAPIDTO.DATA]?.[
              expectedDataKeysAPIDTO.IS_CUSTOM_ATTRIBUTE
            ],
          [expectedDataKeysDTO.IDENTIFIER]:
            data?.[expectedDataKeysAPIDTO.DATA]?.[
              expectedDataKeysAPIDTO.IDENTIFIER
            ],
          [expectedDataKeysDTO.SUB_TYPE]:
            data?.[expectedDataKeysAPIDTO.DATA]?.[
              expectedDataKeysAPIDTO.SUB_TYPE
            ],
          [expectedDataKeysDTO.OPTIONS]: makeOptionsForSelect(
            undefined,
            _makeOptionsDTO({ data })
          ),
          ...(data?.[expectedDataKeysAPIDTO.DATA]?.hasOwnProperty([
            expectedDataKeysAPIDTO.IS_ARRAY
          ]) && {
            [expectedDataKeysDTO.IS_ARRAY]:
              data[expectedDataKeysAPIDTO.DATA][expectedDataKeysAPIDTO.IS_ARRAY]
          })
        };
      }

      acc.push(baseExpectedData);
      return acc;
    }, []) || []
});

const makeExpectedDataEntityBaseDTO = (expectedDataEntityType = {}) => ({
  [expectedDataKeysDTO.NAME]:
    expectedDataEntityType[expectedDataKeysAPIDTO.NAME],
  [expectedDataKeysDTO.ENTITY]:
    expectedDataEntityType[expectedDataKeysAPIDTO.ENTITY],
  [expectedDataKeysDTO.UNIQUE_TYPE]:
    expectedDataEntityType[expectedDataKeysAPIDTO.ENTITY]
});

const makeExpectedDataEntityDDQTaskDTO = ({
  expectedData = {},
  _makeExpectedDataEntityBaseDTO = makeExpectedDataEntityBaseDTO
} = {}) => ({
  ..._makeExpectedDataEntityBaseDTO(expectedData),
  [expectedDataKeysDTO.CONTEXT]: {
    [expectedDataKeysDTO.FORM_ID]:
      expectedData?.[expectedDataKeysAPIDTO.CONTEXT]?.[
        expectedDataKeysAPIDTO.FORM_ID
      ]
  },
  [expectedDataKeysDTO.REFERENCE_URL]:
    expectedData[expectedDataKeysAPIDTO.REFERENCE_URL],
  [expectedDataKeysDTO.REFERENCES]: {}
});

const makeExpectedDataEntityDDQFormDTO = ({
  expectedData = {},
  _makeExpectedDataEntityBaseDTO = makeExpectedDataEntityBaseDTO
} = {}) => ({
  ..._makeExpectedDataEntityBaseDTO(expectedData),
  [expectedDataKeysDTO.OPTIONS]:
    expectedData[expectedDataKeysAPIDTO.OPTIONS]?.map((option) => ({
      [expectedDataKeysDTO.TEXT]: option?.[expectedDataKeysAPIDTO.TEXT],
      [expectedDataKeysDTO.VALUE]: {
        [expectedDataKeysDTO.COMPANY_ID]:
          option?.[expectedDataKeysAPIDTO.VALUE]?.[
            expectedDataKeysAPIDTO.COMPANY_ID
          ],
        [expectedDataKeysDTO.DDQ_ID]:
          option?.[expectedDataKeysAPIDTO.VALUE]?.[
            expectedDataKeysAPIDTO.DDQ_ID
          ]
      }
    })) || []
});

const makeExpectedDataEntityTrainingCourseDTO = ({
  expectedData = {},
  _makeExpectedDataEntityBaseDTO = makeExpectedDataEntityBaseDTO
}) => ({
  ..._makeExpectedDataEntityBaseDTO(expectedData),
  [expectedDataKeysDTO.OPTIONS]:
    expectedData[expectedDataKeysAPIDTO.OPTIONS]?.map((option) => ({
      [expectedDataKeysDTO.TEXT]: option?.[expectedDataKeysAPIDTO.TEXT],
      [expectedDataKeysDTO.VALUE]: {
        [expectedDataKeysDTO.COMPANY_ID]:
          option?.[expectedDataKeysAPIDTO.VALUE]?.[
            expectedDataKeysAPIDTO.COMPANY_ID
          ],
        [expectedDataKeysDTO.COURSE_ID]:
          option?.[expectedDataKeysAPIDTO.VALUE]?.[
            expectedDataKeysAPIDTO.COURSE_ID
          ]
      }
    })) || []
});

const makeExpectedDataEntityEddReportTypeDTO = ({
  expectedData = {},
  _makeExpectedDataEntityBaseDTO = makeExpectedDataEntityBaseDTO
} = {}) => ({
  ..._makeExpectedDataEntityBaseDTO(expectedData),
  [expectedDataKeysDTO.OPTIONS]:
    expectedData?.[expectedDataKeysAPIDTO.OPTIONS]?.map?.((option) => ({
      [expectedDataKeysDTO.TEXT]: option?.[expectedDataKeysAPIDTO.TEXT],
      [expectedDataKeysDTO.VALUE]: {
        [expectedDataKeysDTO.COMPANY_ID]:
          option?.[expectedDataKeysAPIDTO.VALUE]?.[
            expectedDataKeysAPIDTO.COMPANY_ID
          ],
        [expectedDataKeysDTO.EDD_REPORT_TYPE_ID]:
          option?.[expectedDataKeysAPIDTO.VALUE]?.[
            expectedDataKeysAPIDTO.EDD_REPORT_TYPE_ID
          ]
      }
    })) || []
});

const makeExpectedDataEntityDTO = ({
  expectedData = {},
  _makeExpectedDataEntityBaseDTO = makeExpectedDataEntityBaseDTO,
  _makeExpectedDataEntityDDQTaskDTO = makeExpectedDataEntityDDQTaskDTO,
  _makeExpectedDataEntityDDQFormDTO = makeExpectedDataEntityDDQFormDTO,
  _makeExpectedDataEntityTrainingCourseDTO = makeExpectedDataEntityTrainingCourseDTO,
  _makeExpectedDataEntityEddReportTypeDTO = makeExpectedDataEntityEddReportTypeDTO
}) => {
  if (
    [
      expectedDataTypes.THIRD_PARTY,
      expectedDataTypes.IDD_IDD_PLUS,
      expectedDataTypes.TRAINING_ASSIGNMENT,
      expectedDataTypes.EDD
    ].some((value) => value === expectedData.entity)
  ) {
    return _makeExpectedDataEntityBaseDTO(expectedData);
  }

  if (expectedDataTypes.DDQ_TASK === expectedData.entity) {
    return _makeExpectedDataEntityDDQTaskDTO({ expectedData });
  }

  if (expectedDataTypes.DDQ_FORM === expectedData.entity) {
    return _makeExpectedDataEntityDDQFormDTO({ expectedData });
  }

  if (expectedDataTypes.TRAINING_COURSE === expectedData.entity) {
    return _makeExpectedDataEntityTrainingCourseDTO({ expectedData });
  }

  if (expectedDataTypes.EDD_REPORT === expectedData.entity) {
    return _makeExpectedDataEntityEddReportTypeDTO({ expectedData });
  }
};

const makeExpectedDataPrimitiveTypeDTO = (expectedDataPrimitiveType = {}) => {
  const type =
    expectedDataPrimitiveType?.[expectedDataKeysAPIDTO.SUB_TYPE] ||
    expectedDataPrimitiveType?.[expectedDataKeysAPIDTO.TYPE];
  return {
    [expectedDataKeysDTO.NAME]:
      expectedDataPrimitiveType[expectedDataKeysAPIDTO.NAME],
    [expectedDataKeysDTO.TYPE]:
      expectedDataPrimitiveType[expectedDataKeysAPIDTO.TYPE],
    [expectedDataKeysDTO.IS_ARRAY]:
      expectedDataPrimitiveType[expectedDataKeysAPIDTO.IS_ARRAY],
    [expectedDataKeysDTO.IS_ENUM]:
      expectedDataPrimitiveType[expectedDataKeysAPIDTO.IS_ENUM],
    ...(expectedDataPrimitiveType.hasOwnProperty(
      expectedDataKeysAPIDTO.SUB_TYPE
    ) && {
      [expectedDataKeysDTO.SUB_TYPE]:
        expectedDataPrimitiveType[expectedDataKeysAPIDTO.SUB_TYPE]
    }),
    ...(expectedDataPrimitiveType.hasOwnProperty(
      expectedDataKeysAPIDTO.OPTIONS
    ) && {
      [expectedDataKeysDTO.OPTIONS]:
        expectedDataPrimitiveType[expectedDataKeysAPIDTO.OPTIONS]
    }),
    [expectedDataKeysDTO.UNIQUE_TYPE]: expectedDataPrimitiveType[
      expectedDataKeysAPIDTO.IS_ARRAY
    ]
      ? makeMultiSelectType(type)
      : type,
    ...(expectedDataPrimitiveType.hasOwnProperty(
      expectedDataKeysAPIDTO.TEMPLATE_TYPE
    ) && {
      [expectedDataKeysDTO.TEMPLATE_TYPE]:
        expectedDataPrimitiveType[expectedDataKeysAPIDTO.TEMPLATE_TYPE]
    })
  };
};

const makeExpectedDataInstanceTypeDTO = ({
  expectedDataInstanceType = {},
  _getActionOptionUniqueType = getActionOptionUniqueType
} = {}) => ({
  [expectedDataKeysDTO.NAME]:
    expectedDataInstanceType[expectedDataKeysAPIDTO.NAME],
  [expectedDataKeysDTO.TYPE]:
    expectedDataInstanceType[expectedDataKeysAPIDTO.TYPE],
  [expectedDataKeysDTO.SUB_TYPE]:
    expectedDataInstanceType[expectedDataKeysAPIDTO.SUB_TYPE],
  [expectedDataKeysDTO.IS_ENUM]:
    expectedDataInstanceType[expectedDataKeysAPIDTO.IS_ENUM],
  [expectedDataKeysDTO.IS_ARRAY]:
    expectedDataInstanceType[expectedDataKeysAPIDTO.IS_ARRAY],
  [expectedDataKeysDTO.IS_CUSTOM_ATTRIBUTE]:
    expectedDataInstanceType[expectedDataKeysAPIDTO.IS_CUSTOM_ATTRIBUTE],
  [expectedDataKeysDTO.IDENTIFIER]:
    expectedDataInstanceType[expectedDataKeysAPIDTO.IDENTIFIER],
  ...(expectedDataInstanceType.hasOwnProperty(
    expectedDataKeysAPIDTO.TEMPLATE_TYPE
  ) && {
    [expectedDataKeysDTO.TEMPLATE_TYPE]:
      expectedDataInstanceType[expectedDataKeysAPIDTO.TEMPLATE_TYPE]
  }),
  ...(expectedDataInstanceType.hasOwnProperty(
    expectedDataKeysAPIDTO.TEMPLATE_NAME
  ) && {
    [expectedDataKeysDTO.TEMPLATE_NAME]:
      expectedDataInstanceType[expectedDataKeysAPIDTO.TEMPLATE_NAME]
  }),
  ...(expectedDataInstanceType.hasOwnProperty(
    expectedDataKeysAPIDTO.OPTIONS
  ) && {
    [expectedDataKeysDTO.OPTIONS]:
      expectedDataInstanceType[expectedDataKeysAPIDTO.OPTIONS]
  }),
  [expectedDataKeysDTO.UNIQUE_TYPE]: _getActionOptionUniqueType({
    subType: expectedDataInstanceType[expectedDataKeysAPIDTO.SUB_TYPE],
    isArray: expectedDataInstanceType[expectedDataKeysAPIDTO.IS_ARRAY]
  })
});

const makeExpectedDataEntitiesDynamicReferenceDTO = (reference = {}) => ({
  [expectedDataKeysDTO.ID]: makeUUID(),
  [expectedDataKeysDTO.TITLE]: reference[expectedDataKeysAPIDTO.TITLE],
  [expectedDataKeysDTO.SECTIONS]: reference?.[
    expectedDataKeysAPIDTO.SECTIONS
  ]?.map(
    (section) =>
      ({
        [expectedDataKeysDTO.ID]: makeUUID(),
        [expectedDataKeysDTO.TITLE]: section[expectedDataKeysAPIDTO.TITLE],
        [expectedDataKeysDTO.ELEMENTS]:
          section?.[expectedDataKeysAPIDTO.ELEMENTS]?.map((element) => ({
            [expectedDataKeysDTO.ID]: makeUUID(),
            [expectedDataKeysDTO.KEY]: element[expectedDataKeysAPIDTO.KEY],
            [expectedDataKeysDTO.VALUE]: element[expectedDataKeysAPIDTO.VALUE]
          })) || []
      } || [])
  )
});

const makeExpectedDataDTO = ({
  expectedData = {},
  _isExpectedDataEntityType = isExpectedDataEntityType,
  _makeExpectedDataEntityDTO = makeExpectedDataEntityDTO,
  _isExpectedDataInstanceSubType = isExpectedDataInstanceSubType,
  _makeExpectedDataInstanceTypeDTO = makeExpectedDataInstanceTypeDTO,
  _isExpectedDataPrimitiveType = isExpectedDataPrimitiveType,
  _makeExpectedDataPrimitiveTypeDTO = makeExpectedDataPrimitiveTypeDTO
} = {}) => {
  if (
    _isExpectedDataEntityType(expectedData?.[expectedDataKeysAPIDTO.ENTITY])
  ) {
    return _makeExpectedDataEntityDTO({ expectedData });
  }

  if (
    _isExpectedDataInstanceSubType({
      value: expectedData?.[expectedDataKeysAPIDTO.SUB_TYPE]
    })
  ) {
    return _makeExpectedDataInstanceTypeDTO({
      expectedDataInstanceType: expectedData
    });
  }

  if (
    _isExpectedDataPrimitiveType(
      expectedData?.[expectedDataKeysAPIDTO.SUB_TYPE] ||
        expectedData?.[expectedDataKeysAPIDTO.TYPE]
    )
  ) {
    return _makeExpectedDataPrimitiveTypeDTO(expectedData);
  }
};

const makeExpectedDataEntityTypeAPIDTO = ({
  name = "",
  type = "",
  expectedDataDto = {}
} = {}) => {
  const expectedDataEntityType = {
    [expectedDataKeysAPIDTO.ENTITY]: type,
    [expectedDataKeysAPIDTO.NAME]: name,
    [expectedDataKeysAPIDTO.TEMPLATE_TYPE]: null
  };

  if (type === expectedDataEntitiesTypes.DDQ_TASK) {
    expectedDataEntityType.context = {
      form_id: expectedDataDto?.context?.formId
    };
  }

  return expectedDataEntityType;
};

const makeExpectedDataInstanceTypeAPIDTO = ({
  name = "",
  expectedDataDto = {}
} = {}) => ({
  [expectedDataKeysAPIDTO.NAME]: name,
  [expectedDataKeysAPIDTO.TYPE]: expectedDataDto[expectedDataKeysDTO.TYPE],
  [expectedDataKeysAPIDTO.SUB_TYPE]:
    expectedDataDto[expectedDataKeysDTO.SUB_TYPE],
  [expectedDataKeysAPIDTO.IS_ARRAY]:
    expectedDataDto[expectedDataKeysDTO.IS_ARRAY],
  [expectedDataKeysAPIDTO.TEMPLATE_NAME]:
    expectedDataDto[expectedDataKeysDTO.NAME],
  [expectedDataKeysAPIDTO.TEMPLATE_TYPE]: "custom-attribute",
  [expectedDataKeysAPIDTO.TEMPLATE_IDENTIFIER]:
    expectedDataDto[expectedDataKeysDTO.IDENTIFIER],
  [expectedDataKeysAPIDTO.IS_ENUM]: expectedDataDto[expectedDataKeysDTO.IS_ENUM]
});

const makeExpectedDataPrimitiveTypeAPIDTO = ({
  name = "",
  expectedDataDto = {}
} = {}) => ({
  [expectedDataKeysAPIDTO.NAME]: name,
  [expectedDataKeysAPIDTO.TYPE]: expectedDataDto[expectedDataKeysDTO.TYPE],
  [expectedDataKeysAPIDTO.TEMPLATE_TYPE]: null,
  [expectedDataKeysAPIDTO.IS_ARRAY]:
    expectedDataDto[expectedDataKeysDTO.IS_ARRAY],
  [expectedDataKeysAPIDTO.IS_ENUM]:
    expectedDataDto[expectedDataKeysDTO.IS_ENUM],
  ...(expectedDataDto.hasOwnProperty(expectedDataKeysDTO.SUB_TYPE) && {
    [expectedDataKeysAPIDTO.SUB_TYPE]:
      expectedDataDto[expectedDataKeysDTO.SUB_TYPE]
  })
});

const makeExpectedDataAPIDTO = ({
  expectedData,
  storeExpectedData,
  _isExpectedDataInstanceType = isExpectedDataInstanceType,
  _makeExpectedDataInstanceTypeAPIDTO = makeExpectedDataInstanceTypeAPIDTO,
  _isExpectedDataPrimitiveType = isExpectedDataPrimitiveType,
  _makeExpectedDataPrimitiveTypeAPIDTO = makeExpectedDataPrimitiveTypeAPIDTO,
  _isExpectedDataEntityType = isExpectedDataEntityType,
  _makeExpectedDataEntityTypeAPIDTO = makeExpectedDataEntityTypeAPIDTO
} = {}) => {
  let expectedDataDto;
  if (
    _isExpectedDataInstanceType({
      value: expectedData.expectedVariableType
    })
  ) {
    expectedDataDto = storeExpectedData.find(
      ({ name = "" } = {}) => name === expectedData.expectedVariableSubType
    );
    return _makeExpectedDataInstanceTypeAPIDTO({
      name: expectedData.expectedVariableName,
      expectedDataDto
    });
  } else {
    expectedDataDto = storeExpectedData.find(
      ({ uniqueType = "" } = {}) =>
        uniqueType === expectedData.expectedVariableType
    );
    if (_isExpectedDataPrimitiveType(expectedData.expectedVariableType)) {
      return _makeExpectedDataPrimitiveTypeAPIDTO({
        name: expectedData.expectedVariableName,
        expectedDataDto
      });
    } else if (_isExpectedDataEntityType(expectedData.expectedVariableType)) {
      if (
        expectedData.expectedVariableType === expectedDataEntitiesTypes.DDQ_TASK
      ) {
        expectedDataDto = storeExpectedData.find(
          ({ name = "" } = {}) => name === expectedData.expectedVariableSubType
        );
      }
      return _makeExpectedDataEntityTypeAPIDTO({
        expectedData,
        name: expectedData.expectedVariableName,
        type: expectedData.expectedVariableType,
        expectedDataDto
      });
    }
  }
};

const makeExpectedDataPayloadAPIDTO = ({
  companyId,
  expectedDataList = [],
  storeExpectedData = [],
  _makeExpectedDataAPIDTO = makeExpectedDataAPIDTO
} = {}) => ({
  [expectedDataKeysAPIDTO.EXPECTED_DATA_DEFINITION]: {
    [expectedDataKeysAPIDTO.COMPANY_ID]: companyId,
    [expectedDataKeysAPIDTO.VARIABLES]: expectedDataList?.map(
      (expectedData) =>
        _makeExpectedDataAPIDTO({
          expectedData,
          storeExpectedData
        }) || []
    )
  }
});

const makeExpectedDataPayloadDTO = ({
  expectedDataDefinition,
  _makeExpectedDataDTO = makeExpectedDataDTO
} = {}) => ({
  [expectedDataKeysDTO.EXPECTED_DATA_DEFINITION]: {
    [expectedDataKeysDTO.COMPANY_ID]:
      expectedDataDefinition?.[expectedDataKeysAPIDTO.COMPANY_ID],
    [expectedDataKeysDTO.VARIABLES]:
      expectedDataDefinition?.[expectedDataKeysAPIDTO.VARIABLES]?.map(
        (expectedData) =>
          _makeExpectedDataDTO({
            expectedData
          })
      ) || []
  }
});

export {
  makeOptionsDTO,
  makeParsedExpectedDataDTO,
  makeExpectedDataDTO,
  makeExpectedDataInstanceTypeDTO,
  makeExpectedDataPrimitiveTypeDTO,
  makeExpectedDataEntityDDQTaskDTO,
  makeExpectedDataEntityBaseDTO,
  makeExpectedDataEntityDTO,
  makeExpectedDataEntitiesDynamicReferenceDTO,
  makeExpectedDataEntityTypeAPIDTO,
  makeExpectedDataInstanceTypeAPIDTO,
  makeExpectedDataPrimitiveTypeAPIDTO,
  makeExpectedDataAPIDTO,
  makeExpectedDataPayloadAPIDTO,
  makeExpectedDataPayloadDTO,
  makeExpectedDataEntityDDQFormDTO,
  makeExpectedDataEntityTrainingCourseDTO,
  makeExpectedDataEntityEddReportTypeDTO
};
