<template>
  <section class="add-learner">
    <form
      class="add-learner__form"
      data-test-id="add-learner__form"
      @submit.prevent
    >
      <Input
        id="firstName"
        name="firstName"
        placeholder="First name"
        class="add-learner__form__input"
        :value="learner.firstName"
        :error="errors.firstName"
        @change="onChangeInput('firstName', $event)"
      />
      <Input
        id="lastName"
        name="lastName"
        placeholder="Last name"
        class="add-learner__form__input"
        :value="learner.lastName"
        :error="errors.lastName"
        @change="onChangeInput('lastName', $event)"
      />
      <Input
        id="email"
        name="email"
        placeholder="Email"
        class="add-learner__form__input"
        :value="learner.email"
        :error="errors.email"
        @change="onChangeInput('email', $event)"
      />
      <Input
        id="jobTitle"
        name="jobTitle"
        placeholder="Job title"
        class="add-learner__form__input"
        :value="learner.jobTitle"
        @change="onChangeInput('jobTitle', $event)"
      />
      <Select
        id="language"
        name="language"
        class="add-learner__form-language add-learner__form__input"
        :options="getLanguageOptions"
        :value="learner.language"
        :error="errors.language"
        @change="onChangeInput('language', $event)"
      />
      <div class="add-learner__form-cta-holder">
        <CallToAction
          class="add-learner__form-cta"
          value="Cancel"
          @click="onCancel"
          :theme="themes.GREY"
          data-test-id="add-learner__form-cta-holder--close"
        />
        <CallToAction
          class="add-learner__form-cta"
          value="Add"
          :is-loading="isSavingAction"
          data-test-id="add-learner__form-cta-holder--submit"
          @click="onSubmitForm"
        />
      </div>
    </form>
    <InputErrorMessage
      :error="errorMessage"
      class="add-learner__error-message"
    />
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Input from "@/molecules/Input/Input";
import CallToAction from "@/atoms/CallToAction/CallToAction";
import Select from "@/molecules/Select/Select";
import {
  themes,
  DOMEvents,
  customErrorMessages
} from "@/constants";
import InputErrorMessage from "@/molecules/InputErrorMessage/InputErrorMessage";
import {
  validateName,
  validateEmail,
  validateLanguage
} from "./AddTrainingLearner.logic.js";

export default {
  name: "AddTrainingLearner",
  components: {
    Input,
    CallToAction,
    Select,
    InputErrorMessage
  },
  data() {
    return {
      themes,
      learner: this.makeLearner(),
      errors: this.makeLearner(),
      isSavingAction: false,
      errorMessage: ""
    };
  },
  props: {
    thirdPartyId: {
      type: Number,
      default: 0
    },
    courseId: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapGetters({
      getLanguageOptions: "languages/getLanguageOptions"
    })
  },
  methods: {
    ...mapActions({
      addNewTrainingLearner: "userTraining/addNewTrainingLearner"
    }),
    makeLearner() {
      return {
        firstName: "",
        lastName: "",
        email: "",
        jobTitle: "",
        language: ""
      };
    },
    onChangeInput(inputName, val) {
      this.learner[inputName] = val;
    },
    isValidateForm() {
      this.errors.firstName = validateName(this.learner.firstName);
      this.errors.lastName = validateName(this.learner.lastName);
      this.errors.email = validateEmail(this.learner.email);
      this.errors.language = validateLanguage(this.learner.language);

      return (
        !this.errors.firstName &&
        !this.errors.lastName &&
        !this.errors.email &&
        !this.errors.language
      );
    },
    onCancel() {
      this.learner = this.makeLearner();
      this.clearErrors();
      this.$emit(DOMEvents.CLICK);
    },
    clearErrors() {
      this.errors = this.makeLearner();
      this.isSavingAction = false;
    },
    async onSubmitForm() {
      if (this.isValidateForm()) {
        try {
          this.isSavingAction = true;
          const payload = {
            ...this.learner,
            id: this.courseId,
            thirdPartyId: this.thirdPartyId
          };

          await this.addNewTrainingLearner(payload);
        } catch (error) {
          const { status, data: { errors } = {} } = error.response || {};

          if (status === 422 && errors.email_address) {
            this.errorMessage = customErrorMessages.training.duplicateEmail;
          } else {
            this.errorMessage = customErrorMessages.training.addLearnerError;
          }
        } finally {
          this.isSavingAction = false;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.add-learner {
  &__form {
    display: flex;
    justify-content: right;
    padding: $spacing8 $spacing16 0 $spacing16;
    &__input {
      text-align: left;
      margin-left: $spacing12;
      max-width: 180px;
    }
  }

  &__form-cta {
    margin-left: $spacing12;
  }

  &__form-language {
    align-items: baseline;
  }

  &__error-message {
    text-align: center;
  }
}
</style>
