<template>
  <section class="risk-alert-legend">
    <div
      v-for="(config, key) in riskAlertIcons"
      :key="key"
      class="risk-alert-legend__item"
    >
      <ShapeWithInfo v-bind="config" class="risk-alert-legend__item-icon" />
      <BaseText v-bind="config.iconText" class="risk-alert-legend__item-text" />
    </div>
  </section>
</template>

<script>
import ShapeWithInfo from "@/molecules/ShapeWithInfo/ShapeWithInfo";
import BaseText from "@/atoms/BaseText/BaseText";
import { riskAlertIcons } from "@/constants";

export default {
  name: "ThirdPartyRiskAlertsLegend",
  components: {
    ShapeWithInfo,
    BaseText
  },
  data() {
    return {
      riskAlertIcons
    };
  }
};
</script>

<style lang="scss" scoped>
.risk-alert-legend {
  display: flex;
  gap: $spacing12;
  border: 1px solid $geyser-gray;
  padding: $spacing8;
  text-align: left;

  &__item {
    display: flex;
    align-items: center;

    &-icon {
      margin-right: $spacing8;
      flex: 0 0 $spacing24;
    }
  }
}
</style>
