import {
  automationsAPIKeys,
  automationsKeys,
  automationsTriggerDataTypes,
  expectedDataTypes,
  operations,
  requestFromUserOption,
  taskTemplatesExpectedDataTriggerType,
  triggerDataType,
  userRolesKeysAPIDTO
} from "@/constants";
import { getSelectedOption } from "@/utils";

const makeAutomationConditionsDTO = (condition = {}) => ({
  [automationsKeys.ID]: condition?.[automationsAPIKeys.ID],
  [automationsKeys.NAME]: condition?.[automationsAPIKeys.NAME],
  [automationsKeys.PARAMETERS]: condition[automationsAPIKeys.PARAMETERS]?.map(
    (parameter) => ({
      [automationsKeys.NAME]: parameter?.[automationsAPIKeys.NAME],
      [automationsKeys.TYPE]: parameter?.[automationsAPIKeys.TYPE],
      [automationsKeys.REQUIRES_MATCH]:
        parameter?.[automationsAPIKeys.REQUIRES_MATCH],
      [automationsKeys.IS_MAPPABLE]: parameter?.[automationsAPIKeys.IS_MAPPABLE]
    })
  )
});

const makeAutomationActionTriggerDataDTO = (data = {}) => {
  const triggerData = {};
  triggerData[automationsKeys.TYPE] = data[automationsAPIKeys.TYPE];

  if (
    data[automationsAPIKeys.TYPE] ===
    automationsTriggerDataTypes.EVENT_PARAMETER
  ) {
    triggerData[automationsKeys.DATA] = {
      [automationsKeys.NAME]:
        data?.[automationsAPIKeys.DATA]?.[automationsAPIKeys.NAME]
    };
  } else if (
    data?.[automationsAPIKeys.TYPE] ===
    automationsTriggerDataTypes.USER_PROVIDED
  ) {
    if (
      data?.[automationsAPIKeys.DATA]?.[automationsAPIKeys.TYPE] ===
      automationsTriggerDataTypes.DDQ_FORM
    ) {
      triggerData[automationsKeys.DATA] = {
        [automationsKeys.TYPE]:
          data?.[automationsAPIKeys.DATA]?.[automationsAPIKeys.TYPE],
        [automationsKeys.VALUE]: {
          [automationsKeys.COMPANY_ID]:
            data?.[automationsAPIKeys.DATA]?.[automationsAPIKeys.VALUE]?.[
              automationsAPIKeys.COMPANY_ID
            ],
          [automationsKeys.DDQ_ID]:
            data?.[automationsAPIKeys.DATA]?.[automationsAPIKeys.VALUE]?.[
              automationsAPIKeys.DDQ_ID
            ]
        }
      };
    }

    if (
      data?.data?.[automationsAPIKeys.TYPE] ===
      automationsTriggerDataTypes.TRAINING_COURSE
    ) {
      triggerData[automationsKeys.DATA] = {
        [automationsKeys.TYPE]:
          data?.[automationsAPIKeys.DATA]?.[automationsAPIKeys.TYPE],
        [automationsKeys.VALUE]: {
          [automationsKeys.COMPANY_ID]:
            data?.[automationsAPIKeys.DATA]?.[automationsAPIKeys.VALUE]?.[
              automationsAPIKeys.COMPANY_ID
            ],
          [automationsKeys.COURSE_ID]:
            data?.[automationsAPIKeys.DATA]?.[automationsAPIKeys.VALUE]?.[
              automationsAPIKeys.COURSE_ID
            ]
        }
      };
    }

    if (
      data?.data?.[automationsAPIKeys.TYPE] ===
      automationsTriggerDataTypes.EDD_REPORT
    ) {
      triggerData[automationsKeys.DATA] = {
        [automationsKeys.TYPE]:
          data?.[automationsAPIKeys.DATA]?.[automationsAPIKeys.TYPE],
        [automationsKeys.VALUE]: {
          [automationsKeys.COMPANY_ID]:
            data?.[automationsAPIKeys.DATA]?.[automationsAPIKeys.VALUE]?.[
              automationsAPIKeys.COMPANY_ID
            ],
          [automationsKeys.EDD_REPORT_TYPE_ID]:
            data?.[automationsAPIKeys.DATA]?.[automationsAPIKeys.VALUE]?.[
              automationsAPIKeys.EDD_REPORT_TYPE_ID
            ]
        }
      };
    }
  }

  return triggerData;
};

const makeAutomationActionDTO = ({ id, expected_data_mapping } = {}) => ({
  [automationsKeys.ID]: id,
  [automationsKeys.EXPECTED_DATA_MAPPING]: Array.isArray(expected_data_mapping)
    ? expected_data_mapping?.map((expectedDataObj) => ({
        [automationsKeys.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]:
          expectedDataObj?.[
            automationsAPIKeys.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME
          ],
        [automationsKeys.TRIGGERING_DATA]: makeAutomationActionTriggerDataDTO(
          expectedDataObj?.[automationsAPIKeys.TRIGGERING_DATA]
        )
      }))
    : []
});

const getAutomationsDDQformObj = (options = []) => {
  try {
    const value = JSON.parse(getSelectedOption(options)?.value);
    return {
      [automationsAPIKeys.COMPANY_ID]: value[automationsKeys.COMPANY_ID],
      [automationsAPIKeys.DDQ_ID]: value[automationsKeys.DDQ_ID]
    };
  } catch (e) {
    return {
      [automationsAPIKeys.COMPANY_ID]: undefined,
      [automationsAPIKeys.DDQ_ID]: undefined
    };
  }
};

const getAutomationsTrainingCourseFormObj = (options = []) => {
  try {
    const value = JSON.parse(getSelectedOption(options)?.value);
    return {
      [automationsAPIKeys.COMPANY_ID]: value[automationsKeys.COMPANY_ID],
      [automationsAPIKeys.COURSE_ID]: value[automationsKeys.COURSE_ID]
    };
  } catch {
    return {
      [automationsAPIKeys.COMPANY_ID]: undefined,
      [automationsAPIKeys.COURSE_ID]: undefined
    };
  }
};

const getAutomationsEDDReportTypeFormObj = (options = []) => {
  try {
    const value = JSON.parse(getSelectedOption(options)?.value);
    return {
      [automationsAPIKeys.COMPANY_ID]: value[automationsKeys.COMPANY_ID],
      [automationsAPIKeys.EDD_REPORT_TYPE_ID]:
        value[automationsKeys.EDD_REPORT_TYPE_ID]
    };
  } catch {
    return {
      [automationsAPIKeys.COMPANY_ID]: undefined,
      [automationsAPIKeys.EDD_REPORT_TYPE_ID]: undefined
    };
  }
};

const makeAutomationActionTriggerDataBaseAPIDTO = ({
  name = "",
  expectedDataName = "",
  dataSource = ""
} = {}) => ({
  [automationsAPIKeys.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]: name,
  [automationsAPIKeys.TRIGGERING_DATA]: {
    [automationsAPIKeys.DATA]: {
      [automationsAPIKeys.NAME]: expectedDataName
    },
    [automationsAPIKeys.TYPE]: dataSource
  }
});

const makeAutomationActionTriggerDataUserInputAPIDTO = ({
  name = "",
  message = ""
} = {}) => ({
  [automationsAPIKeys.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]: name,
  [automationsAPIKeys.TRIGGERING_DATA]: {
    [automationsAPIKeys.DATA]: null,
    [automationsAPIKeys.MESSAGE]: message,
    [automationsAPIKeys.TYPE]: taskTemplatesExpectedDataTriggerType.USER_INPUT
  }
});

const makeAutomationActionTriggerDataDDQFormAPIDTO = ({
  name = "",
  type = "",
  selectedOption = {}
} = {}) => ({
  [automationsAPIKeys.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]: name,
  [automationsAPIKeys.TRIGGERING_DATA]: {
    [automationsAPIKeys.DATA]: {
      [automationsAPIKeys.TYPE]: type,
      [automationsAPIKeys.VALUE]: {
        [automationsAPIKeys.COMPANY_ID]:
          selectedOption[automationsAPIKeys.COMPANY_ID],
        [automationsAPIKeys.DDQ_ID]: selectedOption[automationsAPIKeys.DDQ_ID]
      }
    },
    [automationsAPIKeys.TYPE]: automationsTriggerDataTypes.USER_PROVIDED
  }
});

const makeAutomationActionTriggerDataEDDReportTypeAPIDTO = ({
  name = "",
  type = "",
  selectedOption = {}
} = {}) => ({
  [automationsAPIKeys.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]: name,
  [automationsAPIKeys.TRIGGERING_DATA]: {
    [automationsAPIKeys.DATA]: {
      [automationsAPIKeys.TYPE]: type,
      [automationsAPIKeys.VALUE]: {
        [automationsAPIKeys.COMPANY_ID]:
          selectedOption[automationsAPIKeys.COMPANY_ID],
        [automationsAPIKeys.EDD_REPORT_TYPE_ID]:
          selectedOption[automationsAPIKeys.EDD_REPORT_TYPE_ID]
      }
    },
    [automationsAPIKeys.TYPE]: automationsTriggerDataTypes.USER_PROVIDED
  }
});

const makeAutomationActionTriggerDataTrainingCourseAPIDTO = ({
  name = "",
  type = "",
  selectedOption = {}
} = {}) => ({
  [automationsAPIKeys.TRIGGERED_EXPECTED_DATA_VARIABLE_NAME]: name,
  [automationsAPIKeys.TRIGGERING_DATA]: {
    [automationsAPIKeys.DATA]: {
      [automationsAPIKeys.TYPE]: type,
      [automationsAPIKeys.VALUE]: {
        [automationsAPIKeys.COMPANY_ID]:
          selectedOption[automationsAPIKeys.COMPANY_ID],
        [automationsAPIKeys.COURSE_ID]:
          selectedOption[automationsAPIKeys.COURSE_ID]
      }
    },
    [automationsAPIKeys.TYPE]: automationsTriggerDataTypes.USER_PROVIDED
  }
});

const makeAutomationActionTriggerDataAPIDTO = ({
  uniqueType = "",
  name = "",
  options = [],
  dataSource = automationsTriggerDataTypes.EVENT_PARAMETER,
  _makeAutomationActionTriggerDataBaseAPIDTO = makeAutomationActionTriggerDataBaseAPIDTO,
  _makeAutomationActionTriggerDataDDQFormAPIDTO = makeAutomationActionTriggerDataDDQFormAPIDTO,
  _makeAutomationActionTriggerDataUserInputAPIDTO = makeAutomationActionTriggerDataUserInputAPIDTO,
  _makeAutomationActionTriggerDataTrainingCourseAPIDTO = makeAutomationActionTriggerDataTrainingCourseAPIDTO,
  _makeAutomationActionTriggerDataEDDReportTypeAPIDTO = makeAutomationActionTriggerDataEDDReportTypeAPIDTO,
  _getSelectedOption = getSelectedOption,
  _getAutomationsDDQformObj = getAutomationsDDQformObj,
  _getAutomationsTrainingCourseFormObj = getAutomationsTrainingCourseFormObj,
  _getAutomationsEDDReportTypeFormObj = getAutomationsEDDReportTypeFormObj
} = {}) => {
  const selectedOption = _getSelectedOption(options);
  if (uniqueType === expectedDataTypes.DDQ_FORM) {
    return _makeAutomationActionTriggerDataDDQFormAPIDTO({
      type: uniqueType,
      name,
      selectedOption: _getAutomationsDDQformObj(options)
    });
  }

  if (uniqueType === expectedDataTypes.TRAINING_COURSE) {
    return _makeAutomationActionTriggerDataTrainingCourseAPIDTO({
      type: uniqueType,
      name,
      selectedOption: _getAutomationsTrainingCourseFormObj(options)
    });
  }

  if (uniqueType === expectedDataTypes.EDD_REPORT) {
    return _makeAutomationActionTriggerDataEDDReportTypeAPIDTO({
      type: uniqueType,
      name,
      selectedOption: _getAutomationsEDDReportTypeFormObj(options)
    });
  }

  if (selectedOption?.value === requestFromUserOption.value) {
    return _makeAutomationActionTriggerDataUserInputAPIDTO({
      name,
      message: selectedOption.message
    });
  }

  return _makeAutomationActionTriggerDataBaseAPIDTO({
    name,
    expectedDataName: selectedOption?.text,
    dataSource
  });
};

const makeAutomationActionAPIDTO = ({
  expectDataList = [],
  actionNameOptions = [],
  dataSource = automationsTriggerDataTypes.EVENT_PARAMETER,
  _getSelectedOption = getSelectedOption,
  _makeAutomationActionTriggerDataAPIDTO = makeAutomationActionTriggerDataAPIDTO
} = {}) => ({
  [automationsAPIKeys.ID]: _getSelectedOption(actionNameOptions)?.value,
  [automationsAPIKeys.EXPECTED_DATA_MAPPING]:
    expectDataList?.map((expectData) =>
      _makeAutomationActionTriggerDataAPIDTO({
        ...expectData,
        dataSource
      })
    ) || []
});

const makeAutomationConditionAPIDTO = ({ event = {}, criteria = [] } = {}) => ({
  event: {
    [automationsAPIKeys.ID]: event[automationsKeys.ID],
    [automationsAPIKeys.NAME]: event[automationsKeys.NAME],
    [automationsAPIKeys.PARAMETERS]:
      event?.[automationsAPIKeys.PARAMETERS]?.map((parameter) => ({
        [automationsAPIKeys.NAME]: parameter[automationsKeys.NAME],
        [automationsAPIKeys.TYPE]: parameter[automationsKeys.TYPE],
        [automationsAPIKeys.REQUIRES_MATCH]:
          parameter?.[automationsKeys.REQUIRES_MATCH],
        [automationsAPIKeys.IS_MAPPABLE]: parameter[automationsKeys.IS_MAPPABLE]
      })) || []
  },
  [automationsAPIKeys.CRITERIA]: criteria.map((criteriaObj) => ({
    [automationsAPIKeys.TYPE]: criteriaObj[automationsKeys.TYPE],
    [automationsAPIKeys.PARAMETER]: criteriaObj[automationsKeys.PARAMETER],
    [automationsAPIKeys.COMPARATOR]: criteriaObj[automationsKeys.COMPARATOR],
    [automationsAPIKeys.VALUE]: {
      [automationsAPIKeys.CUSTOM_ATTRIBUTE_ID]:
        criteriaObj?.[automationsKeys.VALUE]?.[
          automationsKeys.CUSTOM_ATTRIBUTE_ID
        ],
      [automationsAPIKeys.COMPANY_ID]:
        criteriaObj?.[automationsKeys.VALUE]?.[automationsKeys.COMPANY_ID]
    }
  }))
});

const makeAutomationConditionDTO = ({ event = {}, criteria = [] } = {}) => ({
  [automationsKeys.EVENT]: {
    [automationsKeys.ID]: event[automationsAPIKeys.ID],
    [automationsKeys.NAME]: event[automationsAPIKeys.NAME],
    [automationsKeys.PARAMETERS]:
      event?.[automationsAPIKeys.PARAMETERS]?.map((parameter) => ({
        [automationsKeys.NAME]: parameter[automationsAPIKeys.NAME],
        [automationsKeys.TYPE]: parameter[automationsAPIKeys.TYPE],
        [automationsKeys.REQUIRES_MATCH]:
          parameter?.[automationsAPIKeys.REQUIRES_MATCH],
        [automationsKeys.IS_MAPPABLE]: parameter[automationsAPIKeys.IS_MAPPABLE]
      })) || []
  },
  [automationsKeys.CRITERIA]: criteria.map((criteriaObj) => ({
    [automationsKeys.TYPE]: criteriaObj[automationsAPIKeys.TYPE],
    [automationsKeys.PARAMETER]: criteriaObj[automationsAPIKeys.PARAMETER],
    [automationsKeys.COMPARATOR]: criteriaObj[automationsAPIKeys.COMPARATOR],
    [automationsKeys.VALUE]: {
      [automationsKeys.CUSTOM_ATTRIBUTE_ID]:
        criteriaObj?.[automationsAPIKeys.VALUE]?.[
          automationsAPIKeys.CUSTOM_ATTRIBUTE_ID
        ],
      [automationsKeys.COMPANY_ID]:
        criteriaObj?.[automationsAPIKeys.VALUE]?.[automationsAPIKeys.COMPANY_ID]
    }
  }))
});

const makeAutomationDTO = (automation = {}) => ({
  ...(automation?.[automationsAPIKeys.ID] && {
    [automationsKeys.ID]: automation?.id
  }),
  ...(automation?.[automationsAPIKeys.COMPANY_ID] && {
    [automationsKeys.COMPANY_ID]: automation[automationsAPIKeys.COMPANY_ID]
  }),
  [automationsKeys.NAME]: automation[automationsAPIKeys.NAME],
  [automationsKeys.DESCRIPTION]: automation[automationsAPIKeys.DESCRIPTION],
  [automationsKeys.IS_ACTIVE]: automation[automationsAPIKeys.IS_ACTIVE],
  [automationsKeys.CONDITION]: makeAutomationConditionDTO(
    automation[automationsAPIKeys.CONDITION]
  ),
  [automationsKeys.ACTIONS]:
    automation.hasOwnProperty(automationsAPIKeys.ACTIONS) &&
    Array.isArray(automation[automationsAPIKeys.ACTIONS])
      ? automation[automationsAPIKeys.ACTIONS].map(makeAutomationActionDTO)
      : [],
  [automationsKeys.CREATED_AT]: automation[automationsAPIKeys.CREATED_AT],
  [automationsKeys.UPDATED_AT]: automation[automationsAPIKeys.UPDATED_AT],
  [automationsKeys.CREATED_BY]: {
    [automationsKeys.NAME]:
      automation?.[automationsAPIKeys.CREATED_BY]?.[automationsAPIKeys.NAME],
    [automationsKeys.ID]:
      automation?.[automationsAPIKeys.CREATED_BY]?.[automationsAPIKeys.ID]
  },
  [automationsKeys.PERMISSION]:
    automation?.[automationsAPIKeys.PERMISSIONS]?.[0]?.[
      automationsAPIKeys.PERMISSION
    ],
  [automationsKeys.ROLES]:
    automation?.[automationsAPIKeys.PERMISSIONS]?.[0]?.[
      automationsAPIKeys.ROLES
    ]
});

const makeAutomationAPIDTO = (
  automation = {},
  triggerStepList = [],
  includeTriggerRoles = false
) => ({
  ...(automation?.id && { [automationsAPIKeys.ID]: automation?.id }),
  [automationsAPIKeys.COMPANY_ID]: automation?.[automationsKeys.COMPANY_ID],
  [automationsAPIKeys.NAME]: automation?.[automationsKeys.NAME],
  [automationsAPIKeys.DESCRIPTION]: automation?.[automationsKeys.DESCRIPTION],
  [automationsAPIKeys.IS_ACTIVE]: automation?.[automationsKeys.IS_ACTIVE],
  [automationsAPIKeys.CONDITION]: makeAutomationConditionAPIDTO(
    automation?.[automationsKeys.CONDITION]
  ),
  [automationsAPIKeys.ACTIONS]: Array.isArray(triggerStepList)
    ? triggerStepList.map(makeAutomationActionAPIDTO)
    : [],
  [automationsAPIKeys.CREATED_AT]: automation?.[automationsKeys.CREATED_AT],
  [automationsAPIKeys.UPDATED_AT]: automation?.[automationsKeys.UPDATED_AT],
  [automationsAPIKeys.CREATED_BY]: {
    [automationsAPIKeys.NAME]:
      automation?.[automationsKeys.CREATED_BY]?.[automationsKeys.NAME],
    [automationsAPIKeys.ID]:
      automation?.[automationsKeys.CREATED_BY]?.[automationsKeys.ID]
  },
  ...(includeTriggerRoles && {
    [automationsAPIKeys.PERMISSIONS]: [
      {
        [automationsAPIKeys.PERMISSION]: userRolesKeysAPIDTO.TRIGGER,
        [automationsAPIKeys.ROLES]: automation?.[automationsKeys.ROLES] || []
      }
    ]
  })
});

const makeCustomTypeDTO = ({ data = [] } = {}) =>
  Object.keys(data).map((key) => ({
    [automationsKeys.NAME]: key,
    [automationsKeys.CUSTOM_ATTRIBUTE_ID]:
      data[key]?.[automationsAPIKeys.CUSTOM_ATTRIBUTE_ID],
    [automationsKeys.COMPANY_ID]: data[key]?.[automationsAPIKeys.COMPANY_ID]
  }));

const makeCriteriaDTO = (parameter, value, companyId) => ({
  [automationsKeys.TYPE]: triggerDataType.PARAMETER_MATCH,
  [automationsKeys.PARAMETER]: parameter,
  [automationsKeys.COMPARATOR]: operations.EQUALS,
  [automationsKeys.VALUE]: {
    [automationsKeys.CUSTOM_ATTRIBUTE_ID]: value,
    [automationsKeys.COMPANY_ID]: companyId
  }
});

export {
  makeAutomationDTO,
  makeAutomationAPIDTO,
  makeAutomationConditionDTO,
  makeAutomationConditionAPIDTO,
  makeAutomationActionAPIDTO,
  makeAutomationActionTriggerDataAPIDTO,
  getAutomationsDDQformObj,
  getAutomationsEDDReportTypeFormObj,
  makeAutomationActionDTO,
  makeAutomationActionTriggerDataDTO,
  makeAutomationConditionsDTO,
  makeCustomTypeDTO,
  makeCriteriaDTO,
  makeAutomationActionTriggerDataDDQFormAPIDTO,
  makeAutomationActionTriggerDataEDDReportTypeAPIDTO,
  makeAutomationActionTriggerDataUserInputAPIDTO,
  makeAutomationActionTriggerDataBaseAPIDTO
};
