<template>
  <section class="learner">
    <BaseLoader v-if="isLoadingThirdPartyTrainingDetails" />
    <template v-else>
      <BaseInfoBox
        v-if="showWarningMessage"
        :icon-options="infoIconOptions"
        :theme="themes.ERROR"
        class="learner__infoBox"
        text="At least 1 learner must be assigned to the training."
      />
      <Modal
        class="learner__modal"
        v-if="isModalVisible"
        :title="makeModalHeading"
        @close-modal="closeModal"
      >
        <div class="learner__modal-text">
          <BaseText
            text="Are you sure you want to remove following learner(s) from this training?"
            :size="typographySize.BODY_TEXT_BOLD"
          />
          <BaseText :text="getLearnerNamesByIds(learnerIds)" />
        </div>
        <template #left>
          <CallToAction
            :theme="themes.SECONDARY_INVERSE"
            value="Cancel"
            data-test-id="learner__modal-cancel-cta"
            @click="onCancelRemoveLearners"
          />
        </template>
        <template #right>
          <CallToAction
            :theme="themes.ERROR_INVERSE"
            value="Yes, remove"
            data-test-id="learner__modal-delete-cta"
            :is-loading="isDeletingLearner"
            @click="removeLearnerWrapper"
          />
        </template>
      </Modal>

      <ProfileHeader
        :heading="trainingData.name"
        :subheading="trainingData.description"
        :CTAOptions="CTAOptions"
        @click="handleCTA"
      />
      <InputErrorMessage :error="errorMessage" />
      <AddTrainingLearner
        v-if="showAddNewLearner"
        :course-id="courseId"
        :third-party-id="thirdPartyId"
        @click="onAddLearner"
      />
      <div class="learner__table">
        <StickyTable
          :table-headers="tableHeaders(getTableHeaders)"
          :table-rows="tableRows(getTableHeaders)"
          data-test-id="learner__table"
        />
      </div>
      <CallToAction
        v-if="learnerIds.length"
        value="Remove selected"
        class="learner__button"
        :theme="themes.ERROR"
        @click="onRemoveLearners"
      />
    </template>
  </section>
</template>

<script>
import BaseInfoBox from "@/atoms/BaseInfoBox/BaseInfoBox";
import BaseLoader from "@/atoms/BaseLoader/BaseLoader";
import StickyTable from "@/molecules/StickyTable/StickyTable";
import BaseText from "@/atoms/BaseText/BaseText";
import BaseBadge from "@/atoms/BaseBadge/BaseBadge";
import BaseIcon from "@/atoms/BaseIcon/BaseIcon";
import ProfileHeader from "@/molecules/ProfileHeader/ProfileHeader";
import AddTrainingLearner from "@/organisms/AddTrainingLearner/AddTrainingLearner";
import Checkbox from "@/molecules/Checkbox/Checkbox";
import CallToAction from "@/atoms/CallToAction/CallToAction";
import Modal from "@/molecules/Modal/Modal";

import {
  trainingLearnersTableHeaders,
  trainingLearnersKeysDTO,
  icons,
  themes,
  defaultIcons,
  views,
  DOMEvents,
  thirdPartyTrainingStatuses,
  typographySize,
  trainingLearnersSupplierTableHeaders,
  iconSizes
} from "@/constants";
import { mapActions, mapState, mapMutations } from "vuex";
import InputErrorMessage from "@/molecules/InputErrorMessage/InputErrorMessage";
import { getTrainingStatusTheme } from "@/utils";

export default {
  name: "ThirdPartyTrainingDetails",
  components: {
    StickyTable,
    BaseLoader,
    InputErrorMessage,
    ProfileHeader,
    AddTrainingLearner,
    CallToAction,
    Modal,
    BaseText,
    BaseInfoBox
  },
  data() {
    return {
      errorMessage: "",
      showAddNewLearner: false,
      learnerIds: [],
      hasAllLearnersSelected: false,
      isModalVisible: false,
      isDeletingLearner: false,
      showWarningMessage: false,
      themes,
      views,
      typographySize,
      defaultIcons
    };
  },
  props: {
    thirdPartyId: {
      type: Number,
      default: 0
    },
    courseId: {
      type: Number,
      default: 0
    },
    isSupplierUser: {
      type: Boolean,
      default: false
    }
  },
  async created() {
    await this.fetchTrainingDetailsWrapper();
  },
  computed: {
    ...mapState({
      thirdPartyTraining: (state) => state.thirdParty.thirdPartyTraining,
      isLoadingThirdPartyTrainingDetails: (state) =>
        state.thirdParty.isLoadingThirdPartyTrainingDetails
    }),
    infoIconOptions() {
      return {
        ...defaultIcons.HIGH,
        size: iconSizes.SMALL
      };
    },
    makeModalHeading() {
      return `Remove learners from ${this.trainingData.name}`;
    },
    trainingData() {
      return {
        name: this.thirdPartyTraining?.title,
        description: this.thirdPartyTraining?.description
      };
    },
    CTAOptions() {
      if (this.isSupplierUser) {
        return {
          ...defaultIcons.EDIT,
          value: "Add new learner",
          icon: null
        };
      } else
        return {
          ...defaultIcons.EDIT,
          value: "Back to training list",
          icon: null
        };
    },
    getTableHeaders() {
      return this.isSupplierUser
        ? trainingLearnersSupplierTableHeaders
        : trainingLearnersTableHeaders;
    }
  },
  beforeDestroy() {
    this.resetThirdPartyTraining();
  },
  methods: {
    ...mapActions({
      fetchTrainingDetails:
        "thirdParty/fetchThirdPartyTrainingDetails",
      removeTrainingLearner: "userTraining/removeTrainingLearner"
    }),
    ...mapMutations({
      resetThirdPartyTraining:
        "thirdParty/resetThirdPartyTraining"
    }),
    tableHeaders(selectedHeaders) {
      return this.thirdPartyTraining?.learners?.length
        ? Object.keys(selectedHeaders).reduce((acc, key) => {
            if (selectedHeaders[key]) {
              if (key === trainingLearnersKeysDTO.SELECT) {
                acc.push({
                  value: trainingLearnersKeysDTO.SELECT,
                  component: Checkbox,
                  componentOptions: {
                    label: "",
                    labelHidden: true,
                    modelValue: this.hasAllLearnersSelected,
                    "onUpdate:modelValue": this.toggleSelectAll
                  },
                  styles: {
                    minWidth: "48px"
                  }
                });
              } else {
                acc.push({
                  value: key,
                  text: selectedHeaders[key]
                });
              }
            }
            return acc;
          }, [])
        : [];
    },
    tableRows(selectedHeaders) {
      return this.thirdPartyTraining?.learners?.length
        ? this.thirdPartyTraining?.learners.map((training) =>
            Object.keys(selectedHeaders).reduce((acc, trainingKey) => {
              if (trainingKey === trainingLearnersKeysDTO.STATUS) {
                acc[trainingKey] = {
                  component: BaseBadge,
                  componentOptions: {
                    theme: getTrainingStatusTheme(training[trainingKey]),
                    text: training[trainingKey]
                  }
                };
              } else if (
                trainingKey === trainingLearnersKeysDTO.REMINDER_SENT
              ) {
                acc[trainingKey] = {
                  component: BaseIcon,
                  componentOptions: {
                    icon: training[trainingKey] ? icons.CHECK : icons.MINUS,
                    size: 16,
                    theme: themes.LIGHT_GREY_INVERSE
                  },
                  styles: {
                    textAlign: "-webkit-center"
                  }
                };
              } else if (trainingKey === trainingLearnersKeysDTO.SELECT) {
                acc[trainingKey] = {
                  component: Checkbox,
                  componentOptions: {
                    label: "",
                    labelHidden: true,
                    modelValue: this.learnerIds.includes(training.id),
                    disabled:
                      training[trainingLearnersKeysDTO.STATUS] ===
                      thirdPartyTrainingStatuses.COMPLETED,
                    "onUpdate:modelValue": (value) =>
                      this.setSelectedLeanerIds({
                        rowId: training.id,
                        isChecked: value
                      })
                  }
                };
              } else {
                acc[trainingKey] = {
                  component: BaseText,
                  componentOptions: {
                    tag: "span",
                    text: training[trainingKey]
                  }
                };
              }
              return acc;
            }, {})
          )
        : [];
    },
    async fetchTrainingDetailsWrapper() {
      try {
        this.errorMessage = "";
        await this.fetchTrainingDetails({
          thirdPartyId: this.thirdPartyId,
          courseId: this.courseId
        });
      } catch ({ message = "" }) {
        this.errorMessage = message;
      }
    },
    onBackClick() {
      this.$emit(DOMEvents.CLICK, {
        view: views.LIST
      });
    },
    onAddLearner() {
      this.showAddNewLearner = !this.showAddNewLearner;
    },
    handleCTA() {
      if (this.isSupplierUser) {
        this.onAddLearner();
      } else {
        this.onBackClick();
      }
    },
    setSelectedLeanerIds({ rowId, isChecked }) {
      if (isChecked) {
        this.learnerIds.push(rowId);
      } else {
        this.learnerIds = this.learnerIds.filter((id) => id !== rowId);
      }
      this.showWarningMessage = false;
    },
    toggleSelectAll(value) {
      this.hasAllLearnersSelected = value;
      if (this.hasAllLearnersSelected) {
        this.learnerIds = this.thirdPartyTraining?.learners?.reduce(
          (acc, { id, status }) => {
            if (status !== thirdPartyTrainingStatuses.COMPLETED) {
              acc.push(id);
            }
            return acc;
          },
          []
        );
      } else {
        this.learnerIds = [];
      }
      this.showWarningMessage = false;
    },
    onRemoveLearners() {
      if (
        this.thirdPartyTraining.learners.length - this.learnerIds.length <
        1
      ) {
        this.showWarningMessage = true;
      } else {
        this.isModalVisible = true;
      }
    },
    closeModal() {
      this.showWarningMessage = false;
      this.isModalVisible = false;
      this.isDeletingLearner = false;
    },
    onCancelRemoveLearners() {
      this.learnerIds = [];
      this.hasAllLearnersSelected = false;
      this.closeModal();
    },
    async removeLearnerWrapper() {
      this.isDeletingLearner = true;
      const learner = {
        learnerIds: this.learnerIds,
        id: this.courseId,
        thirdPartyId: this.thirdPartyId
      };
      try {
        await this.removeTrainingLearner(learner);
        this.learnerIds = [];
        this.closeModal();
      } catch {
        this.errorMessage = "Failed to remove learner.";
      }
    },
    getLearnerNamesByIds(learnerIds) {
      return this.thirdPartyTraining?.learners
        ?.reduce((acc, { id, name }) => {
          if (learnerIds.includes(id)) {
            acc.push(name);
          }
          return acc;
        }, [])
        .join(", ");
    }
  }
};
</script>
<style lang="scss" scoped>
.learner {
  height: calc(100vh - 110px);
  display: flex;
  flex-direction: column;
  margin: $spacing16;

  &__table {
    margin: 0 $spacing12;
    overflow-y: auto;
  }

  &__button {
    margin-right: $spacing12;
    margin-top: $spacing16;
    margin-left: auto;
    height: fit-content;
    padding: $spacing4 $spacing8;
  }

  &__infoBox {
    max-width: 50%;
    align-self: flex-end;
    padding: $spacing12;
    margin-right: $spacing12;
  }

  :deep(.modal) {
    max-width: 750px;
  }
}
</style>
