import { actionsService } from "@/services";
import { actionKeysApi } from "@/constants";

export const actions = {
  async fetchActions(
    { commit, rootState },
    { isLoadingActionsSuppressed = false, ...payload } = {}
  ) {
    try {
      if (!isLoadingActionsSuppressed) {
        commit("actions/setIsLoadingActions", true, {
          root: true
        });
      }
      const { data = {} } = await actionsService.fetchActions(
        rootState.company.companyId,
        {
          excluded_fields: [actionKeysApi.FUNCTION_BODY],
          with_options: true,
          ...payload
        }
      );
      commit("actions/setActions", data, { root: true });
      return data;
    } catch (error) {
      commit("actions/setActions", [], { root: true });
      throw error;
    } finally {
      commit("actions/setIsLoadingActions", false, {
        root: true
      });
    }
  },
  async fetchAction({ commit, rootState }, id) {
    try {
      commit("actions/setIsLoadingAction", true, {
        root: true
      });
      const { data = {} } = await actionsService.fetchAction(
        rootState.company.companyId,
        id
      );
      commit("actions/setAction", data, { root: true });
      return data;
    } catch (error) {
      commit("actions/setAction", {}, { root: true });
      throw error;
    } finally {
      commit("actions/setIsLoadingAction", false, {
        root: true
      });
    }
  },
  async saveAction({ rootState, dispatch }, payload) {
    await actionsService.saveAction({
      companyId: rootState.company.companyId,
      expectedData: rootState.expectedData.expectedData,
      ...payload
    });
    await dispatch("actions/fetchActions", undefined, { root: true });
  },
  async amendAction({ rootState, dispatch }, { action, entities, actionId }) {
    await actionsService.amendAction({
      companyId: rootState.company.companyId,
      expectedData: rootState.expectedData.expectedData,
      action,
      entities,
      actionId
    });
    await dispatch("actions/fetchActions", undefined, { root: true });
  },
  async deleteAction(
    { rootState, dispatch },
    { actionId, isLoadingActionsSuppressed = false } = {}
  ) {
    await actionsService.deleteAction(rootState.company.companyId, actionId);
    await dispatch(
      "actions/fetchActions",
      { isLoadingActionsSuppressed },
      { root: true }
    );
  },
  async cloneAction(
    { rootState, dispatch },
    { actionId, isLoadingActionsSuppressed = false } = {}
  ) {
    await actionsService.cloneAction(rootState.company.companyId, actionId);
    await dispatch(
      "actions/fetchActions",
      { isLoadingActionsSuppressed },
      { root: true }
    );
  }
};
