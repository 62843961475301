<template>
  <div class="tp-profile-header">
    <div class="d-flex">
      <div class="tp-profile-header__icon">
        <BaseIcon :icon="getIcon" :size="80" />
      </div>
      <div class="text-left ml-5">
        <div class="third-party-profile__heading-wrapper">
          <h2 class="d-flex">
            <strong data-test-id="third-party-profile__heading">
              {{ thirdPartyData.comp_name_eng || "" }}
            </strong>
            <p class="ml-2" v-if="isDisabled">*Archived*</p>
          </h2>
          <BaseBadge v-if="isVerified" v-bind="pillIcons.VERIFIED_PILL" />
        </div>
        <BaseText
          hasDefaultSpacingRemoved
          :size="typographySize.BODY_TEXT_BOLD"
          :text="thirdPartyData?.alias"
        />
        <a
          class="tp-profile-header__link"
          :href="thirdPartyHref()"
          target="_blank"
        >
          {{ thirdPartyData.website || "" }}
        </a>
        <p data-test-id="third-party-profile__country">
          {{ thirdPartyData.country || "" }}
        </p>
      </div>
      <div class="ml-auto">
        <b-btn v-if="isDisabled" class="tp-profile-header__button">
          <font-awesome-icon
            class="edit-icon-style"
            v-b-tooltip.hover
            title="Configure third party"
            size="4x"
            icon="pen"
          />
        </b-btn>
        <BaseLoader v-else-if="isLoading" />
        <font-awesome-icon
          v-else-if="hasEditThirdPartiesPermission"
          v-b-tooltip.hover
          title="Configure third party"
          class="edit-icon-style"
          size="4x"
          icon="pen"
          @click.native="onEditThirdPartyModal"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  featurePermissionsKeys,
  icons,
  pillIcons,
  space,
  thirdPartyType,
  typographySize,
  urls
} from "@/constants";
import { mapGetters, mapMutations, mapState } from "vuex";
import BaseLoader from "@/atoms/BaseLoader/BaseLoader";
import BaseIcon from "@/atoms/BaseIcon/BaseIcon";
import { hasPermission } from "@/utils";
import BaseText from "@/atoms/BaseText/BaseText";
import BaseBadge from "@/atoms/BaseBadge/BaseBadge";

export default {
  name: "ThirdPartyProfileHeader",
  components: {
    BaseBadge,
    BaseLoader,
    BaseIcon,
    BaseText
  },
  data() {
    return {
      isLoading: false,
      typographySize,
      space,
      pillIcons
    };
  },
  props: {
    thirdPartyData: {
      type: Object,
      default: () => ({})
    },
    isDisabled: {
      type: Boolean,
      isRequire: true
    },
    isDnbModal: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapState({
      isAddThirdPartyModalNewVisible: (state) =>
        state.thirdParty.isAddThirdPartyModalNewVisible,
      companyId: (state) => state.company.companyId
    }),
    ...mapGetters({
      isDNBFeatureEnabled: "company/isDNBFeatureEnabled"
    }),
    getIcon() {
      const icon =
        this.thirdPartyData?.tpi_type === thirdPartyType.INDIVIDUAL
          ? icons.USER
          : icons.BUILDING;

      return icon;
    },
    hasEditThirdPartiesPermission() {
      return hasPermission({
        key: featurePermissionsKeys.EDIT_THIRD_PARTIES
      });
    },
    isVerified() {
      return !!this.thirdPartyData.verified;
    }
  },
  watch: {
    isAddThirdPartyModalNewVisible() {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapMutations({
      setIsThirdPartyNewModalVisible:
        "thirdParty/setIsThirdPartyNewModalVisible",
      setIsAddThirdPartyModalNewEditable:
        "thirdParty/setIsAddThirdPartyModalNewEditable"
    }),
    thirdPartyHref() {
      const { website } = this.thirdPartyData;
      if (website) {
        return website.match(/https/g) ? website : `https://${website}`;
      } else {
        return "";
      }
    },
    onEditThirdPartyModal() {
      this.isLoading = true;
      if (this.isDNBFeatureEnabled) {
        this.$router.push(
          urls.THIRD_PARTY_UPDATE({
            id: this.$route?.params?.id,
            companyId: this.$route?.params?.compId || this.companyId
          })
        );
      } else {
        this.setIsThirdPartyNewModalVisible(true);
      }
      this.setIsAddThirdPartyModalNewEditable(true);
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/custom.scss";

.third-party-profile__heading-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  white-space: pre;
}

.tp-profile-header {
  &__link {
    overflow-wrap: anywhere;
    width: 90%;
  }

  &__button {
    background-color: $white;
    border-color: $white;
  }
}
</style>
