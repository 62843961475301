<template>
  <section>
    <BaseLoader v-if="isLoading" />
    <template v-else>
      <ErrorPageTemplate v-if="isErrorPresent" v-bind="errorPageOptions" />
      <router-view v-else v-slot="{ Component }">
        <component
          :is="Component"
          @fetch-task-templates="fetchTaskTemplatesWrapper"
          @fetch-task-template="fetchTaskTemplateWrapper"
          @reset-task-template="resetTaskTemplate"
          :destination-base-options="getDestinationBaseOptions"
          :roles-destination-options="getRolesDestinationOptions"
          :task-templates="taskTemplates"
          :task-template="taskTemplate"
        />
      </router-view>
    </template>
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import BaseLoader from "@/atoms/BaseLoader/BaseLoader";
import ErrorPageTemplate from "@/templates/ErrorPageTemplate/ErrorPageTemplate";

export default {
  name: "TaskWrapperTemplate",
  data() {
    return {
      errorMessages: []
    };
  },
  components: {
    BaseLoader,
    ErrorPageTemplate
  },
  computed: {
    ...mapState({
      taskTemplates: (state) => state.taskTemplates.taskTemplates,
      taskTemplate: (state) => state.taskTemplates.taskTemplate,
      platformRequestSupportLink: (state) =>
        state.platformSettings.platformRequestSupportLink,
      isLoadingTaskTemplates: (state) =>
        state.taskTemplates.isLoadingTaskTemplates,
      isLoadingTaskTemplate: (state) =>
        state.taskTemplates.isLoadingTaskTemplate,
      isLoadingRoles: (state) => state.roles.isLoadingRoles,
      isLoadingExpectedData: (state) =>
        state.expectedData.isLoadingExpectedData,
      isLoadingActions: (state) => state.actions.isLoadingActions
    }),
    ...mapGetters({
      getDestinationBaseOptions: "userEmailTemplates/getDestinationBaseOptions",
      getRolesDestinationOptions:
        "userEmailTemplates/getRolesDestinationOptions"
    }),
    isLoading() {
      return (
        this.isLoadingTaskTemplates ||
        this.isLoadingTaskTemplate ||
        this.isLoadingRoles ||
        this.isLoadingExpectedData ||
        this.isLoadingActions
      );
    },
    isErrorPresent() {
      return !!this.errorMessages.length;
    },
    errorPageOptions() {
      return {
        headingOptions: {
          text: "Incomplete data"
        },
        contentOptions: {
          text: this.errorMessages
        },
        CTAOptions: {
          value: "Please contact support"
        },
        linkComponent: "a",
        linkOptions: {
          href: this.platformRequestSupportLink,
          target: "_blank"
        }
      };
    }
  },
  methods: {
    ...mapActions({
      fetchTaskTemplates: "taskTemplates/fetchTaskTemplates",
      fetchTaskTemplate: "taskTemplates/fetchTaskTemplate",
      fetchExpectedData: "expectedData/fetchExpectedData",
      fetchRoles: "roles/fetchRoles",
      fetchActions: "actions/fetchActions"
    }),
    ...mapMutations({
      resetTaskTemplate: "userEmailTemplates/resetUserEmailTemplate",
      resetTaskTemplates: "taskTemplates/resetTaskTemplates",
      resetRoles: "roles/resetRoles",
      resetExpectedData: "expectedData/resetExpectedData",
      resetActions: "actions/resetActions"
    }),
    async fetchTaskTemplatesWrapper() {
      try {
        await this.fetchTaskTemplates();
      } catch {
        this.errorMessages.push("Failed to fetch the task templates.");
      }
    },
    async fetchTaskTemplateWrapper(id) {
      try {
        await this.fetchTaskTemplate(id);
      } catch {
        this.errorMessages.push(
          `Failed to fetch the task template, with the id: ${id}`
        );
      }
    },
    async fetchExpectedDataWrapper() {
      try {
        await this.fetchExpectedData();
      } catch {
        this.errorMessages.push(
          "Failed to retrieve the available expected data."
        );
      }
    },
    async fetchRolesWrapper() {
      try {
        await this.fetchRoles();
      } catch {
        this.errorMessages.push("Failed to retrieve the roles.");
      }
    },
    async fetchActionsWrapper() {
      try {
        await this.fetchActions();
      } catch {
        this.errorMessages.push("Failed to retrieve the actions.");
      }
    }
  },
  async created() {
    await this.fetchExpectedDataWrapper();

    if (this.$route?.params?.id) {
      await this.fetchTaskTemplateWrapper(this.$route?.params?.id);
    }

    await Promise.allSettled([
      this.fetchTaskTemplatesWrapper(),
      this.fetchActionsWrapper(),
      this.fetchRolesWrapper()
    ]);
  },
  beforeRouteLeave(_, __, next) {
    this.resetTaskTemplates();
    this.resetExpectedData();
    this.resetRoles();
    this.resetActions();
    next();
  }
};
</script>
