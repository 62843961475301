<template>
  <div class="data-table" data-test-id="data-table">
    <SearchFilterBar
      v-if="searchFilterBarOptions"
      v-bind="searchFilterBarOptions"
      class="data-table__search-filter-bar"
      @click="
        onTriggerComponentEvent({
          type: dataTableEvents.SEARCH_FILTER_BAR,
          event: $event,
          eventType: DOMEvents.CLICK
        })
      "
      @input="
        onTriggerComponentEvent({
          type: dataTableEvents.SEARCH_FILTER_BAR,
          event: $event,
          eventType: DOMEvents.INPUT
        })
      "
      @change="
        onTriggerComponentEvent({
          type: dataTableEvents.SEARCH_FILTER_BAR,
          event: $event,
          eventType: DOMEvents.CHANGE
        })
      "
    />
    <div :style="{ height: gridHeight }" class="data-table__table">
      <AgGridTable
        v-bind="agGridOptions"
        @grid-ready="onGridReady"
        @sort-changed="$emit(dataTableEvents.SORT_CHANGED, $event)"
        ref="ag-grid-table"
      />
    </div>
    <PerPagePagination
      v-if="paginationOptions"
      v-bind="paginationOptions"
      @change="
        onTriggerComponentEvent({
          type: dataTableEvents.PER_PAGE_PAGINATION,
          event: $event,
          eventType: DOMEvents.CHANGE
        })
      "
      @click="
        onTriggerComponentEvent({
          type: dataTableEvents.PER_PAGE_PAGINATION,
          event: $event,
          eventType: DOMEvents.CLICK
        })
      "
    />
  </div>
</template>

<script>
import PerPagePagination from "@/molecules/PerPagePagination/PerPagePagination";
import SearchFilterBar from "@/organisms/SearchFilterBar/SearchFilterBar";
import AgGridTable from "@/molecules/AgGridTable/AgGridTable.vue";
import { dataTableEvents, DOMEvents } from "@/constants";

export default {
  name: "DataTable",
  data() {
    return {
      dataTableEvents,
      DOMEvents
    };
  },
  components: {
    AgGridTable,
    PerPagePagination,
    SearchFilterBar
  },
  props: {
    searchFilterBarOptions: {
      type: Object,
      default: () => null
    },
    agGridOptions: {
      type: Object,
      default: () => null
    },
    paginationOptions: {
      type: Object,
      default: () => null
    },
    gridHeight: {
      type: String,
      default: "500px"
    }
  },
  methods: {
    onTriggerComponentEvent({ eventType, type, event }) {
      this.$emit(eventType, { type, event });
    },
    onGridReady(event) {
      this.$emit(dataTableEvents.GRID_READY, event);
    }
  }
};
</script>
