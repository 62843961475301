import { tasksService } from "@/services";
import { tasksKeysAPIDTO } from "@/constants";

export const actions = {
  async fetchTasks({ commit, rootState, state }, payload = {}) {
    try {
      commit("tasks/setIsLoadingTasks", true, {
        root: true
      });
      const response = await tasksService.fetchTasks({
        companyId: rootState.company.companyId,
        filter: state?.tasksFilter,
        pagination: state.tasksPagination,
        excludedFields: [
          tasksKeysAPIDTO.OUTCOME_ACTIONS,
          tasksKeysAPIDTO.OUTCOMES_DATA,
          tasksKeysAPIDTO.EXPECTED_DATA_DEFINITION,
          tasksKeysAPIDTO.PROVIDED_DATA,
          tasksKeysAPIDTO.EXPECTED_DATA_MAPPING
        ],
        ...payload
      });
      commit("tasks/setTasks", response.data.data, {
        root: true
      });
      commit(
        "tasks/setTasksPagination",
        {
          ...(payload?.pagination || state.tasksPagination),
          totalItems: response.data.meta.totalItems
        },
        {
          root: true
        }
      );
      commit(
        "tasks/setTasksFilter",
        [...(payload?.filter || state?.tasksFilter)],
        {
          root: true
        }
      );
      return response;
    } finally {
      commit("tasks/setIsLoadingTasks", false, {
        root: true
      });
    }
  },
  async fetchTask({ commit, rootState }, taskId) {
    try {
      commit("tasks/setIsLoadingTask", true, {
        root: true
      });
      const { data } = await tasksService.fetchTask({
        companyId: rootState.company.companyId,
        taskId
      });
      commit("tasks/setTask", data, {
        root: true
      });
    } finally {
      commit("tasks/setIsLoadingTask", false, {
        root: true
      });
    }
  },
  async completeTask({ dispatch, commit, rootState, state }, amendedTaskData) {
    try {
      commit("tasks/setIsLoadingTask", true, {
        root: true
      });
      await tasksService.completeTask({
        companyId: rootState.company.companyId,
        task: state.task,
        amendedTaskData
      });
      await dispatch("tasks/fetchTasks", {}, { root: true });
      await dispatch("tasks/fetchTask", state.task.id, {
        root: true
      });
    } finally {
      commit("tasks/setIsLoadingTask", false, {
        root: true
      });
    }
  },
  async updateTask({ dispatch, commit, rootState, state }, amendedTaskData) {
    try {
      commit("tasks/setIsLoadingTask", true, {
        root: true
      });
      const { data } = await tasksService.updateTask({
        companyId: rootState.company.companyId,
        task: state.task,
        amendedTaskData
      });
      commit("tasks/resetTask", {}, { root: true });
      dispatch("tasks/fetchTasks", {}, { root: true });
      commit("tasks/setTask", data, { root: true });
    } finally {
      commit("tasks/setIsLoadingTask", false, {
        root: true
      });
    }
  },
  async reopenTask({ dispatch, commit, rootState }, taskId) {
    try {
      commit("tasks/setIsLoadingTask", true, {
        root: true
      });
      const { data } = await tasksService.reopenTask({
        companyId: rootState.company.companyId,
        taskId
      });
      commit("tasks/setTask", data, {
        root: true
      });
      dispatch("tasks/fetchTasks", {}, { root: true });
    } finally {
      commit("tasks/setIsLoadingTask", false, {
        root: true
      });
    }
  },
  async deleteTask({ dispatch, commit, rootState }, taskId) {
    try {
      commit("tasks/setIsLoadingTask", true, {
        root: true
      });
      await tasksService.deleteTask({
        companyId: rootState.company.companyId,
        taskId
      });
      commit("tasks/resetTask", {}, { root: true });
      dispatch("tasks/fetchTasks", {}, { root: true });
    } finally {
      commit("tasks/setIsLoadingTask", false, {
        root: true
      });
    }
  }
};
