import { tasksFilterKeysDTO } from "@/constants";
import Datepicker from "@/molecules/DatePicker/DatePicker";
import MultiSelect from "@/molecules/MultiSelect/MultiSelect";

const isCreatedDateFilterKey = (dateValue) =>
  dateValue === tasksFilterKeysDTO.CREATED_DATE_AFTER ||
  dateValue === tasksFilterKeysDTO.CREATED_DATE_BEFORE;

const isUpdatedDateFilterKey = (dateValue) =>
  dateValue === tasksFilterKeysDTO.UPDATED_DATE_AFTER ||
  dateValue === tasksFilterKeysDTO.UPDATED_DATE_BEFORE;

const isDateFilterKey = ({
  dateValue,
  _isCreatedDateFilterKey = isCreatedDateFilterKey,
  _isUpdatedDateFilterKey = isUpdatedDateFilterKey
}) => _isCreatedDateFilterKey(dateValue) || _isUpdatedDateFilterKey(dateValue);

const makeFilterRow = ({
  availableFilterOptions = [],
  availableFilterError = "",
  availableFilterValueComponentOptions = {},
  availableFilterValueOptionsComponent = {},
  id = ""
} = {}) => ({
  availableFilterOptions,
  availableFilterError,
  availableFilterValueComponentOptions,
  availableFilterValueOptionsComponent,
  id
});

const makeAvailableFilterValueComponent = ({
  dateValue = "",
  _isDateFilterKey = isDateFilterKey
} = {}) => {
  if (_isDateFilterKey({ dateValue })) {
    return Datepicker;
  }
  return MultiSelect;
};

const isDynamicValueAllowedForFilterCriteria = (filterCriteria) =>
  filterCriteria === tasksFilterKeysDTO.COMPANY_TASK_ID ||
  filterCriteria === tasksFilterKeysDTO.SUBJECT ||
  filterCriteria === tasksFilterKeysDTO.DESCRIPTION ||
  filterCriteria === tasksFilterKeysDTO.SUBJECT_OR_DESCRIPTION;

export {
  isDateFilterKey,
  isCreatedDateFilterKey,
  isUpdatedDateFilterKey,
  makeFilterRow,
  makeAvailableFilterValueComponent,
  isDynamicValueAllowedForFilterCriteria
};
