<template>
  <div>
    <nav class="app-header">
      <img
        @click="navigateToHome"
        class="app-header__logo"
        :src="getPlatformLogo"
        alt="logo"
        data-test-id="app-header__logo"
      />
      <BaseText
        class="app-header__version"
        :text="`V${platformVersion}`"
        data-test-id="app-header__version"
        :theme="themes.PRIMARY"
        :size="typographySize.BODY_TEXT_SMALL"
        hasDefaultSpacingRemoved
      />
      <CallToAction
        v-if="hasBulkUpdatePermission"
        @click="onBulkUpdateClick"
        ref="bulkUpdateButton"
        :theme="themes.SECONDARY"
        class="app-header__button"
        :icon-size="12"
        :text-size="typographySize.BODY_TEXT_SMALL"
        icon="upload"
        value="Bulk update"
        has-icon-with-text
        data-test-id="app-header__bulk-update"
      />
      <CallToAction
        v-if="hasAddNewViewsPermission"
        @click="showAddNewViewModal"
        :theme="themes.SECONDARY"
        ref="createViewButton"
        class="app-header__button"
        :icon-size="12"
        :text-size="typographySize.BODY_TEXT_SMALL"
        icon="eye"
        value="Create new view"
        has-icon-with-text
        data-test-id="app-header__create-view"
      />
      <CallToAction
        v-if="hasAddThirdPartiesPermission"
        @click="showAddThirdPartyModal"
        :theme="themes.SECONDARY"
        class="app-header__button"
        :icon-size="12"
        :text-size="typographySize.BODY_TEXT_SMALL"
        icon="plus"
        value="Add new third party"
        has-icon-with-text
        data-test-id="app-header__create-entity"
      />
      <DropdownMenuWithCallToAction
        :CTAOptions="{
          value: fullName,
          hasIconWithText: true,
          textSize: typographySize.BODY_TEXT_SMALL,
          iconSize: 12,
          theme: themes.SECONDARY,
          icon: 'chevron-down',
          'data-test-id': 'app-header__dropdown-cta'
        }"
        :menu-options="dropdownMenuOptions"
      />
    </nav>
    <b-modal
      v-model="showChangeAccountModal"
      size="lg"
      scrollable
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      title="Switch Company"
    >
      <b-overlay>
        <div
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-left p-3"
        >
          <p>
            Are you sure you want to switch company from
            <strong id="form-confirm-label"> {{ companyName }}</strong>
            to
            <strong id="form-confirm-label">{{ selectedCompanyName }}</strong>
            ?
          </p>
        </div>
      </b-overlay>
      <template v-slot:modal-footer>
        <b-button
          size="md"
          variant="outline-danger"
          class="mr-3"
          @click="hideChangeAccountModal"
        >
          No
        </b-button>
        <b-button size="md" variant="outline-success" @click="onCompanyChange">
          Yes
        </b-button>
      </template>
    </b-modal>

    <ChangePasswordModal />
    <AddModal
      v-if="isThirdPartyAddModalVisible"
      :companyId="parseInt(companyId)"
    />
    <AddNewThirdPartyModal v-if="isAddThirdPartyModalNewVisible" />
    <AddNewViewModal />
    <InputErrorMessage :error="errorMessage" />
  </div>
</template>
<script>
import { EventBus } from "@/event-bus.js";
import ChangePasswordModal from "@/organisms/ChangePassword/ChangePasswordModal";
import AddModal from "@/components/eb2/manageThirdParty/addModal";
import AddNewThirdPartyModal from "@/components/eb2/manageThirdParty/addThirdPartyModalNew";
import AddNewViewModal from "@/components/eb2/manageThirdParty/addNewViewModal";
import { HTTP, makeAuthorizationHeader, hasPermission } from "@/utils";
import {
  httpStatuses,
  localStorageItem,
  urls,
  userPassword,
  featurePermissionsKeys,
  typographySize,
  themes,
  routeNames
} from "@/constants";
import defaultActionJson from "@/utils/defaultAction.json";
import InputErrorMessage from "@/molecules/InputErrorMessage/InputErrorMessage";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { Helper } from "@/helpers";
import BaseText from "@/atoms/BaseText/BaseText";
import CallToAction from "@/atoms/CallToAction/CallToAction";
import DropdownMenuWithCallToAction from "@/molecules/DropdownMenuWithCallToAction/DropdownMenuWithCallToAction";
import DropdownMenuItem from "@/molecules/DropdownMenuItem/DropdownMenuItem";

export default {
  name: "AppHeader",
  components: {
    ChangePasswordModal,
    AddModal,
    AddNewThirdPartyModal,
    AddNewViewModal,
    InputErrorMessage,
    BaseText,
    CallToAction,
    DropdownMenuWithCallToAction
  },
  inject: ["$posthog"],
  data() {
    return {
      typographySize,
      themes,
      formDefaultLanguage: "english",
      selectedCompanyName: "",
      errorMessage: [],
      updatePassword: null,
      showChangeAccountModal: false,
      selectedCompanyId: null,
      questionnaires: []
    };
  },
  created() {
    this.resetErrorMessage();
    this.fetchAdditionalVouchersWrapper();
    this.fetchCompaniesWrapper();
    this.fetchCountries();
    this.updatePassword = localStorage.getItem(
      localStorageItem.UPDATED_PASSWORD
    );
    this.checkUpdatePassword();
    this.setLanguagesToStore();
  },
  computed: {
    ...mapState({
      companyId: (state) => state.company.companyId,
      platformVersion: (state) => state.user.platformVersion,
      triggerFetchAdditionalVouchers: (state) =>
        state.company.triggerFetchAdditionalVouchers,
      companyName: (state) => state.company.companyName,
      companyList: (state) => state.company.companyList,
      passwordCreated: (state) => state.user.passwordCreated,
      platformMainHeaderLogo: (state) =>
        state.platformSettings.platformMainHeaderLogo,
      platformReleaseNoteLink: (state) =>
        state.platformSettings.platformReleaseNoteLink,
      platformRequestSupportLink: (state) =>
        state.platformSettings.platformRequestSupportLink,
      platformKnowledgeCentreLink: (state) =>
        state.platformSettings.platformKnowledgeCentreLink,
      companyDetails: (state) => state.company.companyDetails,
      isThirdPartyAddModalVisible: (state) =>
        state.thirdParty.isThirdPartyAddModalVisible,
      isAddThirdPartyModalNewVisible: (state) =>
        state.thirdParty.isAddThirdPartyModalNewVisible,
      defaultProperties: (state) => state.posthog.defaultProperties
    }),
    ...mapGetters({
      isLoggedInViaThePlatform: "user/isLoggedInViaThePlatform",
      getCompanyName: "user/getCompanyName",
      fullName: "user/getFullName",
      isUserThirdParty: "user/isUserThirdParty",
      permittedLandingPage: "user/permittedLandingPage"
    }),
    getPlatformLogo() {
      return this.platformMainHeaderLogo || "";
    },
    hasAddThirdPartiesPermission() {
      return hasPermission({ key: featurePermissionsKeys.ADD_THIRD_PARTIES });
    },
    hasAddNewViewsPermission() {
      return hasPermission({
        key: featurePermissionsKeys.CREATE_AND_REMOVE_USER_VIEWS
      });
    },
    hasBulkUpdatePermission() {
      return hasPermission({
        key: featurePermissionsKeys.BULK_UPDATE_THIRD_PARTIES
      });
    },
    dropdownMenuOptions() {
      return [
        ...(this.companyList?.length > 1
          ? [
              {
                contentMaxHeight: 180,
                headingOptions: {
                  textOptions: {
                    text: "Platforms"
                  }
                },
                listOptions:
                  this.companyList?.map(({ companyName, id }) => ({
                    component: DropdownMenuItem,
                    options: {
                      id,
                      textOptions: {
                        text: companyName,
                        size: typographySize.BODY_TEXT_SMALL
                      },
                      "data-test-id": this.getCompanyDataTestId(companyName),
                      onClick: () => {
                        this.setNewCompany(id, companyName);
                      }
                    }
                  })) || []
              }
            ]
          : []),

        ...(this.platformReleaseNoteLink ||
        this.platformKnowledgeCentreLink ||
        this.platformRequestSupportLink
          ? [
              {
                headingOptions: {
                  textOptions: {
                    text: "Support"
                  }
                },
                listOptions: [
                  {
                    component: DropdownMenuItem,
                    options: {
                      id: 0,
                      textOptions: {
                        text: "Open chat",
                        size: typographySize.BODY_TEXT_SMALL
                      },
                      "data-test-id": "app-header__open-chat",
                      onClick: () => {
                        this.handleOpenChatClick();
                      }
                    }
                  },
                  ...(this.platformReleaseNoteLink
                    ? [
                        {
                          component: DropdownMenuItem,
                          options: {
                            id: 0,
                            textOptions: {
                              text: "Latest release notes",
                              size: typographySize.BODY_TEXT_SMALL
                            },
                            "data-test-id": "app-header__release-notes",
                            path: this.platformReleaseNoteLink,
                            target: "_blank"
                          }
                        }
                      ]
                    : []),
                  ...(this.platformKnowledgeCentreLink
                    ? [
                        {
                          component: DropdownMenuItem,
                          options: {
                            id: 0,
                            textOptions: {
                              text: "Knowledge centre",
                              size: typographySize.BODY_TEXT_SMALL
                            },
                            "data-test-id": "app-header__knowledge-center",
                            path: this.platformKnowledgeCentreLink,
                            target: "_blank"
                          }
                        }
                      ]
                    : []),
                  ...(this.platformRequestSupportLink
                    ? [
                        {
                          component: DropdownMenuItem,
                          options: {
                            id: 0,
                            textOptions: {
                              text: "Request support",
                              size: typographySize.BODY_TEXT_SMALL
                            },
                            "data-test-id": "app-header__request-support",
                            path: this.platformRequestSupportLink,
                            target: "_blank"
                          }
                        }
                      ]
                    : [])
                ]
              }
            ]
          : []),
        {
          headingOptions: {
            textOptions: {
              text: "Other"
            }
          },
          listOptions: [
            ...(this.isLoggedInViaThePlatform
              ? [
                  {
                    component: DropdownMenuItem,
                    options: {
                      id: 0,
                      textOptions: {
                        text: "Change password",
                        size: typographySize.BODY_TEXT_SMALL
                      },
                      "data-test-id": "app-header__change-password",
                      onClick: () => {
                        this.showChangePassword();
                      }
                    }
                  }
                ]
              : []),
            {
              component: DropdownMenuItem,
              options: {
                id: 1,
                textOptions: {
                  text: this.$t("signOutBtnText"),
                  size: typographySize.BODY_TEXT_SMALL
                },
                "data-test-id": "app-header__sign-out",
                onClick: () => {
                  this.onLogOut();
                }
              }
            }
          ]
        }
      ];
    }
  },
  watch: {
    triggerFetchAdditionalVouchers(newValue) {
      if (newValue) {
        this.resetErrorMessage();
        this.fetchAdditionalVouchersWrapper();
        this.setTriggerFetchAdditionalVouchers(false);
      }
    }
  },
  methods: {
    ...mapMutations({
      setPasswordCreated: "user/setPasswordCreated",
      setTriggerFetchAdditionalVouchers:
        "company/setTriggerFetchAdditionalVouchers",
      setCountries: "country/setCountries",
      setIsThirdPartyAddModalVisible:
        "thirdParty/setIsThirdPartyAddModalVisible"
    }),
    ...mapActions({
      logOut: "auth/logOut",
      fetchAdditionalVouchers: "company/fetchAdditionalVouchers",
      selectCompany: "company/selectCompany",
      fetchCompanies: "company/fetchCompanies",
      fetchFormsLanguagesList: "thirdParty/fetchFormsLanguagesList",
      fetchCompanyFeatures: "company/fetchCompanyFeatures",
      fetchPlatformUsersList: "platformSettings/fetchPlatformUserList"
    }),
    async onLogOut() {
      await this.logOut(true);
    },
    async fetchCountries() {
      try {
        let { status, data } = await HTTP(
          "get",
          "v1/countries",
          null,
          makeAuthorizationHeader()
        );
        if (status === 200) {
          this.setCountries(data.countries);
        }
      } catch ({ status }) {
        const description = status
          ? `There was an error with status ${status}.`
          : "";
        this.errorMessage.push(
          `Failed to retrieve the country list. ${description}`
        );
      }
    },
    getCompanyDataTestId(companyName) {
      return `app-header__platform-option--${
        companyName?.replace(/ /g, "-")?.toLowerCase() || "no-name"
      }`;
    },
    showAddNewViewModal() {
      EventBus.emit("addNewViewModal", "");
      this.$refs.createViewButton?.$el?.blur();
    },
    showAddThirdPartyModal() {
      this.setIsThirdPartyAddModalVisible(true);
    },
    async fetchAdditionalVouchersWrapper(companyId = "") {
      try {
        await this.fetchAdditionalVouchers(companyId);
        this.setActionSchema();
      } catch {
        this.errorMessage.push(
          "Something went wrong, please try again. If the issue persists please contact support."
        );
      }
    },
    setActionSchema() {
      let actionsData = this.companyDetails.additionalData;

      let actionsSchema = {};
      if (actionsData) {
        let allActions = [];
        allActions.push(...defaultActionJson.extraActions);
        allActions.push(...defaultActionJson.actionsOccur);
        allActions.push(...defaultActionJson.actionsNotAvailable);
        if (actionsData.extraActions && actionsData.extraActions.length != 0) {
          actionsData.extraActions.map((items, index) => {
            if (items.title == undefined) {
              let extraObjectIndex = allActions.findIndex(
                (x) => x.id == items.id
              );
              actionsData.extraActions[index] = allActions[extraObjectIndex];
            }
          });
        }
        if (actionsData.actionsOccur && actionsData.actionsOccur.length != 0) {
          actionsData.actionsOccur.map((items, index) => {
            if (items.title == undefined) {
              let extraObjectIndex = allActions.findIndex(
                (x) => x.id == items.id
              );
              actionsData.actionsOccur[index] = allActions[extraObjectIndex];
            }
          });
        }
        if (
          actionsData.actionsNotAvailable &&
          actionsData.actionsNotAvailable.length != 0
        ) {
          actionsData.actionsNotAvailable.map((items, index) => {
            if (items.title == undefined) {
              let extraObjectIndex = allActions.findIndex(
                (x) => x.id == items.id
              );
              actionsData.actionsNotAvailable[index] =
                allActions[extraObjectIndex];
            }
          });
        }

        if (!actionsData.extraActions) {
          actionsData.extraActions = defaultActionJson.extraActions;
        }
        if (!actionsData.actionsNotAvailable) {
          actionsData.actionsNotAvailable =
            defaultActionJson.actionsNotAvailable;
        }
        if (!actionsData.actionsOccur) {
          actionsData.actionsOccur = defaultActionJson.actionsOccur;
        }
        actionsSchema = actionsData;
        localStorage.setItem(
          localStorageItem.ACTIONS_SCHEMA,
          JSON.stringify(actionsSchema)
        );
        let actionsSidebarSchema = localStorage.getItem(
          localStorageItem.ACTIONS_SCHEMA
        );
        let parsedSidebarSchema = JSON.parse(actionsSidebarSchema);
        this.$store.state.newSidebarData = parsedSidebarSchema;
      } else {
        actionsSchema = {};
        actionsSchema.extraActions = defaultActionJson.extraActions;
        actionsSchema.actionsNotAvailable =
          defaultActionJson.actionsNotAvailable;
        actionsSchema.actionsOccur = defaultActionJson.actionsOccur;
        localStorage.setItem(
          localStorageItem.ACTIONS_SCHEMA,
          JSON.stringify(actionsSchema)
        );
        let actionsSidebarSchema = localStorage.getItem(
          localStorageItem.ACTIONS_SCHEMA
        );
        let parsedSidebarSchema = JSON.parse(actionsSidebarSchema);
        this.$store.state.newSidebarData = parsedSidebarSchema;
      }

      this.companyDetails.onboardingForms.map((onboardingForm) => {
        const actionsOccurIndex = actionsSchema.actionsOccur.findIndex(
          ({ btnText }) => btnText === onboardingForm.btnText
        );
        if (actionsOccurIndex !== -1) {
          actionsSchema.actionsOccur[actionsOccurIndex] = onboardingForm;
        }

        const extraActionsIndex = actionsSchema.extraActions.findIndex(
          ({ btnText }) => btnText === onboardingForm.btnText
        );
        if (extraActionsIndex !== -1) {
          actionsSchema.extraActions[extraActionsIndex] = onboardingForm;
        }

        const actionsNotAvailableIndex =
          actionsSchema.actionsNotAvailable.findIndex(
            ({ btnText }) => btnText === onboardingForm.btnText
          );
        if (actionsNotAvailableIndex != -1) {
          actionsSchema.actionsNotAvailable[actionsNotAvailableIndex] =
            onboardingForm;
        }
      });

      localStorage.setItem(
        localStorageItem.ACTIONS_SCHEMA,
        JSON.stringify(actionsSchema)
      );
    },
    async getQuestionnariesData() {
      try {
        let result = await HTTP(
          "get",
          `v1/getactiveformslist/${this.companyId}`,
          null,
          makeAuthorizationHeader()
        );
        if (result.data) {
          result.data.questionnaires.map((items) => {
            this.questionnaires.push(items);
          });
        }
      } catch ({ status }) {
        Helper.makeToast(
          this.$bvToast,
          "danger",
          `There is an unknown error with status of ${status}. Please contact support`
        );
      }
    },
    setLanguagesToStore() {
      let languageInLocalStore =
        localStorage.getItem(localStorageItem.FORM_DEFAULT_LANGUAGE) ||
        "english";
      if (languageInLocalStore == "chinese simplified") {
        this.$i18n.locale = "chinese";
      } else if (languageInLocalStore == "chinese traditional taiwan") {
        this.$i18n.locale = "chinesetr";
      } else if (languageInLocalStore == "bahasa indonesia") {
        this.$i18n.locale = "bahasaIndonesia";
      } else {
        this.$i18n.locale = languageInLocalStore;
      }
      this.$store.state.form1.formDefaultLanguage = languageInLocalStore;
      this.$store.state.selectedLanguage = languageInLocalStore;
      this.formDefaultLanguage = languageInLocalStore;
    },
    defaultLanguageText(lang) {
      let ll = lang.toLowerCase();
      if (ll == "chinese simplified") {
        return this.$t("languages.chinese");
      } else if (ll == "chinese traditional taiwan") {
        return this.$t("languages.chinesetr");
      } else if (ll == "bahasa indonesia") {
        return this.$t("bahasaIndonesia");
      } else {
        return this.$i18n.availableLocales.includes(ll)
          ? this.$t(`languages."+${ll}`)
          : ll;
      }
    },
    onUpdateLanguage(lang) {
      let yy = lang.toLowerCase();
      if (yy == "chinese simplified") {
        lang = "chinese";
      }
      if (yy == "chinese traditional taiwan") {
        lang = "chinesetr";
      }
      if (yy == "bahasa indonesia") {
        lang = "bahasaIndonesia";
      }
      let ll = lang.toLowerCase();
      let ff = this.$i18n.availableLocales.includes(ll); // checking selected languages with saved messages
      this.$i18n.locale = ff ? ll : "english";
      this.$store.state.form1.formDefaultLanguage = yy;
      this.$store.state.selectedLanguage = yy;
      localStorage.setItem(localStorageItem.FORM_DEFAULT_LANGUAGE, yy);
      this.formDefaultLanguage = ff ? ll : "english";
      if (ll == "arabic" || ll == "hebrew") {
        this.$store.state.rightAlign = true;
      } else {
        this.$store.state.rightAlign = false;
      }
    },
    async fetchCompaniesWrapper() {
      try {
        const { data = {}, status = "" } = await this.fetchCompanies();
        if (data.fakeHttpStatus === httpStatuses.FAIL) {
          this.fetchCompaniesFailure({ status });
        }
      } catch {
        this.fetchCompaniesFailure();
      }
    },
    fetchCompaniesFailure() {
      this.errorMessage.push("Failed to receive the available platforms.");
    },
    resetErrorMessage() {
      this.errorMessage = [];
    },
    setNewCompany(companyId, companyName) {
      this.selectedCompanyId = companyId;
      this.selectedCompanyName = companyName;
      this.showChangeAccountModal = true;
    },
    async onCompanyChange() {
      this.resetErrorMessage();
      this.hideChangeAccountModal();
      await this.selectCompanyWrapper();
      await Promise.allSettled([
        this.fetchAdditionalVouchersWrapper(this.selectedCompanyId),
        this.fetchFormsLanguagesList({ companyId: this.selectedCompanyId }),
        this.fetchCompanyFeatures(),
        this.fetchPlatformUsersList()
      ]);
      this.$router.push(urls.MANAGE_THIRD_PARTY);
    },
    selectCompanySuccess() {
      localStorage.removeItem(localStorageItem.BULK_ACTIONS);
      this.activeCompanyName = this.companyName;
      this.$posthog.register(this.defaultProperties);
    },
    async selectCompanyWrapper() {
      try {
        await this.selectCompany(this.selectedCompanyId);
        this.selectCompanySuccess();
      } catch {
        this.errorMessage.push("Failed to set the new platform.");
      }
    },
    hideChangeAccountModal() {
      this.showChangeAccountModal = false;
    },
    checkUpdatePassword() {
      if (this.passwordCreated == 2 && this.updatePassword == 0) {
        this.showChangePassword();
      }
    },
    navigateToHome() {
      this.$router.push(this.permittedLandingPage);
    },
    showChangePassword() {
      this.setPasswordCreated(userPassword.RESET_PASSWORD);
    },
    onBulkUpdateClick() {
      this.$router.push({ name: routeNames.BULK_UPDATE });
      this.$refs.bulkUpdateButton?.$el.blur();
    },
    handleOpenChatClick() {
      const launcher = document.querySelector("#launcher");

      if (launcher?.style?.display !== "block") {
        this.displayAndTriggerLauncher(launcher);
      }
    },
    displayAndTriggerLauncher(launcher) {
      const launcherButton =
        launcher.contentWindow.document.querySelector("button");
      const messagingWindow = document.querySelector(
        "iframe[title='Messaging window']"
      );

      launcher.style.display = "block";

      if (!messagingWindow || messagingWindow?.tabIndex !== 0) {
        launcherButton.click();
      }

      setTimeout(() => {
        document.querySelector(
          "iframe[title='Messaging window']"
        ).style.display = "block";
      }, 500);

      launcherButton.addEventListener(
        "click",
        () => {
          launcher.style.display = "none";
          document.querySelector(
            "iframe[title='Messaging window']"
          ).style.display = "none";
        },
        { once: true }
      );
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/custom.scss";

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  height: 490px !important;
}
.videoModalBody {
  height: 550px;
}
.capitalize {
  text-transform: capitalize;
}
.dropdown-item {
  color: $jet !important;
}
.header-dropdown-outline {
  outline: 0;
}
.header-dropdown {
  outline: 0;
}
.header-dropdown::after {
  margin-left: 25px;
  transform: translate3d(10rem, -1.7rem, 0);
}
.header-dropdown .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0em solid !important;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-submenu {
  position: relative;
}
.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}
.dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: $chinese-silver;
  margin-top: 5px;
  margin-right: -10px;
}
.dropdown-submenu:hover > a:after {
  border-left-color: $white;
}
.dropdown-submenu.pull-left {
  float: none;
}
.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}

.header__dropdown-sub-menu {
  height: 200px;
  overflow-y: auto;
  right: 100% !important;
  top: -11px !important;
}
</style>

<style lang="scss" scoped>
.app-header {
  display: flex;
  align-items: center;
  background-color: $primary-color;
  padding: $spacing12 $spacing32;
  gap: $spacing16;

  &__logo {
    cursor: pointer;
    height: 36px;
  }

  &__version {
    flex: 1;
    text-align: end;
  }

  &__button {
    display: flex;
    align-items: center;
    gap: $spacing8;
    height: fit-content;
    width: fit-content;
    border-radius: $spacing16;
    padding: $spacing8 $spacing20;
  }

  :deep(.dropdown-menu-with-cta__cta) {
    display: flex;
    align-items: center;
    gap: $spacing8;
    height: fit-content;
    width: fit-content;
    border-radius: $spacing16;
    padding: $spacing8 $spacing20;
    flex-direction: row-reverse;
  }
}
</style>
