const actionKeys = {
  ID: "id",
  NAME: "name",
  DATA: "data",
  TYPE: "type",
  DESCRIPTION: "description",
  EXPECTED_DATA_DEFINITION: "expectedDataDefinition",
  FUNCTION_BODY: "functionBody",
  IS_ACTIVE: "isActive",
  IS_LICENSED: "isLicensed",
  CREATED_AT: "createdAt",
  UPDATED_AT: "updatedAt",
  IS_SYSTEM: "isSystem",
  ACTION: "action",
  COMPANY_ID: "companyId",
  VARIABLES: "variables",
  ENTITY: "entity",
  LOCAL: "local",
  OPTIONS: "options",
  EXPECTED_DATA_VARIABLE: "expectedDataVariable",
  STEP_TYPE: "stepType",
  VARIABLE_SCOPE: "variableScope",
  VARIABLE_TYPE: "variableType",
  VARIABLE_NAME: "variableName",
  VALUE_TYPE: "valueType",
  VALUE_DATA_TYPE: "valueDataType",
  VALUE: "value",
  BLOCKS: "blocks",
  DISPLAY_VALUE_TYPE: "displayValueType",
  VARIABLE: "variable",
  IF_BLOCK: "ifBlock",
  ELSE_IF_BLOCKS: "elseIfBlocks",
  ELSE_BLOCK: "elseBlock",
  CONDITION: "condition",
  CONDITIONS: "conditions",
  COMPARATOR: "comparator",
  OPERATOR: "operator",
  LEFT: "left",
  RIGHT: "right",
  IS_INVERSE: "isInverse",
  IS_GROUP: "isGroup",
  COMPONENT: "component",
  SUCCESS_STEPS: "successSteps",
  EXPECTED_DATA_MAPPING: "expectedDataMapping",
  ACTION_ID: "actionId",
  TRIGGER_DATA: "triggeringData",
  TRIGGERED_EXPECTED_DATA_VARIABLE_NAME: "triggeredExpectedDataVariableName",
  DDQ_ID: "ddqId",
  CONTEXT: "context",
  FORM_ID: "formId",
  REFERENCE_URL: "referenceUrl",
  REFERENCES: "references",
  TITLE: "title",
  SECTIONS: "sections",
  ELEMENTS: "elements",
  KEY: "key",
  PROXY_NAME: "proxyName",
  ATTRIBUTE_NAME: "attributeName",
  IS_ENUM: "isEnum",
  IS_READ_ONLY: "isReadOnly",
  IS_CUSTOM_ATTRIBUTE: "isCustomAttribute",
  IDENTIFIER: "identifier",
  SUB_TYPE: "subType",
  COMMENT: "comment",
  IS_ARRAY: "isArray",
  OPERATIONS: "operations",
  OPERATION: "operation",
  INPUT_TYPE: "inputType",
  OUTPUT_TYPE: "outputType",
  SETTINGS: "settings",
  DECIMALS: "decimals",
  SPECIFICATION: "specification",
  TEMPLATE_ID: "templateId",
  TO: "to",
  CC: "cc",
  BCC: "bcc",
  EMAIL: "email",
  ROLE_ID: "roleId",
  USER_ID: "userId",
  ASSIGNEE: "assignee",
  COURSE_ID: "courseId",
  INPUTS: "inputs",
  EDD_REPORT_TYPE_ID: "eddReportTypeId"
};

const actionKeysApi = {
  ID: "id",
  NAME: "name",
  ENTITY: "entity",
  LOCAL: "local",
  DESCRIPTION: "description",
  EXPECTED_DATA_DEFINITION: "expected_data_definition",
  COMPANY_ID: "company_id",
  VARIABLES: "variables",
  STEP_TYPE: "step_type",
  FUNCTION_BODY: "function_body",
  IS_LICENSED: "is_licensed",
  IS_ACTIVE: "is_active",
  CREATED_AT: "created_at",
  UPDATED_AT: "updated_at",
  IS_SYSTEM: "is_system",
  VARIABLE: "variable",
  TYPE: "type",
  DATA: "data",
  ACTION: "action",
  EXPECTED_DATA_VARIABLE: "expectedDataVariable",
  VARIABLE_SCOPE: "variableScope",
  VARIABLE_TYPE: "variableType",
  VARIABLE_NAME: "variableName",
  VALUE_TYPE: "valueType",
  VALUE_DATA_TYPE: "valueDataType",
  VALUE: "value",
  BLOCKS: "blocks",
  IF_BLOCK: "if_block",
  ELSE_IF_BLOCKS: "else_if_blocks",
  ELSE_BLOCK: "else_block",
  CONDITION: "condition",
  CONDITIONS: "conditions",
  SUCCESS_STEPS: "success_steps",
  COMPARATOR: "comparator",
  OPERATOR: "operator",
  LEFT: "left",
  RIGHT: "right",
  IS_INVERSE: "is_inverse",
  IS_GROUP: "is_group",
  SUB_TYPE: "sub_type",
  IS_ENUM: "is_enum",
  IS_READ_ONLY: "is_read_only",
  IS_CUSTOM_ATTRIBUTE: "is_custom_attribute",
  IDENTIFIER: "identifier",
  ACTION_ID: "action_id",
  EXPECTED_DATA_MAPPING: "expected_data_mapping",
  TRIGGER_DATA: "triggering_data",
  TRIGGERED_EXPECTED_DATA_VARIABLE_NAME:
    "triggered_expected_data_variable_name",
  DDQ_ID: "ddq_id",
  CONTEXT: "context",
  FORM_ID: "form_id",
  REFERENCE_URL: "reference_url",
  TITLE: "title",
  SECTIONS: "sections",
  ELEMENTS: "elements",
  KEY: "key",
  PROXY_NAME: "proxy_name",
  ATTRIBUTE_NAME: "attribute_name",
  OPTIONS: "options",
  COMMENT: "comment",
  IS_ARRAY: "is_array",
  OPERATIONS: "operations",
  OPERATION: "operation",
  INPUT_TYPE: "input_type",
  OUTPUT_TYPE: "output_type",
  SETTINGS: "settings",
  DECIMALS: "decimals",
  SPECIFICATION: "specification",
  TEMPLATE_ID: "template_id",
  TO: "to",
  CC: "cc",
  BCC: "bcc",
  EMAIL: "email",
  ROLE_ID: "role_id",
  USER_ID: "user_id",
  ASSIGNEE: "assignee",
  COURSE_ID: "course_id",
  INPUTS: "inputs",
  EDD_REPORT_TYPE_ID: "edd_report_type_id"
};

export { actionKeys, actionKeysApi };
