import { expectedDataService } from "@/services";

export const actions = {
  async fetchExpectedData({ rootState, commit }) {
    try {
      commit("expectedData/setIsLoadingExpectedData", true, {
        root: true
      });
      const { data = {} } = await expectedDataService.fetchExpectedData(
        rootState.company.companyId
      );
      commit("expectedData/setExpectedData", data, {
        root: true
      });
    } finally {
      commit("expectedData/setIsLoadingExpectedData", false, {
        root: true
      });
    }
  },
  async fetchEntitiesDynamicReference({ commit, state }, id) {
    let entityIndex;
    const { referenceUrl } = state.expectedData.find((data, index) => {
      entityIndex = index;
      return data?.context?.formId === id;
    });
    const { data = {} } =
      await expectedDataService.fetchEntitiesDynamicReference(referenceUrl);
    commit(
      "expectedData/setExpectedData",
      {
        references: data,
        index: entityIndex
      },
      {
        root: true
      }
    );
    return entityIndex;
  }
};
