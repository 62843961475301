import { onboardingTasksService } from "@/services";

export const actions = {
  async fetchOnboardingTasks({ commit, rootState }) {
    const { userId = "" } = rootState.user;
    try {
      commit("onboardingTasks/setIsOnboardingTasksLoading", true, {
        root: true
      });
      const { data } = await onboardingTasksService.fetchOnboardingTasks(
        userId
      );
      commit("onboardingTasks/setOnboardingTasks", data, {
        root: true
      });
    } finally {
      commit("onboardingTasks/setIsOnboardingTasksLoading", false, {
        root: true
      });
    }
  },
  async fetchOnboardingTask({ commit }, { taskId, taskType, userId }) {
    const { data } = await onboardingTasksService.fetchOnboardingTask({
      userId,
      taskId,
      taskType
    });
    commit("onboardingTasks/setOnboardingTask", data, {
      root: true
    });
  }
};
