<template>
  <div class="px-5" :dir="$store.state.rightAlign ? 'rtl' : 'ltr'">
    <h2 v-if="!isUserNotUpdated" class="mb-5">
      {{ $t("createProfile.titleUpdate") }}
    </h2>
    <h2 v-else class="mb-5">{{ $t("createProfile.title") }}</h2>
    <b-row>
      <b-col class="text-left" cols="6">
        <div class="headers">
          <h4 class="text-muted mb-3">
            {{ $t("createProfile.contactHeading") }}
          </h4>
        </div>
        <b-form>
          <b-form-group label-cols-lg="4" label-for="email-profile">
            <template #label>
              {{ $t("createProfile.email") }}
            </template>
            <b-form-input
              id="email-profile"
              disabled
              v-model="registration.email"
              type="text"
            ></b-form-input>
          </b-form-group>

          <b-form-group label-cols-lg="4">
            <template #label>
              <span class="required-span">*</span>
              {{ $t("createProfile.fNameLabel") }}
            </template>
            <b-form-input
              v-model="v$.registration.firstname.$model"
              :state="
                v$.registration.firstname.$dirty
                  ? !v$.registration.firstname.$error
                  : null
              "
              type="text"
              :placeholder="$t('createProfile.fNamePlaceholder')"
            ></b-form-input>
            <b-form-invalid-feedback>{{
              $t("createProfile.requiredValidationMessage")
            }}</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label-cols-lg="4">
            <template #label>
              <span class="required-span">*</span>
              {{ $t("createProfile.lNameLabel") }}
            </template>
            <b-form-input
              v-model="v$.registration.lastname.$model"
              :state="
                v$.registration.lastname.$dirty
                  ? !v$.registration.lastname.$error
                  : null
              "
              :placeholder="$t('createProfile.lNamePlaceholder')"
            ></b-form-input>
            <b-form-invalid-feedback>{{
              $t("createProfile.requiredValidationMessage")
            }}</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label-cols-lg="4">
            <template #label>
              {{ $t("createProfile.contactLabel") }}
            </template>
            <b-form-input
              v-model="registration.contactNumber"
              :placeholder="$t('createProfile.contactPlaceholder')"
            ></b-form-input>
          </b-form-group>

          <b-form-group label-cols-lg="4">
            <template #label>
              {{ $t("createProfile.jobTitleLabel") }}
            </template>
            <b-form-input
              v-model="registration.jobTitle"
              :placeholder="$t('createProfile.jobTitlePlaceholder')"
            ></b-form-input>
          </b-form-group>
        </b-form>
      </b-col>
      <b-col class="text-left" cols="6">
        <div class="headers">
          <h4 class="text-muted mb-3">
            {{ $t("createProfile.passwordHeading") }}
          </h4>
        </div>
        <b-form>
          <b-form-group label-cols-lg="4">
            <template #label>
              <span class="required-span">*</span>
              {{ $t("createProfile.currentPasswordLabel") }}
            </template>
            <b-form-input
              v-model="v$.registration.currentPassword.$model"
              :state="
                v$.registration.currentPassword.$dirty
                  ? !v$.registration.currentPassword.$error
                  : null
              "
              type="password"
              :placeholder="$t('createProfile.currentPasswordPlaceholder')"
            ></b-form-input>
            <b-form-invalid-feedback>{{
              $t("createProfile.requiredValidationMessage")
            }}</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label-cols-lg="4">
            <template #label>
              <span class="required-span">*</span>
              {{ $t("createProfile.newPasswordLabel") }}
            </template>
            <b-form-input
              v-model="v$.registration.newPassword.$model"
              :state="
                v$.registration.newPassword.$dirty
                  ? !v$.registration.newPassword.$error
                  : null
              "
              type="password"
              :placeholder="$t('createProfile.newPasswordPlaceholder')"
            ></b-form-input>
            <b-form-invalid-feedback>{{
              $t("createProfile.requiredValidationMessage")
            }}</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label-cols-lg="4">
            <template #label>
              <span class="required-span">*</span>
              {{ $t("createProfile.confirmPasswordLabel") }}
            </template>
            <b-form-input
              v-model="v$.registration.confirmNewPassword.$model"
              :state="
                v$.registration.confirmNewPassword.$dirty
                  ? !v$.registration.confirmNewPassword.$error
                  : null
              "
              type="password"
              :placeholder="$t('createProfile.confirmPasswordPlaceholder')"
            ></b-form-input>
            <b-form-invalid-feedback
              class="error"
              v-if="v$.registration.confirmNewPassword.sameAsPassword?.$invalid"
              >{{
                $t("createProfile.passwordValidationMessage")
              }}</b-form-invalid-feedback
            >
          </b-form-group>
        </b-form>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-button
          :disabled="isLoading"
          class="float-right"
          type="button"
          variant="primary"
          @click="onSubmit"
          ><b-spinner v-if="isLoading" small></b-spinner
          >{{ $t("updateBtn") }}</b-button
        >
      </b-col>
    </b-row>
    <AppLoaderModal :content="$t('createProfile.loaderMessage')" />
    <b-alert
      :dir="$store.state.rightAlign ? 'rtl' : 'ltr'"
      :content-class="$store.state.rightAlign ? 'text-right' : 'text-left'"
      v-model="showErrorToast"
      class="position-fixed fixed-top m-0 rounded-0"
      style="z-index: 2000"
      variant="danger"
      dismissible
      solid
      >{{ $t("validations.wrongPasswordMessage") }}</b-alert
    >
  </div>
</template>

<script>
import AppLoaderModal from "@/components/Loader/LoaderModal";
import { HTTP, makeAuthorizationHeader } from "@/utils";
import { useVuelidate } from "@vuelidate/core";
import { minLength, required, sameAs } from "@vuelidate/validators";
import { mapGetters, mapMutations, mapState } from "vuex";
import { urls } from "@/constants";

export default {
  name: "RegisterPage",
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  components: {
    AppLoaderModal
  },
  data() {
    return {
      registration: {},
      error: false,
      showErrorToast: false,
      isLoading: false
    };
  },
  computed: {
    ...mapState({
      userFirstName: (state) => state.user.userFirstName,
      userLastName: (state) => state.user.userLastName,
      email: (state) => state.user.email,
      userId: (state) => state.user.userId
    }),
    ...mapGetters({
      isUserThirdParty: "user/isUserThirdParty",
      isUserNotUpdated: "user/isUserNotUpdated"
    })
  },
  created() {
    this.registration = {
      email: this.email,
      firstname: this.userFirstName,
      lastname: this.userLastName,
      jobTitle: null,
      contactNumber: null,
      currentPassword: null,
      newPassword: null,
      confirmNewPassword: null,
      registrationStatus: 1
    };
  },
  validations() {
    return {
      registration: {
        firstname: {
          required
        },
        lastname: {
          required
        },
        currentPassword: {
          required
        },
        newPassword: {
          required,
          minLength: minLength(6)
        },
        confirmNewPassword: {
          sameAsPassword: sameAs(this.registration.newPassword)
        }
      }
    };
  },

  methods: {
    ...mapMutations({
      setRegistrationStatus: "user/setRegistrationStatus"
    }),
    onSubmit() {
      this.showErrorToast = false;
      this.v$.registration?.$touch();
      if (this.v$.registration.$errors?.length) {
        return false;
      } else {
        this.updateRegistration();
      }
    },
    async updateRegistration() {
      this.isLoading = true;
      this.showErrorToast = false;
      let registration = this.registration;
      try {
        let result = await HTTP(
          "post",
          "v1/mytpp/registration/update",
          {
            userId: this.userId,
            registration
          },
          makeAuthorizationHeader(),
          null,
          true
        );
        this.isLoading = false;
        if (result.data.error) {
          this.showErrorToast = true;
          return;
        }
        if (result.data.success == 1) {
          this.setRegistrationStatus(1);
          if (this.isUserThirdParty) {
            this.$router.push(urls.DASHBOARD);
          }
        }
      } catch (error) {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped>
.headers {
  display: flex;
  justify-content: center;
  margin-left: 8rem;
}
</style>
