<template>
  <div>
    <b-modal
      scrollable
      body-bg-variant="light"
      no-close-on-backdrop
      v-model="isThirdPartyAddModalVisible"
      modal-class="px-5"
      size="xl"
      centered
      @hidden="onHideModal"
      title="Create New Third Party"
    >
      <div>
        <b-row v-if="!isLoading">
          <b-col>
            <b-card
              @click="onCheckTpModalWording"
              bg-variant="default"
              class="click-cursor card-wrapper text-center"
              data-test-id="add-model__button--single"
            >
              <b-card-text>
                <strong>Single</strong>
              </b-card-text>
            </b-card>
          </b-col>
          <b-col v-if="hasBulkUploadAccess">
            <b-card
              @click="onSelectCreateType"
              bg-variant="default"
              class="click-cursor card-wrapper text-center"
              data-test-id="add-model__button--import-data"
            >
              <b-card-text>
                <strong>Import Data</strong>
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
        <BaseLoader v-else />
      </div>
      <template v-slot:modal-footer>
        <b-col cols="5" class="text-right p-0">
          <b-button
            class="red-btn"
            variant="outline-danger"
            v-if="!isLoading"
            @click="resetModal"
            >Cancel</b-button
          >
        </b-col>
      </template>
    </b-modal>
    <BulkUpload
      v-if="isBulkUploadModalVisible"
      :companyId="companyId"
      :fileFormats="fileFormats"
      @close-modal="onHideModal"
    />
    <Modal
      v-if="isSingleTpModalWordingVisible"
      title="Action"
      @close-modal="setSingleTpModalWordingVisibility(false)"
      class="single-tp-modal-wording"
    >
      <template v-slot>
        <BaseText
          class="single-tp-modal-wording-text"
          :text="getFormattedModalWording"
        />
      </template>
      <template #right>
        <CallToAction value="I Understand" @click="onUserConfirmation" />
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";
import BulkUpload from "./bulkUploadModal";
import {
  urls,
  themes,
  featurePermissionsKeys
} from "@/constants";
import BaseLoader from "@/atoms/BaseLoader/BaseLoader.vue";
import Modal from "@/molecules/Modal/Modal";
import BaseText from "@/atoms/BaseText/BaseText";
import CallToAction from "@/atoms/CallToAction/CallToAction";
import { hasPermission } from "@/utils";

export default {
  name: "AddThirdPartyModal",
  components: {
    BulkUpload,
    BaseLoader,
    Modal,
    BaseText,
    CallToAction
  },
  props: {
    companyId: Number
  },
  data() {
    return {
      themes,
      urls,
      fileFormats: [{ text: "Excel 2012", value: "application/vnd.ms-excel" }],
      isLoading: false,
      isSingleTpModalWordingVisible: false
    };
  },
  computed: {
    ...mapState({
      isThirdPartyAddModalVisible: (state) =>
        state.thirdParty.isThirdPartyAddModalVisible,
      isBulkUploadModalVisible: (state) =>
        state.thirdParty.isBulkUploadModalVisible,
      singleTpModalWording: (state) =>
        state.platformSettings.singleTpModalWording
    }),
    ...mapGetters({
      isDNBFeatureEnabled: "company/isDNBFeatureEnabled"
    }),
    getFormattedModalWording() {
      return this.singleTpModalWording.split("\n");
    },
    hasBulkUploadAccess() {
      return hasPermission({ key: featurePermissionsKeys.BULK_UPLOAD });
    }
  },
  methods: {
    ...mapMutations({
      setIsThirdPartyAddModalVisible:
        "thirdParty/setIsThirdPartyAddModalVisible",
      setIsBulkUploadModalVisible: "thirdParty/setIsBulkUploadModalVisible",
      setIsThirdPartyNewModalVisible:
        "thirdParty/setIsThirdPartyNewModalVisible",
      setIsAddThirdPartyModalNewEditable:
        "thirdParty/setIsAddThirdPartyModalNewEditable"
    }),
    async openNewModal() {
      this.isLoading = true;
      if (this.isDNBFeatureEnabled) {
        this.$router.push(urls.THIRD_PARTY_CREATE);
        this.resetModal();
      } else {
        this.setIsAddThirdPartyModalNewEditable(false);
        this.setIsThirdPartyNewModalVisible(true);
      }
    },
    onSelectCreateType() {
      this.setIsBulkUploadModalVisible(true);
    },
    resetModal() {
      this.isLoading = false;
      this.setIsThirdPartyAddModalVisible(false);
    },
    onHideModal() {
      this.setIsBulkUploadModalVisible(false);
      this.setIsThirdPartyAddModalVisible(false);
    },
    onCheckTpModalWording() {
      if (this.singleTpModalWording) {
        this.setSingleTpModalWordingVisibility(true);
      } else {
        this.openNewModal();
      }
    },
    setSingleTpModalWordingVisibility(value) {
      this.isSingleTpModalWordingVisible = value;
    },
    onUserConfirmation() {
      this.setSingleTpModalWordingVisibility(false);
      this.openNewModal();
    }
  }
};
</script>
<style lang="scss">
.click-cursor:hover {
  cursor: pointer;
}
.card-wrapper {
  box-shadow: 0 0px 0px rgba($black, 0.15);
  transition: box-shadow 0.3s ease-in-out;
}
.card-wrapper:hover {
  box-shadow: 2px 5px 10px 0px $granite-gray;
  transition: box-shadow 0.3s ease-in-out;
}
.green-btn {
  color: $eucalyptus;
  border-color: $eucalyptus;
}
.green-btn:hover {
  color: $white;
  background-color: $eucalyptus;
  border-color: $eucalyptus;
}
.red-btn {
  color: $alizarin-crimson;
  border-color: $alizarin-crimson;
}
.red-btn:hover {
  color: $white;
  background-color: $alizarin-crimson;
  border-color: $alizarin-crimson;
}
.single-tp-modal-wording .modal {
  max-width: 900px;
  text-align: left;
  padding: 15px;
}
.single-tp-modal-wording-text p {
  margin: 0;
}
</style>
