<template>
  <section class="task_templates">
    <BaseLoader v-if="isLoadingTableData" />
    <template v-else>
      <Modal
        class="task_templates__modal"
        v-if="isModalVisible"
        title="Delete task template"
        @close-modal="toggleModal"
      >
        <div class="task_templates__modal-text">
          <BaseText :text="makeModalText" />
        </div>
        <template #left>
          <CallToAction
            type="button"
            theme="error-inverse"
            value="Cancel"
            data-test-id="task_templates__modal-cancel-cta"
            @click="onCancelDeleteTaskTemplate"
          />
        </template>
        <template #right>
          <CallToAction
            type="button"
            value="Delete"
            data-test-id="task_templates__modal-delete-cta"
            @click="deleteTaskTemplateWrapper"
          />
        </template>
      </Modal>
      <div class="task_templates__operations">
        <CallToAction
          theme="strong"
          value="Add"
          data-test-id="task_templates__operations-cta"
          @click="onCTAAdd"
        />
      </div>
      <BaseText
        :text="operationFeedback"
        theme="success-inverse"
        data-test-id="task_templates__operations-feedback"
      />
      <InputErrorMessage :error="errorMessagesApi" />
      <StickyTable
        v-if="isDataAvailable"
        caption="Task templates"
        class="task_templates__table"
        data-test-id="task_templates__table"
        :table-headers="taskTemplatesHeaders"
        :table-rows="taskTemplatesRows"
        @table-row-click="onTableRowClick"
      />
      <BaseText
        v-else
        text="You do not have any task templates. Click the Add button to create one."
        data-test-id="task_templates__table-fallback-message"
      />
    </template>
  </section>
</template>

<script>
import StickyTable from "@/molecules/StickyTable/StickyTable";
import DropDownList from "@/molecules/DropDownList/DropDownList";
import InputErrorMessage from "@/molecules/InputErrorMessage/InputErrorMessage";
import { mapState, mapActions } from "vuex";
import {
  taskTemplatesListTableHeaders,
  typographySize,
  taskTemplateKeysDTO,
  operations,
  urls,
  taskTemplateEvents
} from "@/constants";
import BaseText from "@/atoms/BaseText/BaseText";
import CallToAction from "@/atoms/CallToAction/CallToAction";
import Modal from "@/molecules/Modal/Modal";
import BaseLoader from "@/atoms/BaseLoader/BaseLoader";
import { hasStatus500, hasStatus404, isNotAuthorized } from "@/utils";

export default {
  name: "TaskTemplates",
  components: {
    StickyTable,
    BaseText,
    CallToAction,
    InputErrorMessage,
    Modal,
    BaseLoader
  },
  data() {
    return {
      operationFeedback: "",
      errorMessagesApi: "",
      selectedTaskTemplate: {
        id: "",
        name: ""
      },
      isModalVisible: false,
      isLoadingTableData: false
    };
  },
  computed: {
    ...mapState({
      taskTemplates: (state) => state.taskTemplates.taskTemplates
    }),
    makeTaskTemplatesListForTable() {
      return (
        this.taskTemplates?.map(({ templateName, templateDescription }) => ({
          [taskTemplateKeysDTO.TEMPLATE_NAME]: templateName,
          [taskTemplateKeysDTO.TEMPLATE_DESCRIPTION]: templateDescription,
          [taskTemplateKeysDTO.ACTION]: "Action"
        })) || []
      );
    },
    isDataAvailable() {
      return (
        !!this.taskTemplatesHeaders.length && !!this.taskTemplatesRows.length
      );
    },
    taskTemplatesHeaders() {
      return this.makeTaskTemplatesListForTable.length
        ? Object.keys(this.makeTaskTemplatesListForTable[0]).reduce(
            (acc, key) => {
              if (taskTemplatesListTableHeaders[key]) {
                acc.push({
                  value: key,
                  component: BaseText,
                  componentOptions: {
                    tag: "span",
                    text: taskTemplatesListTableHeaders[key],
                    size: typographySize.BODY_TEXT_BOLD,
                    styles: {
                      margin: "0"
                    }
                  },
                  styles: {
                    verticalAlign: "top",
                    background: "white",
                    padding: "5px"
                  }
                });
              }
              return acc;
            },
            []
          )
        : [];
    },
    taskTemplatesRows() {
      return (
        this.makeTaskTemplatesListForTable?.map((template, index) =>
          Object.keys(template).reduce((acc, templateKey) => {
            if (templateKey === taskTemplateKeysDTO.ACTION) {
              acc[templateKey] = {
                component: DropDownList,
                componentOptions: {
                  dropDownList: [
                    {
                      value: JSON.stringify({
                        operation: operations.EDIT,
                        id: this.taskTemplates[index].id,
                        name: this.taskTemplates[index].templateName
                      }),
                      text: "Edit"
                    },
                    {
                      value: JSON.stringify({
                        operation: operations.CLONE,
                        id: this.taskTemplates[index].id,
                        name: this.taskTemplates[index].templateName
                      }),
                      text: "Clone"
                    },
                    {
                      value: JSON.stringify({
                        operation: operations.DELETE,
                        id: this.taskTemplates[index].id,
                        name: this.taskTemplates[index].templateName
                      }),
                      text: "Delete"
                    }
                  ],
                  style: {
                    display: "inline-block"
                  }
                }
              };
            } else {
              acc[templateKey] = {
                component: BaseText,
                componentOptions: {
                  tag: "span",
                  text: template[templateKey],
                  styles: {
                    padding: "0px 5px"
                  }
                }
              };
            }
            return acc;
          }, {})
        ) || []
      );
    },
    makeModalText() {
      return `Are you sure you want to delete the task template, ${this.selectedTaskTemplate.name}?`;
    }
  },
  methods: {
    ...mapActions({
      deleteTaskTemplate: "taskTemplates/deleteTaskTemplate",
      cloneTaskTemplate: "taskTemplates/cloneTaskTemplate"
    }),
    onCancelDeleteTaskTemplate() {
      this.resetSelectedTaskTemplate();
      this.toggleModal();
    },
    toggleModal() {
      this.isModalVisible = !this.isModalVisible;
    },
    onCTAAdd() {
      this.$emit(taskTemplateEvents.RESET_TASK_TEMPLATE);
      this.$router.push(urls.ADD_TASK_TEMPLATE);
    },
    setSelectedTaskTemplate({ id = "", name = "" } = {}) {
      this.selectedTaskTemplate.id = id;
      this.selectedTaskTemplate.name = name;
    },
    resetSelectedTaskTemplate() {
      this.setSelectedTaskTemplate();
    },
    onTableRowClick(tableRowObj) {
      const { operation, id, name } = JSON.parse(tableRowObj);
      if (operation === operations.EDIT) {
        this.$router.push(urls.EDIT_TASK_TEMPLATE_ID(id));
        this.$emit(taskTemplateEvents.FETCH_TASK_TEMPLATE, id);
      } else if (operation === operations.DELETE) {
        this.setSelectedTaskTemplate({ id, name });
        this.toggleModal();
      } else if (operation === operations.CLONE) {
        this.cloneTaskTemplateWrapper(id);
      }
    },
    setInitialData() {
      this.isLoadingTableData = true;
      this.operationFeedback = "";
      this.errorMessagesApi = "";
    },
    async cloneTaskTemplateWrapper(id) {
      try {
        this.setInitialData();
        await this.cloneTaskTemplate({
          id,
          isLoadingTaskTemplatesSuppressed: true
        });
        this.operationFeedback = "Task template cloned successfully";
      } catch (error) {
        this.setErrorMessageApi({
          response: error?.response,
          type: operations.CLONE
        });
      } finally {
        this.isLoadingTableData = false;
      }
    },
    async deleteTaskTemplateWrapper() {
      try {
        this.toggleModal();
        this.setInitialData();
        await this.deleteTaskTemplate({
          id: this.selectedTaskTemplate.id,
          isLoadingTaskTemplatesSuppressed: true
        });
        this.operationFeedback = "Task template deleted successfully";
      } catch (error) {
        this.setErrorMessageApi({
          response: error?.response,
          type: operations.DELETE
        });
      } finally {
        this.resetSelectedTaskTemplate();
        this.isLoadingTableData = false;
      }
    },
    setErrorMessageApi({ response = {}, type = "" } = {}) {
      if (hasStatus500(response)) {
        this.errorMessagesApi =
          "There is a server error. Please contact support.";
      } else if (hasStatus404(response)) {
        this.errorMessagesApi = `Failed to find and ${type} the selected task template.`;
      } else if (!isNotAuthorized(response)) {
        this.errorMessagesApi =
          "There is an unknown error. Please contact support.";
      }
    }
  },
  mounted() {
    this.operationFeedback = history.state?.message || "";
  }
};
</script>

<style lang="scss" scoped>
.task_templates {
  height: calc(100vh - 130px);
  overflow-y: scroll;
  padding: 0 20px 0 0;
  text-align: left;

  &__modal-text {
    padding: 15px 15px 0;
  }

  &__operations {
    text-align: right;
    padding-bottom: 20px;
  }
}
</style>
