<template>
  <div class="settings">
    <div class="settings__header-wrapper">
      <BaseText
        :size="typographySize.BODY_TEXT_BOLD"
        :text="`Platform Features for ${companyName}`"
      />
    </div>
    <div v-show="isLoading" class="settings__body">
      <BaseLoader />
    </div>
    <div v-show="!isLoading" class="settings__body">
      <div class="settings__body-wrapper">
        <BaseText
          text="MSQ V2 autofill field"
          class="settings__body-wrapper-label"
        />
        <Checkbox
          :size="16"
          class="settings__body-wrapper-value"
          v-model="isMsqAutoFieldEnabled"
          @update:modelValue="updateAutoFieldStatus"
        />
      </div>
      <div
        v-for="(companyFeature, index) in customCompanyFeatures"
        :key="index"
        class="settings__body-wrapper"
      >
        <BaseText
          :text="companyFeature.name"
          class="settings__body-wrapper-label"
        />
        <Checkbox
          v-if="companyFeature.type === settingsType.BOOLEAN"
          :size="16"
          class="settings__body-wrapper-value"
          v-model="companyFeature.value"
          @update:modelValue="
            updateCompanyFeatureWrapper(
              companyFeature.settingId,
              companyFeature.value,
              companyFeature.type
            )
          "
        />
        <Input
          v-else-if="companyFeature.type === settingsType.STRING"
          id="companyFeatureString"
          class="settings__body-wrapper-value"
          :value="companyFeature.value"
          @change="
            updateCompanyFeatureWrapper(
              companyFeature.settingId,
              $event,
              companyFeature.type
            )
          "
        />
        <Select
          v-else-if="companyFeature.type === settingsType.DROPDOWN"
          id="companyFeatureDropdown"
          :options="companyFeature.settingOption"
          class="settings__body-wrapper-value"
          @change="
            onChangeDropdownItems(
              companyFeature.settingId,
              $event,
              companyFeature.type
            )
          "
        />
        <Input
          v-else-if="companyFeature.type === settingsType.INTEGER"
          id="companyFeatureString"
          class="settings__body-wrapper-value"
          type="number"
          :value="companyFeature.value"
          @change="
            updateCompanyFeatureWrapper(
              companyFeature.settingId,
              $event,
              companyFeature.type
            )
          "
        />
        <v-select
          v-else-if="companyFeature.type === settingsType.MULTISELECT"
          label="text"
          v-model="companyFeature.value"
          :options="companyFeature.settingOption"
          :clearable="true"
          :multiple="true"
          class="settings__body-wrapper-value"
          @update:modelValue="
            onMultiSelectUpdate(
              companyFeature.settingId,
              $event,
              companyFeature.type
            )
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import BaseText from "@/atoms/BaseText/BaseText";
import {
  localStorageItem,
  settingsType,
  typographySize,
  companyFeatureValues
} from "@/constants";
import { thirdPartyService } from "@/services";
import Input from "@/molecules/Input/Input";
import Select from "@/molecules/Select/Select";
import { hasStatus200 } from "@/utils";
import { makeOptionsForSelect } from "@/molecules/Select/Select.dto";
import BaseLoader from "@/atoms/BaseLoader/BaseLoader";
import Checkbox from "@/molecules/Checkbox/Checkbox";

export default {
  name: "Setting",
  components: {
    BaseLoader,
    BaseText,
    Input,
    Select,
    Checkbox
  },
  data() {
    return {
      customCompanyFeatures: [],
      isMsqAutoFieldEnabled: false,
      companyName: "",
      typographySize,
      settingsType,
      isLoadingAutoFieldStatus: false,
      isLoadingUpdateCompanyFeature: false,
      isLoadingCustomCompanyFeatures: false
    };
  },
  computed: {
    isLoading() {
      return (
        this.isLoadingCustomCompanyFeatures ||
        this.isLoadingAutoFieldStatus ||
        this.isLoadingUpdateCompanyFeature
      );
    }
  },
  async created() {
    await this.fetchCompanyFeaturesWrapper();
    this.companyName = localStorage.getItem(
      localStorageItem.OPENED_COMPANY_PROFILE
    );
    this.isMsqAutoFieldEnabled =
      localStorage.getItem(localStorageItem.AUTO_FILL_MSQ_V2) ===
      companyFeatureValues.ENABLED;
  },
  methods: {
    makeOptionsForSelect,
    ...mapActions({
      fetchCustomCompanyFeatures:
        "company/fetchCustomCompanyFeatures",
      updateCompanyFeature: "company/updateCompanyFeature"
    }),
    async updateAutoFieldStatus(value) {
      const status = value
        ? companyFeatureValues.ENABLED
        : companyFeatureValues.DISABLED;
      try {
        this.isLoadingAutoFieldStatus = true;
        const result = await thirdPartyService.updateAutoFieldStatus({
          comp_id: this.$route.params.id,
          status
        });
        if (result?.data?.status === 1 && hasStatus200(result)) {
          localStorage.setItem(localStorageItem.AUTO_FILL_MSQ_V2, status);

          this.successToastMessage(
            `MSQ V2 autofill field has been ${value ? "enabled" : "disabled"}`
          );
        }
      } catch {
        this.failureToastMessage(
          `Failed to ${value ? "enable" : "disable"} MSQ V2 autofill field`
        );
        this.isMsqAutoFieldEnabled = false;
      } finally {
        this.isLoadingAutoFieldStatus = false;
      }
    },
    async fetchCompanyFeaturesWrapper() {
      try {
        this.isLoadingCustomCompanyFeatures = true;
        const { data } = await this.fetchCustomCompanyFeatures(
          this.$route.params.id
        );
        this.fetchCompanyFeaturesSuccess(data);
      } catch {
        this.failureToastMessage(
          `Failed to fetch the company features for ${this.companyName}. Please contact support`
        );
      } finally {
        this.isLoadingCustomCompanyFeatures = false;
      }
    },
    fetchCompanyFeaturesSuccess(data = []) {
      this.customCompanyFeatures.push(...data);
      this.customCompanyFeatures.forEach((companyFeature) => {
        if (companyFeature?.type === settingsType.DROPDOWN) {
          companyFeature.settingOption = this.makeOptionsForSelect(
            companyFeature?.value,
            companyFeature?.settingOption
          );
        } else if (companyFeature?.type === settingsType.BOOLEAN) {
          companyFeature.value =
            companyFeature.value === companyFeatureValues.ENABLED;
        }
      });
    },
    failureToastMessage(message = "") {
      this.$bvToast.toast(message, {
        title: "Error",
        variant: "danger",
        autoHideDelay: 2000,
        appendToast: false
      });
    },
    successToastMessage(message = "") {
      this.$bvToast.toast(message, {
        title: "Done",
        variant: "success",
        autoHideDelay: 2000,
        appendToast: false
      });
    },
    onChangeDropdownItems(settingId, settingValue, type) {
      const companyFeature = this.customCompanyFeatures.find(
        (companyFeature) => companyFeature.settingId === settingId
      );
      companyFeature.settingOption = this.makeOptionsForSelect(
        settingValue,
        companyFeature?.settingOption
      );
      this.updateCompanyFeatureWrapper(settingId, settingValue, type);
    },
    onMultiSelectUpdate(settingId, settingValue, type) {
      this.updateCompanyFeatureWrapper(
        settingId,
        settingValue.map(({ id }) => id),
        type
      );
    },
    makeCompanyFeaturePayload(settingId, settingValue) {
      return {
        payload: {
          setting_id: settingId,
          value: settingValue
        },
        companyId: this.$route.params.id
      };
    },
    getCompanyFeatureBasedOnSettingId(settingId) {
      return (
        this.customCompanyFeatures.find(
          (customCompanyFeature) => customCompanyFeature.settingId === settingId
        ) || {}
      );
    },
    async updateCompanyFeatureWrapper(settingId, settingValue, type) {
      const companyFeature = this.getCompanyFeatureBasedOnSettingId(settingId);
      const { name } = companyFeature;
      try {
        this.isLoadingUpdateCompanyFeature = true;
        await this.updateCompanyFeature({
          companyFeature: this.makeCompanyFeaturePayload(
            settingId,
            settingValue
          )
        });
        if (type === settingsType.BOOLEAN) {
          this.successToastMessage(
            `${name} has been ${settingValue ? "enabled" : "disabled"}`
          );
        } else {
          this.successToastMessage(`${name} has been set to ${settingValue}`);
        }

        companyFeature.value = settingValue;
      } catch {
        if (type === settingsType.BOOLEAN) {
          this.failureToastMessage(
            `Failed to ${
              settingValue ? "enable" : "disable"
            } ${name}. Please contact support`
          );
          companyFeature.value = false;
        } else {
          this.failureToastMessage(`Failed to set ${name} to ${settingValue}`);
        }
      } finally {
        this.isLoadingUpdateCompanyFeature = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.settings {
  &:deep(.settings__body-wrapper-label) {
    margin-top: 3px;
  }
  &:deep(.settings__body-wrapper-value) {
    margin-left: auto;
  }

  &__header-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  &__body {
    height: calc(100vh - 120px);
    overflow-y: auto;

    &-wrapper {
      text-align: left;
      margin-top: 20px;
      display: flex;

      &-value {
        margin-left: auto;
      }
    }
  }
}
</style>
