import { destinationTypes } from "@/constants";

export const getters = {
  getRolesDestinationOptions: (_, __, rootState) =>
    rootState.roles.roles.map(({ id, name }) => ({
      text: name,
      value: id,
      type: destinationTypes.ROLE,
      companyId: rootState.company.companyId
    })),
  getUsersDestinationOptions: (_, __, rootState) =>
    rootState.platformSettings.platformUserList.map(({ name, memberId }) => ({
      text: name,
      value: memberId,
      type: destinationTypes.USER,
      companyId: rootState.company.companyId
    })),
  getDestinationBaseOptions: (_, __, ___, rootGetters) => [
    ...rootGetters["userEmailTemplates/getRolesDestinationOptions"],
    ...rootGetters["userEmailTemplates/getUsersDestinationOptions"]
  ],
  getUserEmailTemplatesOptions: (state) =>
    state.userEmailTemplates.map(({ id, name }) => ({
      text: name,
      value: id
    })),
  getUserEmailTemplateBasedById: (state) => (id) =>
    state.userEmailTemplates.find(
      (userEmailTemplate) => id === userEmailTemplate.id
    ) || {}
};
