import moment from "moment";
import "moment/locale/es";
import { cloneDeep, isEmpty } from "lodash";
import {
  thirdPartyActionType,
  localStorageItem,
  attributeType,
  eb360DateFormat,
  orbiaSpecificConstants,
} from "@/constants";
import { mapState, mapGetters, mapActions } from "vuex";
import { makeDateToMatchFormat } from "@/utils";
import { makeMultiSelectCountriesCustomAttributeDTO } from "@/organisms/CustomAttribute/CustomAttributes.dto";

export default {
  computed: {
    ...mapState({
      tCertificationOrderTypes: (state) =>
        state.thirdParty.tCertificationOrderTypes,
      thirdPartyDetails: (state) => state.thirdParty.thirdPartyDetails,
      thirdPartyDetailsMessage: (state) =>
        state.thirdParty.thirdPartyDetailsMessage,
      isLoadingThirdPartyDetails: (state) =>
        state.thirdParty.isLoadingThirdPartyDetails,
      ESGMetricsOrderTypes: (state) => state.thirdParty.ESGMetricsOrderTypes,
      formsLanguagesList: (state) => state.thirdParty.formsLanguagesList,
      companyId: (state) => state.company.companyId,
      userId: (state) => state.user.userId,
      platformSupportEmail: (state) =>
        state.platformSettings.platformSupportEmail,
      countries: (state) => state.country.countries,
      OCStatesData: (state) => state.thirdParty.OCStatesList,
      headerConfigData: (state) => state.thirdParty.headerConfigData,
      platformUserList: (state) => state.platformSettings.platformUserList,
      customAttributesList: (state) =>
        state.customAttributes.customAttributesList,
      companyDetails: (state) => state.company.companyDetails,
      companyName: (state) => state.company.companyName,
      listOfEDD: (state) => state.thirdParty.EDDClientAllPackages,
      platformRequestSupportLink: (state) =>
        state.platformSettings.platformRequestSupportLink,
      isAddThirdPartyModalNewEditable: (state) =>
        state.thirdParty.isAddThirdPartyModalNewEditable
    }),
    ...mapGetters({
      isContactNameAndEmailRequired: "company/isContactNameAndEmailRequired",
      isThirdPartyArchived: "thirdParty/isThirdPartyArchived"
    }),
    getPlaceholderText() {
      return this.isContactNameAndEmailRequired ? "---Required---" : "";
    },
    isLoadingPage() {
      return this.isLoadingResultReview || this.isLoadingThirdPartyDetails;
    },
    errorPageOptions() {
      return {
        headingOptions: {
          text: "Incomplete data"
        },
        contentOptions: {
          text: this.getErrorMessages
        },
        iconOptions: {
          icon: "exclamation"
        }
      };
    },
    isErrorPresent() {
      return !!this.thirdPartyDetailsMessage;
    },
    getErrorMessages() {
      return [this.thirdPartyDetailsMessage];
    }
  },
  methods: {
    isEmpty,
    ...mapActions({
      fetchAllThirdPartyData: "thirdParty/fetchAllThirdPartyData",
      fetchThirdPartyDetails: "thirdParty/fetchThirdPartyDetails",
      fetchFormsLanguagesList: "thirdParty/fetchFormsLanguagesList"
    }),
    async initializeData(id) {
      const httpCallList = [];
      if (id) {
        await this.fetchThirdPartyDetailsWrapper(id);
      }
      httpCallList.push(
        this.fetchFormsLanguagesList({ companyId: this.companyId })
      );
      httpCallList.push(this.onInitialLoad());

      await Promise.allSettled(httpCallList);
    },
    async fetchThirdPartyDetailsWrapper(id) {
      await this.fetchThirdPartyDetails(id);
      this.isPageAvailable = !this.isEmpty(this.thirdPartyDetails);
    },
    getPopoverType(action) {
      if (action.actionType === thirdPartyActionType.EDD) {
        return "popover-target-edd";
      } else if (action.actionType === thirdPartyActionType.T_CERTIFICATION) {
        return "popover-target-tcert";
      } else if (action.actionType === thirdPartyActionType.ESG_METRIC) {
        return "popover-target-esgm";
      } else {
        return "";
      }
    },
    getNumberWord(number) {
      if (number && number !== 0) {
        return this.numToWords(number);
      }
    },
    numToWords(n) {
      const arr = (x) => Array.from(x);
      const num = (x) => Number(x) || 0;
      const isEmpty = (xs) => xs.length === 0;
      const take = (n) => (xs) => xs.slice(0, n);
      const drop = (n) => (xs) => xs.slice(n);
      const reverse = (xs) => xs.slice(0).reverse();
      const comp = (f) => (g) => (x) => f(g(x));
      const not = (x) => !x;
      const chunk = (n) => (xs) =>
        isEmpty(xs) ? [] : [take(n)(xs), ...chunk(n)(drop(n)(xs))];
      const a = [
        "",
        "one",
        "two",
        "three",
        "four",
        "five",
        "six",
        "seven",
        "eight",
        "nine",
        "ten",
        "eleven",
        "twelve",
        "thirteen",
        "fourteen",
        "fifteen",
        "sixteen",
        "seventeen",
        "eighteen",
        "nineteen"
      ];

      const b = [
        "",
        "",
        "twenty",
        "thirty",
        "forty",
        "fifty",
        "sixty",
        "seventy",
        "eighty",
        "ninety"
      ];

      const g = [
        "",
        "thousand",
        "million",
        "billion",
        "trillion",
        "quadrillion",
        "quintillion",
        "sextillion",
        "septillion",
        "octillion",
        "nonillion"
      ];

      // this part is really nasty still
      // it might edit this again later to show how Monoids could fix this up
      const makeGroup = ([ones, tens, huns]) =>
        [
          num(huns) === 0 ? "" : `${a[huns]} hundred `,
          num(ones) === 0 ? b[tens] : (b[tens] && `${b[tens]}-`) || "",
          a[tens + ones] || a[ones]
        ].join("");

      const thousand = (group, i) =>
        group === "" ? group : `${group} ${g[i]}`;

      if (typeof n === "number") return this.numToWords(String(n));
      else if (n === "0") return "zero";
      else
        return comp(chunk(3))(reverse)(arr(n))
          .map(makeGroup)
          .map(thousand)
          .filter(comp(not)(isEmpty))
          .reverse()
          .join(" ");
    },
    datediff(date) {
      const date1 = new Date(date);
      const date2 = new Date();
      const timeDifference = date2.getTime() - date1.getTime();
      const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

      return this.getYear(Math.round(daysDifference), date1);
    },
    getYear(number_of_days, createdDate) {
      const year = parseInt(number_of_days / 365);
      const days = number_of_days % 365;
      let fullDate;

      if (days == 0) {
        fullDate = moment.utc(createdDate).local().locale("en").fromNow();
      } else if (year > 0) {
        fullDate =
          year +
          (year == 1 ? " year " : " years ") +
          days +
          (days <= 1 ? " day" : " days");
      } else {
        fullDate = days + (days <= 1 ? " day" : " days");
      }
      return fullDate;
    },
    makeTCertificationOptions() {
      this.tCertificationOptions = [
        {
          text: "---Tcertification Order Types---",
          value: null,
          selected: true,
          hidden: true
        },
        ...cloneDeep(this.tCertificationOrderTypes)
      ];
    },
    makeESGMetricsOptions() {
      this.ESGMetricsOptions = [
        {
          text: "---ESGmetrics Order Types---",
          value: null,
          selected: true,
          hidden: true
        },
        ...cloneDeep(this.ESGMetricsOrderTypes)
      ];
    },
    async onInitialLoad() {
      this.isLoadingResultReview = true;
      try {
        await this.fetchAllThirdPartyData();
        this.isPremiumCorporateDataEnabled = true;
        this.companyDetail = JSON.parse(
          localStorage.getItem(localStorageItem.COMPANY_DETAILS)
        );
        const configuredActionData = localStorage.getItem(
          localStorageItem.ACTIONS_SCHEMA
        );

        this.setOrbiaSelectionAttributeIdentifiers();
        this.setOrbiaAffectedAttributeIds();
        this.setDefaultCustomAttributes();
        this.makeTCertificationOptions();
        this.makeESGMetricsOptions();
        this.setCustomAttributesValues();
        if (
          !this.isEmpty(this.thirdPartyDetails) &&
          this.isAddThirdPartyModalNewEditable
        ) {
          this.handleEditModeDataDisplay(
            this.thirdPartyDetails,
            JSON.parse(configuredActionData)
          );
        } else {
          this.modalShow(JSON.parse(configuredActionData));
        }
      } catch (error) {
        this.isLoadingResultReview = false;
      } finally {
        this.isLoadingResultReview = false;
      }
    },
    setOrbiaSelectionAttributeIdentifiers() {
      const selectionAttributeIndex = this.customAttributesList?.findIndex(
        (attribute) =>
          attribute?.description?.includes(
            orbiaSpecificConstants.MANDATORY_SELECTION_DESCRIPTION
          )
      );

      this.orbiaSelectionAttributeIndex = selectionAttributeIndex;
      this.orbiaSelectionAttributeId =
        this.customAttributesList?.[selectionAttributeIndex]?.id;
    },
    setOrbiaAffectedAttributeIds() {
      const filteredAttributeList = this.customAttributesList?.filter(
        (attribute) =>
          attribute?.description?.includes(
            orbiaSpecificConstants.MANDATORY_ATTRIBUTE_DESCRIPTION
          )
      );

      this.orbiaAffectedAttributeIds =
        filteredAttributeList?.map((attribute) => attribute?.id) || [];
    },
    makeCustomAttributesValueObj({
      tp_type,
      tp_customatt_values,
      meta_data,
      file,
      hash_id,
      tp_customatt_id,
      tp_is_required
    } = {}) {
      const value =
        tp_type === attributeType.DATE
          ? makeDateToMatchFormat({
              stringifiedDate: tp_customatt_values,
              dateFormat: eb360DateFormat.DEFAULT_DATE_FORMAT_HYPHEN
            })
          : tp_customatt_values;
      return {
        value,
        type: tp_type,
        meta_data,
        file,
        hash_id,
        id: tp_customatt_id,
        error: !!(parseInt(tp_is_required) && tp_customatt_values === "")
      };
    },
    setDefaultCustomAttributes(showOrbiaAttribute = false) {
      this.dynamicCustomAttributesList =
        this.customAttributesList?.map((attribute) => ({
          ...attribute,
          is_orbia_attribute: this.orbiaAffectedAttributeIds.includes(
            attribute?.id
          ),
          show_orbia_attribute: showOrbiaAttribute,
          description: attribute?.description?.replaceAll(
            /\[mandatory (selection|attribute)\]/g,
            ""
          ),
          tp_customatt_values:
            attribute?.tp_type === attributeType.MULTISELECT_COUNTRIES
              ? makeMultiSelectCountriesCustomAttributeDTO(this.countries)
              : attribute?.tp_customatt_values
        })) || [];
    },
    setCustomAttributesValues() {
      this.dynamicCustomAttributesList.forEach(
        ({ id, tp_is_required, tp_is_search, tp_type }) => {
          this.customAttributesValues[id] = {
            id,
            value: "",
            error: !!(parseInt(tp_is_required) && parseInt(tp_is_search)),
            type: tp_type
          };
          if (tp_type === attributeType.MULTISELECT_COUNTRIES) {
            const objToUpdate = this.dynamicCustomAttributesList.find(
              (obj) => obj.id === id
            );
            if (objToUpdate) {
              objToUpdate.tp_customatt_values =
                makeMultiSelectCountriesCustomAttributeDTO(this.countries);
            }
          }
        }
      );
    }
  }
};
