<template>
  <div class="user-training">
    <BaseText
      v-if="userTrainingList"
      class="user-training__heading"
      text="Training"
      tag="h2"
      :size="typographySize.HEADING_TWO"
    />
    <ThirdPartyTrainingList
      :training-list="userTrainingList"
      :error-message="errorMessage"
      :isLoading="isLoadingUserTrainingList"
      :feedback-message="feedbackMessage"
      @click="handleChangeView"
      isSupplierUser
    />
  </div>
</template>

<script>
import ThirdPartyTrainingList from "@/organisms/ThirdPartyTrainingList/ThirdPartyTrainingList";
import { mapState, mapActions } from "vuex";
import BaseText from "@/atoms/BaseText/BaseText";
import { sizes, typographySize, urls } from "@/constants";

export default {
  name: "UserTraining",
  components: { BaseText, ThirdPartyTrainingList },
  data() {
    return {
      errorMessage: "",
      feedbackMessage: "",
      sizes,
      typographySize
    };
  },
  created() {
    if (!this.userTrainingList?.length) {
      this.fetchUserTrainingListWrapper();
    }
  },
  computed: {
    ...mapState({
      userTrainingList: (state) => state.userTraining.userTrainingList,
      isLoadingUserTrainingList: (state) =>
        state.userTraining.isLoadingUserTrainingList
    })
  },
  methods: {
    ...mapActions({
      fetchUserTrainingList: "userTraining/fetchUserTrainingList"
    }),
    async fetchUserTrainingListWrapper() {
      try {
        this.errorMessage = "";
        this.feedbackMessage = (await this.fetchUserTrainingList()) || "";
      } catch ({ message = "" }) {
        this.errorMessage = message;
      }
    },
    handleChangeView({ selectedCourse }) {
      this.$router.push(urls.TRAINING_DETAILS(selectedCourse?.id));
    }
  }
};
</script>
<style lang="scss" scoped>
.user-training {
  padding: $spacing24;
  &__heading {
    text-align: left;
  }
}
</style>
