import { userEmailTemplatesService } from "@/services";

export const actions = {
  async fetchUserEmailTemplates({ commit, rootState }) {
    try {
      commit(
        "userEmailTemplates/setIsLoadingUserEmailTemplates",
        true,
        {
          root: true
        }
      );
      const { data } = await userEmailTemplatesService.fetchUserEmailTemplates(
        rootState.company.companyId
      );
      commit("userEmailTemplates/setUserEmailTemplates", data, {
        root: true
      });
    } finally {
      commit(
        "userEmailTemplates/setIsLoadingUserEmailTemplates",
        false,
        {
          root: true
        }
      );
    }
  },
  async fetchUserEmailTemplate({ commit, rootState }, templateId) {
    const { data } = await userEmailTemplatesService.fetchUserEmailTemplate({
      companyId: rootState.company.companyId,
      templateId
    });
    commit("userEmailTemplates/setUserEmailTemplate", data, {
      root: true
    });
  },
  async makeUserEmailTemplate({ dispatch, rootState }, userEmailTemplate) {
    await userEmailTemplatesService.makeUserEmailTemplate({
      companyId: rootState.company.companyId,
      userEmailTemplate: {
        ...userEmailTemplate,
        companyId: rootState.company.companyId
      }
    });
    await dispatch("userEmailTemplates/fetchUserEmailTemplates", null, {
      root: true
    });
  },
  async updateUserEmailTemplate({ dispatch, rootState }, userEmailTemplate) {
    await userEmailTemplatesService.updateUserEmailTemplate({
      companyId: rootState.company.companyId,
      userEmailTemplate
    });
    await dispatch("userEmailTemplates/fetchUserEmailTemplates", null, {
      root: true
    });
  },
  async cloneUserEmailTemplate({ dispatch, rootState }, templateId) {
    await userEmailTemplatesService.cloneUserEmailTemplate({
      companyId: rootState.company.companyId,
      templateId
    });
    await dispatch("userEmailTemplates/fetchUserEmailTemplates", null, {
      root: true
    });
  },
  async deleteUserEmailTemplate({ dispatch, rootState }, templateId) {
    await userEmailTemplatesService.deleteUserEmailTemplate({
      companyId: rootState.company.companyId,
      templateId
    });
    await dispatch("userEmailTemplates/fetchUserEmailTemplates", null, {
      root: true
    });
  }
};
