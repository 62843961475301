<template>
  <FormsContainer v-if="isUserSuperAdmin" />
</template>

<script>
import FormsContainer from "@/containers/FormsContainer";
import { mapGetters } from "vuex";

export default {
  name: "TemplatesPage",
  components: {
    FormsContainer
  },
  computed: {
    ...mapGetters({
      isUserSuperAdmin: "user/isUserSuperAdmin"
    })
  }
};
</script>
