<template>
  <section ref="automation" class="automation">
    <BaseLoader v-if="isLoadingTableData" />
    <template>
      <Modal
        class="automation__modal"
        data-test-id="automation__delete-modal"
        v-if="isModalVisible"
        title="Delete Automation"
        @close-modal="closeModal"
      >
        <div
          class="automation__modal-text"
          data-test-id="automation__modal-text"
        >
          <BaseText :text="makeModalText" />
        </div>
        <template #left>
          <CallToAction
            type="button"
            theme="error-inverse"
            value="Cancel"
            data-test-id="automation__modal-cta--cancel"
            @click="onCancelDeleteAutomation"
          />
        </template>
        <template #right>
          <CallToAction
            type="button"
            value="Delete"
            data-test-id="automation__modal-cta--delete"
            @click="deleteAutomationWrapper"
          />
        </template>
      </Modal>
      <div class="automation__operations">
        <CallToAction
          theme="strong"
          value="Add"
          data-test-id="automation__operations-cta--add"
          @click="onCTAAdd"
        />
      </div>
      <BaseText
        :text="operationFeedback"
        theme="success-inverse"
        data-test-id="automation__operations-feedback"
      />
      <InputErrorMessage
        :error="errorMessagesApi"
        data-test-id="automation__operations-error-message-api"
      />
      <StickyTable
        v-if="isDataAvailable"
        caption="Automations"
        class="automation__table"
        data-test-id="automation__table"
        :table-headers="automationHeaders"
        :table-rows="automationRows"
        @table-row-click="onTableRowClick"
      />
      <BaseText
        v-else
        text="You do not have any automations. Click the Add button to create one."
        data-test-id="automation__table-fallback-message"
      />
    </template>
  </section>
</template>

<script>
import StickyTable from "@/molecules/StickyTable/StickyTable";
import DropDownList from "@/molecules/DropDownList/DropDownList";
import Modal from "@/molecules/Modal/Modal";
import InputErrorMessage from "@/molecules/InputErrorMessage/InputErrorMessage";
import {
  automationsListTableHeaders,
  typographySize,
  automationsKeys,
  operations,
  externalUrls,
  urls,
  activityState,
  automationEventType
} from "@/constants";
import BaseText from "@/atoms/BaseText/BaseText";
import CallToAction from "@/atoms/CallToAction/CallToAction";
import BaseLoader from "@/atoms/BaseLoader/BaseLoader";
import { hasStatus500, hasStatus404, isNotAuthorized } from "@/utils";
import { automationsService } from "@/services";
import { mapMutations, mapState } from "vuex";

export default {
  name: "Automations",
  components: {
    StickyTable,
    BaseText,
    CallToAction,
    InputErrorMessage,
    Modal,
    BaseLoader
  },
  props: {
    automations: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      externalUrls,
      operationFeedback: "",
      errorMessagesApi: "",
      isLoadingTableData: false,
      isModalVisible: false,
      selectedAutomation: {
        id: "",
        name: ""
      }
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.company.companyId
    }),
    isDataAvailable() {
      return !!this.automationHeaders.length && !!this.automationRows.length;
    },
    makeAutomationsForTable() {
      return this.automations.map((automation) => ({
        [automationsKeys.ID]: automation[automationsKeys.ID],
        [automationsKeys.NAME]: automation[automationsKeys.NAME],
        [automationsKeys.DESCRIPTION]: automation[automationsKeys.DESCRIPTION],
        [automationsKeys.CREATED_BY]:
          automation[automationsKeys.CREATED_BY][automationsKeys.NAME],
        [automationsKeys.IS_ACTIVE]: automation[automationsKeys.IS_ACTIVE]
          ? activityState.ACTIVE
          : activityState.INACTIVE,
        [automationsKeys.ACTIONS]: automation[automationsKeys.ACTIONS]
      }));
    },
    automationHeaders() {
      return this.makeAutomationsForTable.length
        ? Object.keys(this.makeAutomationsForTable[0])?.reduce((acc, key) => {
            if (automationsListTableHeaders[key]) {
              acc.push({
                value: key,
                component: BaseText,
                componentOptions: {
                  tag: "span",
                  text: automationsListTableHeaders[key],
                  size: typographySize.BODY_TEXT_BOLD,
                  styles: {
                    margin: "0"
                  }
                },
                styles: {
                  verticalAlign: "top",
                  background: "white",
                  padding: "5px"
                }
              });
            }
            return acc;
          }, [])
        : [];
    },
    automationRows() {
      return (
        this.makeAutomationsForTable.map((automation) =>
          Object.keys(automation).reduce((acc, automationsKey) => {
            if (automationsKey === automationsKeys.ACTIONS) {
              acc[automationsKey] = {
                component: DropDownList,
                componentOptions: {
                  dropDownList: [
                    {
                      value: JSON.stringify({
                        operation: operations.EDIT,
                        id: automation.id
                      }),
                      text: "Edit"
                    },
                    {
                      value: JSON.stringify({
                        operation: operations.DELETE,
                        id: automation.id,
                        name: automation.name
                      }),
                      text: "Delete"
                    }
                  ],
                  style: {}
                }
              };
            } else {
              acc[automationsKey] = {
                component: BaseText,
                componentOptions: {
                  tag: "span",
                  text: automation[automationsKey],
                  styles: {
                    padding: "0px 5px"
                  }
                }
              };
            }
            return acc;
          }, {})
        ) || []
      );
    },
    makeModalText() {
      return `Are you sure you want to delete the automation, ${this.selectedAutomation.name}?`;
    }
  },
  methods: {
    ...mapMutations({
      setTriggerFetchAdditionalVouchers:
        "company/setTriggerFetchAdditionalVouchers"
    }),
    setSelectedAutomation(id = "", name = "") {
      this.selectedAutomation.id = id;
      this.selectedAutomation.name = name;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    onCancelDeleteAutomation() {
      this.resetSelectedAutomation();
      this.closeModal();
    },
    resetSelectedAutomation() {
      this.setSelectedAutomation("", "");
    },
    onCTAAdd() {
      this.$emit(automationEventType.EDIT_AUTOMATION, undefined);
      this.$router.push(urls.ADD_AUTOMATION);
    },
    onTableRowClick(tableRowObj) {
      const { operation, id, name } = JSON.parse(tableRowObj);
      if (operation === operations.EDIT) {
        this.$emit(automationEventType.EDIT_AUTOMATION, id);
        this.$router.push(urls.EDIT_AUTOMATION_ID(id));
      } else if (operation === operations.DELETE) {
        this.isModalVisible = true;
        this.setSelectedAutomation(id, name);
      }
    },
    async deleteAutomationWrapper() {
      try {
        this.closeModal();
        this.isLoadingTableData = true;
        this.operationFeedback = "";
        this.errorMessagesApi = "";
        await automationsService.deleteAutomation(
          this.companyId,
          this.selectedAutomation.id
        );
        this.$refs.automation.scrollTo({ top: 0 });
        this.setTriggerFetchAdditionalVouchers(true);
        this.$emit(automationEventType.FETCH_AUTOMATIONS);
        this.operationFeedback = "Automation deleted successfully";
      } catch (error) {
        this.deleteAutomationFailure(error);
      } finally {
        this.resetSelectedAutomation();
        this.isLoadingTableData = false;
      }
    },
    deleteAutomationFailure({ response } = {}) {
      if (hasStatus500(response)) {
        this.errorMessagesApi = "There is a server error.";
      } else if (hasStatus404(response)) {
        this.errorMessagesApi = "Failed to find automation and delete it.";
      } else if (!isNotAuthorized(response)) {
        this.errorMessagesApi = `There is an unknown error with status of ${response?.status}. Please contact support`;
      }
    }
  },
  mounted() {
    this.operationFeedback = history.state?.message || "";
  }
};
</script>

<style lang="scss" scoped>
.automation {
  height: calc(100vh - 130px);
  overflow-y: scroll;
  padding: 0 20px 0 0;
  text-align: left;

  &__modal-text {
    padding: 15px 15px 0;
  }

  &__operations {
    text-align: right;
    padding-bottom: 20px;
  }
}
</style>
