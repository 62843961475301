<template id="template-input">
  <div :class="isPreview ? 'pb-2 mb-2' : 'bg-light p-3 pb-5 mb-2'">
    <!-- menu buttons -->
    <MenuButtons
      v-if="!isPreview"
      :fieldData="fieldData"
      :collapse="collapse"
      :isHidden="isHidden"
      :label="label"
      :sectionIndex="sectionIndex"
      :fieldIndex="fieldIndex"
      v-on:toggle-field="showSettings = !showSettings"
      v-on:collapse-field="collapse = !collapse"
    />
    <!-- input content -->
    <b-collapse
      v-show="JSON.parse(!fieldData.isHidden) || !isPreview"
      id="collapse-1"
      :class="isPreview ? 'mt-0' : 'mt-5'"
      v-model="collapse"
    >
      <b-table-simple bordered v-if="fieldData.selectedLayout == 'l1'">
        <b-thead v-if="fieldData.tableHeaders && fieldData.tableHeaders.length">
          <b-tr>
            <b-th v-if="fieldData.enableNumbers"></b-th>
            <b-th
              v-for="(theader, index) in fieldData.tableHeaders"
              :key="index"
              >{{ theader.heading[selectedLanguage] }}</b-th
            >
          </b-tr>
        </b-thead>
        <b-tbody
          v-if="fieldData.tableRowsItems && fieldData.tableRowsItems.length"
        >
          <b-tr
            v-for="(itemRow, itemIndex) in fieldData.tableRowsItems"
            :key="itemIndex"
          >
            <b-td v-if="fieldData.enableNumbers">{{ itemIndex + 1 }}</b-td>
            <b-th>{{ itemRow.text[selectedLanguage] }}</b-th>
            <b-td v-for="(item, index) in itemRow.cols" :key="index">
              <div v-if="item && item.type == 'label'">
                <p>{{ item.text[selectedLanguage] }}</p>
              </div>
              <div v-if="item && item.type == 'text'">
                <b-form-textarea v-model="item.value"></b-form-textarea>
              </div>

              <div v-if="item && item.type == 'date'">
                <DatePicker
                  class="custom-date-picker"
                  :datePickerOptions="{
                    value: item.value,
                    enableTimePicker: false,
                    'onUpdate:modelValue': (value) => {
                      item.value = value;
                    }
                  }"
                />
              </div>
              <div>
                <v-select
                  v-if="item && item.type == 'select'"
                  label="name"
                  :options="renderCountries"
                  v-model="selectedCountry"
                ></v-select>
              </div>

              <b-form-group v-if="item && item.type == 'radio'">
                <b-form-radio>{{ item.text[selectedLanguage] }}</b-form-radio>
              </b-form-group>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <!-- show advance field options -->
      <b-modal
        cancel-title="Close"
        ok-title="save"
        size="xl"
        v-model="showSettings"
        scrollable
        title="Advanced Settings"
        @ok="onSaveData"
      >
        <AdvanceSettings
          :fieldData="fieldData"
          v-on:toggle-field="showSettings = !showSettings"
          :sectionIndex="sectionIndex"
          :fieldIndex="fieldIndex"
        />
      </b-modal>
    </b-collapse>
  </div>
</template>

<script>
import { required } from "@vuelidate/validators";
import AdvanceSettings from "./advanceTableSettings.vue";
import MenuButtons from "@/components/menuButtons";
import { mapGetters, mapState } from "vuex";
import { EventBus } from "@/event-bus.js";
import { Helper } from "@/helpers";
import DatePicker from "@/molecules/DatePicker/DatePicker";
import CountryCodes from "@/utils/countryCodes.json";
import vSelect from "vue-select";

export default {
  name: "AdvanceTableComponent",
  components: {
    AdvanceSettings,
    MenuButtons,
    DatePicker,
    vSelect
  },
  data() {
    return {
      isHidden: false,
      showSettings: false,
      collapse: true,
      label: null,
      isBusy: false,
      taskStatus: 0,
      items: [],
      postData: [],
      renderCountries: CountryCodes.countries,
      selectedCountry: null
    };
  },

  created() {},

  mounted() {
    let st = parseInt(localStorage.getItem("activeTaskStatus"));
    this.taskStatus = st;
  },

  validations: {
    name: {
      required
    }
  },

  computed: {
    ...mapGetters([
      "form1",
      "selectedLanguage",
      "userForm",
      "userFormSubmitArray",
      "rightAlign"
    ]),
    ...mapState(["toggleAllCoppalse", "rightAlign"])
  },

  watch: {
    toggleAllCoppalse(value) {
      this.collapse = value;
    }
  },

  methods: {
    toggleBusy() {
      this.isBusy = !this.isBusy;
    },
    updateAdvancedSettings() {
      let advance = {
        fullNameDisabled: this.fullNameDisabled,
        dateAppointedDisabled: this.dateAppointedDisabled,
        officerRoleDisabled: this.officerRoleDisabled
      };
      Object.assign(this.fieldData, { advance });
    },
    onSaveData() {
      EventBus.emit("onSaveSettings", "saving settings");
      Helper.makeToast(this.$bvToast, "success", "Settings updated");
    }
  },
  props: {
    fieldData: Object,
    isPreview: Boolean,
    sectionIndex: Number,
    fieldIndex: Number,
    isDisabled: Number
  }
};
</script>
<style lang="scss">
.required-span {
  font-size: 24px;
  margin-left: 3px;
  color: $spanish-red;
}
.custom-date-picker {
  width: 100%;
  border: none;
}
.custom-date-picker:focus {
  border: none;
}
.date-calender {
  width: 500px !important;
  background: $platinum !important;
}
/* .form-control.is-valid{
	background-position: left calc(0.375em + 0.1875rem) center

}
.form-control.is-invalid{
	background-position :left calc(0.375em + 0.1875rem) center

} */
</style>
