const languagesInitial = {
  CHINESE_SIMPLIFIED: "zh-cn",
  JAPANESE: "ja",
  SPANISH: "es",
  ENGLISH: "en",
  FRENCH: "fr",
  RUSSIAN: "ru",
  ARABIC: "ar",
  INDONESIAN: "in",
  PORTUGUESE: "po",
  SWEDISH: "sw",
  HEBREW: "he",
  VIETNAMESE: "vi",
  THAI: "th",
  TURKISH: "tu",
  POLISH: "pl",
  GERMAN: "de",
  ITALIAN: "it",
  KOREAN: "ko",
  CHINESE_TRADITIONAL_TAIWAN: "zh-tw",
  CHINESE_TRADITIONAL: "zh",
  BULGARIAN: "bg",
  BURMESE: "my",
  CROATIAN: "hr",
  CZECH: "cs",
  DUTCH: "nl",
  GREEK: "el",
  HAITIAN_CREOLE: "ht",
  HUNGARIAN: "hu",
  KHMER: "kh",
  LAO: "la",
  ROMANIAN: "ro",
  SERBIAN: "rs",
  SINHALESE: "si",
  SLOVAK: "sk",
  SLOVENIAN: "sl",
  TAMIL: "ta",
  PORTUGUESE_BRAZILIAN: "pt-br",
  PORTUGUESE_MOZAMBIQUE: "pt-mo",
  NORWEGIAN: "no",
  BAHASA_INDONESIAN: "id",
  TAPALOG: "ph",
  HINDI: "hi",
  SPANISH_MEXICAN: "mx"
};

const languages = {
  [languagesInitial.CHINESE_SIMPLIFIED]: "chinese simplified",
  [languagesInitial.JAPANESE]: "japanese",
  [languagesInitial.SPANISH]: "spanish",
  [languagesInitial.ENGLISH]: "english",
  [languagesInitial.FRENCH]: "french",
  [languagesInitial.RUSSIAN]: "russian",
  [languagesInitial.ARABIC]: "arabic",
  [languagesInitial.INDONESIAN]: "indonesian",
  [languagesInitial.PORTUGUESE]: "portuguese",
  [languagesInitial.SWEDISH]: "swedish",
  [languagesInitial.HEBREW]: "hebrew",
  [languagesInitial.VIETNAMESE]: "vietnamese",
  [languagesInitial.THAI]: "thai",
  [languagesInitial.TURKISH]: "turkish",
  [languagesInitial.POLISH]: "polish",
  [languagesInitial.GERMAN]: "german",
  [languagesInitial.ITALIAN]: "italian",
  [languagesInitial.KOREAN]: "korean",
  [languagesInitial.CHINESE_TRADITIONAL_TAIWAN]: "chinese traditional taiwan",
  [languagesInitial.CHINESE_TRADITIONAL]: "chinese traditional",
  [languagesInitial.BULGARIAN]: "bulgarian",
  [languagesInitial.BURMESE]: "burmese",
  [languagesInitial.CROATIAN]: "crotian",
  [languagesInitial.CZECH]: "czech",
  [languagesInitial.GREEK]: "greek",
  [languagesInitial.DUTCH]: "dutch",
  [languagesInitial.HAITIAN_CREOLE]: "haitian creole",
  [languagesInitial.HUNGARIAN]: "hungarian",
  [languagesInitial.KHMER]: "khmer",
  [languagesInitial.LAO]: "laos",
  [languagesInitial.ROMANIAN]: "romanian",
  [languagesInitial.SERBIAN]: "serbian",
  [languagesInitial.SINHALESE]: "sinhalese",
  [languagesInitial.SLOVAK]: "slovak",
  [languagesInitial.SLOVENIAN]: "slovenian",
  [languagesInitial.TAMIL]: "tamil",
  [languagesInitial.PORTUGUESE_BRAZILIAN]: "portuguese brazilian",
  [languagesInitial.NORWEGIAN]: "norwegian",
  [languagesInitial.BAHASA_INDONESIAN]: "bahasa indonesian",
  [languagesInitial.TAPALOG]: "tagalog",
  [languagesInitial.HINDI]: "hindi",
  [languagesInitial.PORTUGUESE_MOZAMBIQUE]: "portuguese mozambique",
  [languagesInitial.SPANISH_MEXICAN]: "spanish mexican"
};

const languageLocaleKeys = {
  [languagesInitial.CHINESE_SIMPLIFIED]: "chinese",
  [languagesInitial.CHINESE_TRADITIONAL_TAIWAN]: "chinesetr",
  [languagesInitial.PORTUGUESE_BRAZILIAN]: "portuguesebr",
  [languagesInitial.BAHASA_INDONESIAN]: "bahasaIndonesia",
  [languagesInitial.PORTUGUESE_MOZAMBIQUE]: "portuguesemz",
  [languagesInitial.SPANISH_MEXICAN]: "spanishmx",
  [languagesInitial.CHINESE_TRADITIONAL]: "chinesetr"
};

export { languagesInitial, languages, languageLocaleKeys };
