<template>
  <div class="risk-alert-header">
    <section class="risk-alert-header__heading">
      <section class="risk-alert-header__heading-title">
        <BaseText
          tag="span"
          :size="typographySize.BODY_TEXT_BOLD"
          :theme="themes.ETHIXBASE_PRIMARY_INVERSE"
          text="Risk alerts by entity"
        />
        <BaseBadge
          class="risk-alert-header__heading-badge"
          :text="`${thirdPartyRiskAlerts.totalAlerts} RISK ALERTS FOUND`"
          :theme="themes.GRADIENT_ORANGE_INVERSE"
        />
      </section>
      <BaseText
        tag="span"
        :size="typographySize.BODY_TEXT_SMALL"
        text="This table summarizes risk alerts by entity and category, showing the
        alert status."
      />
    </section>
    <ThirdPartyRiskAlertsLegend />
  </div>
</template>

<script>
import BaseText from "@/atoms/BaseText/BaseText";
import BaseBadge from "@/atoms/BaseBadge/BaseBadge";
import ThirdPartyRiskAlertsLegend from "@/organisms/ThirdPartyRiskAlertsLegend/ThirdPartyRiskAlertsLegend";
import { themes, typographySize } from "@/constants";

export default {
  name: "ThirdPartyRiskAlertsHeader",
  components: {
    BaseText,
    BaseBadge,
    ThirdPartyRiskAlertsLegend
  },
  props: {
    thirdPartyRiskAlerts: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      themes,
      typographySize
    };
  }
};
</script>

<style lang="scss" scoped>
.risk-alert-header {
  display: flex;
  gap: 0 $spacing16;
  justify-content: space-between;
  align-items: flex-start;
  padding: $spacing16;

  &__heading {
    text-align: left;

    &-title {
      display: inline-flex;
      gap: $spacing12;
    }

    &-badge {
      min-width: 150px;
    }
  }
}
</style>
