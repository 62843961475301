<template>
  <div class="risk-overview">
    <b-overlay :show="isLoadingData" rounded="sm">
      <b-row>
        <b-col cols="6">
          <b-card class="risk-overview__section">
            <b-card-title class="risk-overview__section-title">
              Risk Ratings
            </b-card-title>
            <b-row class="mt-4">
              <b-col style="display: flex; align-items: center" cols="8">
                <p class="m-0">
                  {{ $t("eb360.manageThirdParties.riskRating") }}
                </p>
              </b-col>
              <b-col class="risk-overview__rating" cols="4">
                <b-dropdown
                  :disabled="isDisabled"
                  v-if="thirdPartyData.risk_rating"
                  size="sm"
                  variant="outline"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template #button-content>
                    <IconWithText
                      v-bind="
                        getRiskRatingIconWithText(thirdPartyData.risk_rating)
                      "
                    />
                  </template>
                  <b-dropdown-item-button
                    v-if="thirdPartyData.risk_rating"
                    @click="onViewThirdPartyModal('')"
                    >View</b-dropdown-item-button
                  >
                  <b-dropdown-item-button
                    v-else
                    @click="onViewThirdPartyModal('')"
                    >Perform this Action</b-dropdown-item-button
                  >
                </b-dropdown>
              </b-col>
            </b-row>
            <b-row
              v-for="{ name, value, id, description } in getCustomAttributeList"
              :key="id"
              class="risk-overview__rating-list"
            >
              <template v-if="hasCustomAttributeViewPermission(id)">
                <b-col cols="8">
                  <p class="m-0">
                    {{ name }}
                    <Tooltip v-if="description" :text="description" />
                  </p>
                </b-col>
                <b-col cols="4" class="risk-overview__rating">
                  <RatingCustomAttribute
                    class="risk-overview__custom-attribute-rating"
                    :value="value"
                    :text-align="textAlign.RIGHT"
                    :scale-type="
                      getCustomAttributeRatingScaleTypeId({
                        attributeName: name,
                        customAttributeList: customAttributes
                      })
                    "
                  />
                </b-col>
              </template>
            </b-row>
          </b-card>
          <b-card class="risk-overview__section">
            <b-card-title class="risk-overview__section-title">
              {{ $t("eb360.riskOverview.policyAttestation") }}
            </b-card-title>
            <b-row class="mb-2">
              <b-col style="display: flex; align-items: center" cols="8">
                <p class="m-0">{{ $t("eb360.riskOverview.codeOfConduct") }}</p>
              </b-col>
              <b-col class="risk-overview__rating" cols="4">
                <b-dropdown
                  :disabled="isDisabled"
                  size="sm"
                  variant="outline"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template #button-content>
                    <IconWithText
                      v-if="checkActionAvailable('COCA')"
                      v-bind="riskIcons.NOT_RUN"
                    />
                    <IconWithText v-else v-bind="riskIcons.NOT_AVAILABLE" />
                  </template>
                  <div>
                    <b-dropdown-item-button
                      v-if="checkActionAvailable('COCA') == true"
                      :disabled="!thirdPartyData"
                      @click="onViewThirdPartyModal"
                      >View</b-dropdown-item-button
                    >
                    <b-dropdown-item-button
                      v-if="checkActionAvailable('COCA') == true"
                      @click="onViewThirdPartyModal('COCA')"
                    >
                      Perform this Action
                    </b-dropdown-item-button>
                    <b-dropdown-item-button
                      v-if="checkActionAvailable('COCA') == false"
                      @click="
                        openEnablePlatformModal('Code of Conduct Attestation')
                      "
                    >
                      Request feature
                    </b-dropdown-item-button>
                  </div>
                </b-dropdown>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col style="display: flex; align-items: center" cols="8">
                <p class="m-0">{{ $t("eb360.riskOverview.antiCorruption") }}</p>
              </b-col>
              <b-col class="risk-overview__rating" cols="4">
                <b-dropdown
                  :disabled="isDisabled"
                  size="sm"
                  variant="outline"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template #button-content>
                    <IconWithText
                      v-if="checkActionAvailable('ACPA')"
                      v-bind="riskIcons.NOT_RUN"
                    />
                    <IconWithText v-else v-bind="riskIcons.NOT_AVAILABLE" />
                  </template>
                  <div>
                    <b-dropdown-item-button
                      v-if="checkActionAvailable('ACPA') == true"
                      :disabled="!thirdPartyData"
                      @click="onViewThirdPartyModal"
                    >
                      View
                    </b-dropdown-item-button>
                    <b-dropdown-item-button
                      v-if="checkActionAvailable('ACPA') == true"
                      @click="onViewThirdPartyModal('ACPA')"
                    >
                      Perform this Action
                    </b-dropdown-item-button>
                    <b-dropdown-item-button
                      v-if="checkActionAvailable('ACPA') == false"
                      @click="
                        openEnablePlatformModal(
                          'Anti-Corruption Policy Attestation'
                        )
                      "
                    >
                      Request feature
                    </b-dropdown-item-button>
                  </div>
                </b-dropdown>
              </b-col>
            </b-row>
            <b-row
              class="mb-2"
              v-for="(data, index) in filteredQuestionnaires"
              :key="index"
            >
              <b-col style="display: flex; align-items: center" cols="8">
                <p class="m-0">{{ data.title }}</p>
              </b-col>
              <b-col
                class="risk-overview__rating"
                v-if="riskData[data.id]"
                cols="4"
              >
                <span v-if="isRiskRatingAvailable(data)">
                  {{ riskData[data.id].rating }}
                </span>
                <b-dropdown
                  :disabled="isDisabled"
                  size="sm"
                  variant="outline"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template #button-content>
                    <IconWithText
                      v-if="!checkActionAvailable(data.gridBtntext)"
                      v-bind="riskIcons.NOT_AVAILABLE"
                    />
                    <div v-if="checkActionAvailable(data.gridBtntext) == true">
                      <p
                        v-if="
                          riskScoreColor[data.id] == 1 ||
                          riskScoreColor[data.id] == 2 ||
                          riskScoreColor[data.id] == 3
                        "
                      >
                        <IconWithText
                          v-bind="getMSQIcon(riskScoreColor[data.id])"
                        />
                      </p>
                      <p
                        v-else-if="
                          (riskData[data.id].rating == null ||
                            riskData[data.id].rating == 'Invalid') &&
                          riskDataId[data.id]
                        "
                      >
                        <b-badge class="text-white low-risk-score-style">
                          Completed
                        </b-badge>
                      </p>
                      <IconWithText v-else v-bind="riskIcons.NOT_RUN" />
                    </div>
                  </template>
                  <div>
                    <b-dropdown-item-button
                      v-if="
                        ((riskData[data.id] && riskScoreColor[data.id] == 1) ||
                          riskScoreColor[data.id] == 2 ||
                          riskScoreColor[data.id] == 3 ||
                          ((riskData[data.id].rating == null ||
                            riskData[data.id].rating == 'Invalid') &&
                            riskDataId[data.id])) &&
                        checkActionAvailable('MSQ')
                      "
                      @click="goToQuestionnaireTab()"
                    >
                      View
                    </b-dropdown-item-button>
                    <b-dropdown-item-button
                      v-if="checkActionAvailable(data.gridBtntext)"
                      @click="onViewThirdPartyModal(data.gridBtntext)"
                    >
                      Perform this Action
                    </b-dropdown-item-button>
                    <b-dropdown-item-button
                      v-if="checkActionAvailable(data.gridBtntext) == false"
                      @click="openEnablePlatformModal(data.btnText)"
                    >
                      Request feature
                    </b-dropdown-item-button>
                  </div>
                </b-dropdown>
              </b-col>
              <b-col v-else class="risk-overview__rating" cols="4">
                <b-dropdown
                  :disabled="isDisabled"
                  size="sm"
                  variant="outline"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template #button-content>
                    <IconWithText
                      v-if="checkActionAvailable(data.gridBtntext)"
                      v-bind="riskIcons.NOT_RUN"
                    />
                    <IconWithText v-else v-bind="riskIcons.NOT_AVAILABLE" />
                  </template>
                  <div>
                    <b-dropdown-item-button
                      v-if="checkActionAvailable(data.gridBtntext)"
                      @click="onViewThirdPartyModal(data.gridBtntext)"
                    >
                      Perform this Action
                    </b-dropdown-item-button>
                    <b-dropdown-item-button
                      v-if="checkActionAvailable(data.gridBtntext) == false"
                      @click="openEnablePlatformModal(data.btnText)"
                    >
                      Request feature
                    </b-dropdown-item-button>
                  </div>
                </b-dropdown>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col cols="6">
          <b-card class="risk-overview__section">
            <b-card-title class="risk-overview__section-title">
              {{ $t("eb360.riskOverview.ddResults") }}
            </b-card-title>
            <b-row class="mb-2">
              <b-col style="display: flex; align-items: center" cols="8">
                <p class="m-0">{{ $t("eb360.riskOverview.iddSe") }}</p>
              </b-col>
              <b-col class="risk-overview__rating" cols="4">
                <b-dropdown
                  :disabled="isDisabled"
                  size="sm"
                  variant="outline"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template #button-content>
                    <div v-if="thirdPartyData">
                      <div>
                        <div v-if="hasIDDRiskLevel">
                          <IconWithText
                            v-if="
                              thirdPartyData.risk_level == 1 ||
                              thirdPartyData.risk_level == 3 ||
                              thirdPartyData.risk_level == 5 ||
                              thirdPartyData.risk_level == 6
                            "
                            v-bind="getIconWithText(thirdPartyData.risk_level)"
                          />
                        </div>
                        <IconWithText
                          v-else-if="checkActionAvailable('IDD')"
                          v-bind="riskIcons.NOT_RUN"
                        />
                        <IconWithText v-else v-bind="riskIcons.NOT_AVAILABLE" />
                      </div>
                    </div>
                    <div v-else>
                      <IconWithText v-bind="riskIcons.NOT_AVAILABLE" />
                    </div>
                  </template>
                  <div>
                    <div>
                      <b-dropdown-item-button
                        v-if="
                          thirdPartyData.risk_level == 1 ||
                          thirdPartyData.risk_level == 3 ||
                          thirdPartyData.risk_level == 5 ||
                          thirdPartyData.risk_level == 6
                        "
                        :disabled="!thirdPartyData"
                        @click="goToDueDiligenceTab()"
                      >
                        View
                      </b-dropdown-item-button>
                      <div v-else>
                        <b-dropdown-item-button
                          v-if="checkActionAvailable('IDD')"
                          @click="onViewThirdPartyModal('IDD')"
                        >
                          Perform this Action
                        </b-dropdown-item-button>
                      </div>
                    </div>
                    <b-dropdown-item-button
                      v-if="checkActionAvailable('IDD') == false"
                      @click="
                        openEnablePlatformModal('Instant Due Diligence (IDD)')
                      "
                    >
                      Request feature
                    </b-dropdown-item-button>
                  </div>
                </b-dropdown>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col style="display: flex; align-items: center" cols="8">
                <p class="m-0">{{ $t("eb360.riskOverview.iddPlus") }}</p>
              </b-col>
              <b-col class="risk-overview__rating" cols="4">
                <b-dropdown
                  :disabled="isDisabled"
                  size="sm"
                  variant="outline"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template #button-content>
                    <div v-if="thirdPartyData">
                      <div>
                        <div v-if="thirdPartyData.is_idd == '1'">
                          <IconWithText
                            v-if="
                              thirdPartyData.idd_color == 1 ||
                              thirdPartyData.idd_color == 3 ||
                              thirdPartyData.idd_color == 5 ||
                              thirdPartyData.idd_color == 6
                            "
                            v-bind="getIconWithText(thirdPartyData.idd_color)"
                          />
                        </div>
                        <div
                          v-else-if="
                            thirdPartyData.is_edd_or &&
                            thirdPartyData.is_edd_or == 1 &&
                            thirdPartyData.is_edd &&
                            thirdPartyData.is_edd == 0
                          "
                        >
                          <font-awesome-icon
                            size="2x"
                            class="fa-2"
                            icon="hourglass-half"
                          />
                        </div>
                        <div v-else-if="checkActionAvailable('IDD+')">
                          <IconWithText v-bind="riskIcons.NOT_RUN" />
                        </div>
                        <div v-else>
                          <IconWithText v-bind="riskIcons.NOT_AVAILABLE" />
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <IconWithText v-bind="riskIcons.NOT_AVAILABLE" />
                    </div>
                  </template>
                  <div>
                    <div>
                      <b-dropdown-item-button
                        v-if="
                          (thirdPartyData.is_idd == 1 &&
                            thirdPartyData.idd_color == 1) ||
                          thirdPartyData.idd_color == 3 ||
                          thirdPartyData.idd_color == 5 ||
                          thirdPartyData.idd_color == 6 ||
                          (thirdPartyData.is_edd_or &&
                            thirdPartyData.is_edd_or == 1 &&
                            thirdPartyData.is_edd &&
                            thirdPartyData.is_edd == 0)
                        "
                        :disabled="!thirdPartyData"
                        @click="goToDueDiligenceTab()"
                      >
                        View
                      </b-dropdown-item-button>
                      <div v-else>
                        <b-dropdown-item-button
                          v-if="checkActionAvailable('IDD+') == true"
                          @click="onViewThirdPartyModal('IDD+')"
                        >
                          Perform this Action
                        </b-dropdown-item-button>
                      </div>
                    </div>
                    <b-dropdown-item-button
                      v-if="checkActionAvailable('IDD+') == false"
                      @click="
                        openEnablePlatformModal(
                          'Instant Due Diligence PLUS (IDD+)'
                        )
                      "
                    >
                      Request feature
                    </b-dropdown-item-button>
                  </div>
                </b-dropdown>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col style="display: flex; align-items: center" cols="8">
                <p class="m-0">{{ $t("eb360.riskOverview.edd") }}</p>
              </b-col>
              <b-col class="risk-overview__rating" cols="4">
                <b-dropdown
                  :disabled="
                    isDisabled ||
                    (thirdPartyData.is_edd_or &&
                      thirdPartyData.is_edd_or == '1' &&
                      thirdPartyData.is_edd &&
                      thirdPartyData.is_edd == '0')
                  "
                  size="sm"
                  variant="outline"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template #button-content>
                    <div v-if="thirdPartyData">
                      <IconWithText
                        v-if="!checkActionAvailable('EDD')"
                        v-bind="riskIcons.NOT_AVAILABLE"
                      />
                      <div v-if="checkActionAvailable('EDD') == true">
                        <div
                          v-if="
                            thirdPartyData.is_edd_or &&
                            thirdPartyData.is_edd_or == '1' &&
                            thirdPartyData.is_edd &&
                            thirdPartyData.is_edd == '0'
                          "
                        >
                          <IconWithText v-bind="riskIcons.IN_PROGRESS" />
                        </div>
                        <div
                          v-else-if="
                            thirdPartyData.is_edd &&
                            thirdPartyData.is_edd == '1'
                          "
                        >
                          <IconWithText
                            v-bind="getIconWithText(thirdPartyData.edd_color)"
                          />
                        </div>
                        <div v-else>
                          <IconWithText v-bind="riskIcons.NOT_RUN" />
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <IconWithText v-bind="riskIcons.NOT_AVAILABLE" />
                    </div>
                  </template>
                  <div>
                    <b-dropdown-item-button
                      v-if="checkActionAvailable('EDD') == true"
                      @click="onViewThirdPartyModal('EDD')"
                    >
                      Perform this Action
                    </b-dropdown-item-button>
                    <b-dropdown-item-button
                      v-if="checkActionAvailable('EDD') == false"
                      @click="
                        openEnablePlatformModal('Enhanced Due Diligence (EDD)')
                      "
                    >
                      Request feature
                    </b-dropdown-item-button>
                  </div>
                </b-dropdown>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col style="display: flex; align-items: center" cols="8">
                <p class="m-0">{{ $t("eb360.riskOverview.execSummary") }}</p>
              </b-col>
              <b-col class="risk-overview__rating" cols="4">
                <b-dropdown
                  :disabled="isDisabled"
                  size="sm"
                  variant="outline"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template #button-content>
                    <IconWithText
                      v-if="checkActionAvailable('Executive')"
                      v-bind="riskIcons.NOT_RUN"
                    />
                    <IconWithText v-else v-bind="riskIcons.NOT_AVAILABLE" />
                  </template>
                  <div>
                    <b-dropdown-item-button
                      v-if="checkActionAvailable('Executive') == true"
                      :disabled="!thirdPartyData"
                      @click="onViewThirdPartyModal"
                      >View</b-dropdown-item-button
                    >
                    <b-dropdown-item-button
                      v-if="checkActionAvailable('Executive') == true"
                      @click="onViewThirdPartyModal('Executive')"
                    >
                      Perform this Action
                    </b-dropdown-item-button>
                    <b-dropdown-item-button
                      v-if="checkActionAvailable('Executive') == false"
                      @click="
                        openEnablePlatformModal('Executive Summary Analysis')
                      "
                    >
                      Request feature
                    </b-dropdown-item-button>
                  </div>
                </b-dropdown>
              </b-col>
            </b-row>
            <b-row
              class="risk-overview__products"
              v-for="(data, index) in products"
              :key="index"
            >
              <b-col class="risk-overview__products-title" :cols="8">
                {{ data.title }}
              </b-col>
              <b-col class="risk-overview__products-dropdown" :cols="4">
                <b-dropdown
                  :disabled="isDisabled || isProductInProgress(data.id)"
                  size="sm"
                  variant="outline"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template #button-content>
                    <div v-if="thirdPartyData">
                      <IconWithText
                        v-if="!checkActionAvailable(data.gridBtntext)"
                        v-bind="riskIcons.NOT_AVAILABLE"
                      />
                      <div v-if="checkActionAvailable(data.gridBtntext)">
                        <IconWithText
                          v-if="isProductInProgress(data.id)"
                          v-bind="riskIcons.IN_PROGRESS"
                        />
                        <BaseBadge
                          v-else-if="isProductCompleted(data.id)"
                          text="Completed"
                          :theme="themes.STRONG_GREEN"
                        />
                        <IconWithText
                          v-else
                          class="risk-overview__products-dropdown-icon"
                          v-bind="riskIcons.NOT_RUN"
                        />
                      </div>
                    </div>
                    <div v-else>
                      <IconWithText v-bind="riskIcons.NOT_AVAILABLE" />
                    </div>
                  </template>
                  <div>
                    <b-dropdown-item-button
                      v-if="checkActionAvailable(data.gridBtntext)"
                      :disabled="!thirdPartyData"
                      @click="goToDueDiligenceTab()"
                      >View</b-dropdown-item-button
                    >
                    <b-dropdown-item-button
                      v-if="checkActionAvailable(data.gridBtntext)"
                      @click="onViewThirdPartyModal(data.gridBtntext)"
                    >
                      Perform this Action
                    </b-dropdown-item-button>
                    <b-dropdown-item-button
                      v-if="!checkActionAvailable(data.gridBtntext)"
                      @click="openEnablePlatformModal(data.btnText)"
                    >
                      Request feature
                    </b-dropdown-item-button>
                  </div>
                </b-dropdown>
              </b-col>
            </b-row>
          </b-card>
          <!-- NEW UBO BLOCK -->
          <b-card class="risk-overview__section">
            <b-card-title
              class="risk-overview__section-title risk-overview__section-title--ubo"
            >
              <p class="m-0">Ultimate Beneficial Owner</p>
              <Tooltip text="Highest remediated risk for all screened UBOs" />
            </b-card-title>
            <b-row class="mb-2">
              <b-col style="display: flex; align-items: center" cols="8">
                <p class="m-0">Instant Due Diligence Summary</p>
              </b-col>
              <b-col class="risk-overview__rating" cols="4">
                <b-dropdown
                  size="sm"
                  variant="outline"
                  toggle-class="text-decoration-none"
                  no-caret
                  :class="
                    hasUBOFeature &&
                    !hasUBOData(thirdPartyData?.overall_ubo_idd_risk)
                      ? 'risk-overview__dropdown-disabled'
                      : ''
                  "
                >
                  <template #button-content>
                    <IconWithText
                      v-bind="
                        getUBOIconOptions(thirdPartyData?.overall_ubo_idd_risk)
                      "
                    />
                  </template>
                  <b-dropdown-item-button
                    v-if="!hasUBOFeature"
                    @click="
                      openEnablePlatformModal('Ultimate beneficial ownership')
                    "
                  >
                    Request feature
                  </b-dropdown-item-button>
                  <b-dropdown-item-button
                    v-if="hasUBOData(thirdPartyData?.overall_ubo_idd_risk)"
                    @click="goToUBOTab()"
                  >
                    View
                  </b-dropdown-item-button>
                </b-dropdown>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col style="display: flex; align-items: center" cols="8">
                <p class="m-0">Instant Due Diligence Plus Summary</p>
              </b-col>
              <b-col class="risk-overview__rating" cols="4">
                <b-dropdown
                  size="sm"
                  variant="outline"
                  toggle-class="text-decoration-none"
                  no-caret
                  :class="
                    hasUBOFeature &&
                    !hasUBOData(thirdPartyData?.overall_ubo_idd_plus_risk)
                      ? 'risk-overview__dropdown-disabled'
                      : ''
                  "
                >
                  <template #button-content>
                    <IconWithText
                      v-bind="
                        getUBOIconOptions(
                          thirdPartyData?.overall_ubo_idd_plus_risk
                        )
                      "
                    />
                  </template>
                  <b-dropdown-item-button
                    v-if="!hasUBOFeature"
                    @click="
                      openEnablePlatformModal('Ultimate beneficial ownership')
                    "
                  >
                    Request feature
                  </b-dropdown-item-button>
                  <b-dropdown-item-button
                    v-if="hasUBOData(thirdPartyData?.overall_ubo_idd_plus_risk)"
                    @click="goToUBOTab()"
                  >
                    View
                  </b-dropdown-item-button>
                </b-dropdown>
              </b-col>
            </b-row>
          </b-card>
          <b-card class="risk-overview__section">
            <b-card-title class="risk-overview__section-title">
              {{ $t("eb360.riskOverview.perceivedRisk") }}&trade;
            </b-card-title>
            <b-row class="mb-2">
              <b-col style="display: flex; align-items: center" cols="8">
                <p class="m-0">{{ $t("eb360.riskOverview.standardPri") }}</p>
              </b-col>
              <b-col class="risk-overview__rating" cols="4">
                <b-dropdown
                  :disabled="isDisabled"
                  size="sm"
                  variant="outline"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template #button-content>
                    <div v-if="thirdPartyData">
                      <div v-if="thirdPartyData.tpi_type == 'O'">
                        <IconWithText
                          v-if="!checkActionAvailable('PRI')"
                          v-bind="riskIcons.NOT_AVAILABLE"
                        />
                        <div v-if="checkActionAvailable('PRI') == true">
                          <div v-if="thirdPartyData.ethix_risk_rating != null">
                            <IconWithText
                              v-if="
                                thirdPartyData.ethix_risk_rating == 1 ||
                                thirdPartyData.ethix_risk_rating == 2 ||
                                thirdPartyData.ethix_risk_rating == 3
                              "
                              v-bind="
                                getIconWithText(
                                  thirdPartyData.ethix_risk_rating
                                )
                              "
                            />
                            <IconWithText v-else v-bind="riskIcons.NOT_RUN" />
                          </div>
                          <IconWithText
                            v-else
                            v-bind="riskIcons.NOT_AVAILABLE"
                          />
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div
                        v-if="
                          thirdPartyData.tpi_type ===
                          thirdPartyType.ORGANIZATION
                        "
                      >
                        <IconWithText v-bind="riskIcons.NOT_AVAILABLE" />
                      </div>
                    </div>
                  </template>
                  <div>
                    <div v-if="thirdPartyData.ethix_risk_rating != null">
                      <b-dropdown-item-button
                        v-if="
                          (thirdPartyData.ethix_risk_rating == 1 ||
                            thirdPartyData.ethix_risk_rating == 2 ||
                            thirdPartyData.ethix_risk_rating == 3) &&
                          checkActionAvailable('PRI') == true
                        "
                        :disabled="!thirdPartyData"
                        @click="onViewThirdPartyModal('')"
                      >
                        View
                      </b-dropdown-item-button>
                    </div>
                    <b-dropdown-item-button
                      v-if="checkActionAvailable('PRI') == true"
                      @click="onViewThirdPartyModal('PRI')"
                    >
                      Perform this Action
                    </b-dropdown-item-button>
                    <b-dropdown-item-button
                      v-if="checkActionAvailable('PRI') == false"
                      @click="openEnablePlatformModal('Standard PRI')"
                    >
                      Request feature
                    </b-dropdown-item-button>
                  </div>
                </b-dropdown>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col style="display: flex; align-items: center" cols="8">
                <p class="m-0">{{ $t("eb360.riskOverview.configuredPri") }}</p>
              </b-col>
              <b-col class="risk-overview__rating" cols="4">
                <b-dropdown
                  :disabled="isDisabled"
                  size="sm"
                  variant="outline"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template #button-content>
                    <IconWithText
                      v-if="checkActionAvailable('CONFIG PRI')"
                      v-bind="riskIcons.NOT_RUN"
                    />
                    <IconWithText v-else v-bind="riskIcons.NOT_AVAILABLE" />
                  </template>
                  <div>
                    <b-dropdown-item-button
                      v-if="checkActionAvailable('CONFIG PRI') == true"
                      :disabled="!thirdPartyData"
                      @click="onViewThirdPartyModal"
                    >
                      View
                    </b-dropdown-item-button>
                    <b-dropdown-item-button
                      v-if="checkActionAvailable('CONFIG PRI') == true"
                      @click="onViewThirdPartyModal('CONFIG PRI')"
                    >
                      Perform this Action
                    </b-dropdown-item-button>
                    <b-dropdown-item-button
                      v-if="checkActionAvailable('CONFIG PRI') == false"
                      @click="openEnablePlatformModal('Configured PRI')"
                    >
                      Request feature
                    </b-dropdown-item-button>
                  </div>
                </b-dropdown>
              </b-col>
            </b-row>
          </b-card>

          <b-card class="risk-overview__section">
            <b-card-title class="risk-overview__section-title">
              {{ $t("eb360.riskOverview.trainingStatus") }}
            </b-card-title>
            <b-row
              v-for="training in thirdPartyTrainingSummaryList"
              :key="training?.id"
            >
              <b-col style="display: flex; align-items: center" cols="8">
                <BaseText :text="training?.title" />
              </b-col>
              <b-col style="display: flex; justify-content: right" cols="4">
                <BaseBadge
                  :text="training?.status"
                  :theme="getTrainingStatusTheme(training?.status)"
                />
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
    <PlatformModal />
  </div>
</template>

<script>
import {
  getCustomAttributeRatingScaleTypeId,
  getRiskIconBasedOnStatus,
  HTTP,
  makeAuthorizationHeader,
  getIconWithText,
  getRiskRatingIconWithText,
  hasPermission
} from "@/utils";
import {
  attributeType,
  ddqScoring,
  eventBusName,
  incomingRiskStatus,
  riskRating,
  riskStatus,
  riskStatusMap,
  textAlign,
  themes,
  icons,
  shapes,
  border,
  thirdPartyActionProductStatus,
  thirdPartyTabHash,
  thirdPartyType,
  msqActionStatusValue,
  iconSizes,
  riskIcons,
  featurePermissionsKeys,
  userPermissionKeysDTO
} from "@/constants";
import { EventBus } from "@/event-bus.js";
import defaultActionJson from "@/utils/defaultAction.json";
import PlatformModal from "@/components/eb2/thirdpartyProfilePageComponents/riskSummaryTab/enablePlatformModal.vue";
import RatingCustomAttribute from "@/organisms/RatingCustomAttribute/RatingCustomAttribute.vue";
import { makeCustomAttributeListDTO } from "@/organisms/RatingCustomAttribute/RatingCustomAttribute.dto.js";
import { mapMutations, mapState } from "vuex";
import BaseBadge from "@/atoms/BaseBadge/BaseBadge";
import BaseText from "@/atoms/BaseText/BaseText";
import Tooltip from "@/molecules/Tooltip/Tooltip";
import IconWithText from "@/molecules/IconWithText/IconWithText";
import { getTrainingStatusTheme } from "@/utils/thirdParty/thirdPartyTrainings.utils";

export default {
  name: "RiskOverview",
  components: {
    IconWithText,
    PlatformModal,
    RatingCustomAttribute,
    BaseBadge,
    Tooltip,
    BaseText
  },
  props: {
    thirdPartyId: {
      type: Number,
      default: 0
    },
    isDisabled: {
      type: Boolean,
      required: true
    },
    thirdPartyData: {
      type: Object,
      required: true
    },
    isDnbModal: {
      type: Boolean,
      required: true
    },
    customAttributes: {
      type: Array,
      default: () => []
    },
    hasUBOFeature: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      icons,
      shapes,
      border,
      themes,
      riskIcons,
      isLoadingData: false,
      availableActionSchema: null,
      riskData: [],
      riskScoreColor: [],
      questionnaires: [],
      riskDataId: [],
      thirdPartyType,
      products: [],
      ddqScoring,
      textAlign,
      getCustomAttributeRatingScaleTypeId,
      getIconWithText,
      getRiskRatingIconWithText,
      iconSizes,
      getTrainingStatusTheme
    };
  },
  mounted() {
    this.getQuestionnairesData();
  },
  computed: {
    hasIDDRiskLevel() {
      const riskLevel = this.thirdPartyData?.risk_level || "0";

      return !!parseInt(riskLevel);
    },
    getCustomAttributeList() {
      if (
        this.customAttributes.length &&
        this.thirdPartyData?.tp_custom_att_searches?.length
      ) {
        const unassignedRatingCustomAttributes = this.customAttributes.reduce(
          (unassignedRatingCustomAttributesList, customAttribute) => {
            const indexOfCustomAttributeInThirdPartyData =
              this.thirdPartyData.tp_custom_att_searches.findIndex(
                ({ tp_customatt }) =>
                  tp_customatt === customAttribute.tp_customatt
              );

            if (customAttribute.tp_type === attributeType.RATING) {
              if (indexOfCustomAttributeInThirdPartyData === -1) {
                unassignedRatingCustomAttributesList.push(customAttribute);
              } else {
                unassignedRatingCustomAttributesList.push({
                  ...customAttribute,
                  ...this.thirdPartyData.tp_custom_att_searches[
                    indexOfCustomAttributeInThirdPartyData
                  ]
                });
              }
            }
            return unassignedRatingCustomAttributesList;
          },
          []
        );
        unassignedRatingCustomAttributes.sort(
          (customAttributeA, customAttributeB) =>
            Number.parseInt(customAttributeA.tp_sequence) <
            Number.parseInt(customAttributeB.tp_sequence)
              ? -1
              : Number.parseInt(customAttributeA.tp_sequence) >
                Number.parseInt(customAttributeB.tp_sequence)
              ? 1
              : 0
        );

        return makeCustomAttributeListDTO(unassignedRatingCustomAttributes);
      } else {
        return [];
      }
    },
    filteredQuestionnaires() {
      return this.questionnaires?.filter(
        (data) => !data?.suppressScoreReporting
      );
    },
    ...mapState({
      companyId: (state) => state.company.companyId,
      thirdPartyTrainingSummaryList: (state) =>
        state.thirdParty.thirdPartyTrainingSummaryList
    })
  },
  methods: {
    ...mapMutations({
      setTabIndexProfilePage: "setTabIndexProfilePage"
    }),
    getUBOIconOptions(overallUBORisk = "") {
      let mappedStatus;

      if (!this.hasUBOFeature && !this.hasUBOData(overallUBORisk)) {
        mappedStatus = riskStatus.NOT_AVAILABLE;
      } else {
        mappedStatus = riskStatusMap[overallUBORisk];
      }
      ``;
      return getRiskIconBasedOnStatus(mappedStatus);
    },
    hasUBOData(overallUBORisk = "") {
      return (
        !!overallUBORisk && overallUBORisk !== incomingRiskStatus.NOT_REQUESTED
      );
    },
    goToUBOTab() {
      this.$emit("click", thirdPartyTabHash.UBO);
    },
    isRiskRatingAvailable({ gridBtntext, id, is_scored }) {
      return (
        this.checkActionAvailable(gridBtntext) === true &&
        this.riskData[id].rating !== riskRating.INVALID &&
        parseInt(is_scored) === ddqScoring.IS_SCORED
      );
    },
    getProductId(id) {
      if (this.thirdPartyData?.product_orders) {
        return this.thirdPartyData.product_orders.findIndex(
          (product) => parseInt(product.product_id) === parseInt(id)
        );
      } else {
        return -1;
      }
    },
    isProductInProgress(id) {
      const key = id.split("_")[1];
      if (this.getProductId(key) !== -1) {
        return (
          this.thirdPartyData?.product_orders[this.getProductId(key)]
            ?.status === thirdPartyActionProductStatus.IN_PROGRESS
        );
      }
    },
    isProductCompleted(id) {
      const key = id.split("_")[1];
      if (this.getProductId(key) !== -1) {
        return (
          this.thirdPartyData?.product_orders[this.getProductId(key)]
            ?.status === thirdPartyActionProductStatus.COMPLETED
        );
      }
    },
    async questionnairesSuccess(data) {
      this.questionnaires = data?.questionnaires || [];
      const questionnairesPromises = [];
      for (const questionnaire of this.questionnaires) {
        questionnairesPromises.push(
          this.getTpQuestionnairesRiskData(questionnaire.id)
        );
      }
      await Promise.all(questionnairesPromises);
    },
    async getQuestionnairesData() {
      try {
        this.isLoadingData = true;
        let { data = {} } = await HTTP(
          "get",
          `v1/getactiveformslist/${this.companyId}`,
          null,
          makeAuthorizationHeader()
        );
        this.products = data.products;
        await this.questionnairesSuccess(data);
      } catch (error) {
        return error;
      } finally {
        this.isLoadingData = false;
      }
    },
    openEnablePlatformModal(value) {
      EventBus.emit("onEnablePlatformModal", value);
    },
    async getTpQuestionnairesRiskData(formId) {
      try {
        let response = await HTTP(
          "post",
          "thirdparty/lastcomplatedquestionnairesriskdata",
          {
            formId,
            thirdPartyId: this.thirdPartyId
          },
          makeAuthorizationHeader()
        );
        if (
          response.data.response &&
          response.data.status == 1 &&
          response.status == 200
        ) {
          let risk_data = response.data.response.risk_data;
          this.riskData[formId] = JSON.parse(risk_data);
          this.riskDataId[formId] = response.data.response.id;
          this.checkRiskScoreColor(JSON.parse(risk_data), formId);
        } else {
          this.items = [];
        }
        this.$store.state.riskSummaryOverviewTabLazyLoad = false;
      } catch (error) {
        this.isLoadingData = false;
        return [error];
      }
    },
    checkRiskScoreColor(parsedRiskData, formId) {
      if (parsedRiskData) {
        let risk = parsedRiskData.risk.toLowerCase();
        if (risk == "high") {
          this.riskScoreColor[formId] = 1;
        } else if (risk == "low") {
          this.riskScoreColor[formId] = 2;
        } else if (risk == "moderate") {
          this.riskScoreColor[formId] = 3;
        }
      } else {
        this.riskScoreColor[formId] = null;
      }
    },
    onViewThirdPartyModal(actionPerformedType = null) {
      if (this.isDnbModal) {
        EventBus.emit(
          eventBusName.EDIT_THIRD_PARTY_DNB_MODAL,
          this.thirdPartyData,
          actionPerformedType
        );
      } else {
        EventBus.emit(
          eventBusName.EDIT_THIRD_PARTY_MODAL,
          this.thirdPartyData,
          actionPerformedType
        );
      }
    },
    checkActionAvailable(key) {
      let configuredActionData = localStorage.getItem("actionsSchema");
      let availableActionSchema = configuredActionData
        ? JSON.parse(configuredActionData)
        : null;
      let actionsOccur =
        availableActionSchema && availableActionSchema.actionsOccur
          ? availableActionSchema.actionsOccur
          : defaultActionJson.actionsOccur;
      let extraAction =
        availableActionSchema && availableActionSchema.extraActions
          ? availableActionSchema.extraActions
          : defaultActionJson.extraActions;
      extraAction.push(...actionsOccur);
      if (!availableActionSchema.extraActions) {
        extraAction.push(...this.questionnaires);
        extraAction.push(...this.products);
      }

      let indexAction = extraAction.findIndex(
        (action) => action.gridBtntext == key
      );
      return indexAction !== -1;
    },
    goToQuestionnaireTab() {
      this.setTabIndexProfilePage(3);
    },
    goToDueDiligenceTab() {
      this.setTabIndexProfilePage(2);
    },
    getMSQIcon(status) {
      switch (parseInt(status)) {
        case msqActionStatusValue.LOW:
          return riskIcons.NO_RISK_ALERT;
        case msqActionStatusValue.MEDIUM:
          return riskIcons.MODERATE_RISK_ALERT;
        case msqActionStatusValue.HIGH:
          return riskIcons.RISK_ALERT;
        default:
          return riskIcons.NOT_AVAILABLE;
      }
    },

    hasCustomAttributeViewPermission(customAttributeId) {
      return hasPermission({
        key: featurePermissionsKeys.VIEW_VALUE,
        contextId: customAttributeId,
        contextKey: userPermissionKeysDTO.CUSTOM_ATTRIBUTE_ID
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.risk-overview {
  .risk-overview :deep(.rating-custom-attribute) {
    text-align: center;
    padding: 4px 8px;
  }
  &:deep(.risk-overview__products-dropdown-icon) {
    float: right;
  }

  &__products {
    margin-bottom: 8px;

    &-title {
      margin: 0;
      display: flex;
      align-items: center;
    }
    &-dropdown {
      text-align: center;
    }
  }

  &__section {
    border: 0;

    &-title {
      @include heading-three;

      &--ubo {
        display: flex;
        gap: 8px;
      }
    }
  }

  &__custom-attribute-rating {
    text-align: right;
  }

  &__rating {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__rating-list {
    align-items: center;
    margin-top: 5px;
  }

  &__dropdown-disabled {
    pointer-events: none;
  }
}
</style>
