<template>
  <div class="login" data-test-id="login">
    <BaseLoader
      v-if="isLoadingPublicPlatformSettings"
      data-test-id="login__loader"
    />
    <template v-else>
      <Logo
        class="login__logo"
        :logo-props="{ height: '98px', width: '320px' }"
      />
      <BaseInfoBox
        v-if="isErrorOnPage"
        class="login__error"
        data-test-id="alert-heading"
        :text="pageErrorMessages"
        :icon-options="defaultIcons.HIGH"
        :theme="themes.ERROR"
      />
      <div class="login__container">
        <div class="login__form">
          <form data-test-id="login__form" @keyup.enter="onSubmit">
            <Input
              id="username"
              name="username"
              label="Email address"
              placeholder="eg. username@ethixbase.com"
              class="login__form-input"
              data-test-id="login__form-email"
              :is-required="false"
              :value="username"
              :error="validationMessage.username"
              :include-colon="false"
              @change="onChangeInput('username', $event)"
            />
            <Input
              type="password"
              id="password"
              name="password"
              label="Password"
              placeholder="**********"
              class="login__form-input"
              data-test-id="login__form-password"
              :is-required="false"
              :value="password"
              :error="validationMessage.password"
              :include-colon="false"
              @change="onChangeInput('password', $event)"
            />
          </form>
          <CallToAction
            class="login__button"
            value="Login"
            icon="eb-icon-angle-right"
            data-test-id="login__login"
            :hasIconWithText="!isAuthenticating"
            :is-loading="isAuthenticating"
            :icon-size="12"
            @click="onSubmit"
          />
          <div class="login__organization-login-container">
            <CallToAction
              class="login__organization-login"
              value="Organization login"
              has-link-style
              :theme="themes.ETHIXBASE_PRIMARY_INVERSE"
              :text-size="typographySize.BODY_TEXT_BOLD"
              data-test-id="login__org-login"
              @click="onOrganizationLogin"
            />
            <Tooltip
              class="third-party-details__system-owner-tooltip"
              :icon-size="10"
              text="Only use 'Organization login' if you are a direct client of Ethixbase360 and your organization uses Single Sign On."
            />
          </div>
        </div>
        <CallToAction
          class="login__forgot-password"
          value="Forgot Password?"
          data-test-id="login__forgot-password"
          has-link-style
          :theme="themes.ETHIXBASE_PRIMARY_INVERSE"
          @click="$router.push(urls.FORGOT_PASSWORD)"
        />
      </div>
      <div class="login__links" data-test-id="login__links">
        <p v-if="isAnyLinkAvailable">
          By logging in to the platform I agree to the
          <Link
            v-if="platformTermsAndConditionsLink"
            :anchor-options="{
              href: platformTermsAndConditionsLink,
              target: '_blank'
            }"
            :text-options="{
              text: 'Terms & Conditions',
              theme: themes.ETHIXBASE_PRIMARY_INVERSE
            }"
          />
          {{ isAnyLinkAvailable ? "and" : "" }}
          <Link
            v-if="platformPrivacyPolicyLink"
            :anchor-options="{
              href: platformPrivacyPolicyLink,
              target: '_blank'
            }"
            :text-options="{
              text: 'Privacy & Cookie Policy',
              theme: themes.ETHIXBASE_PRIMARY_INVERSE
            }"
          />
        </p>
      </div>
    </template>
    <RouterView />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import {
  languages,
  languagesInitial,
  localStorageItem,
  privacyPolicy,
  regex,
  urls,
  validationKeys,
  defaultIcons,
  customErrorMessages,
  themes,
  typographySize
} from "@/constants";
import Input from "@/molecules/Input/Input.vue";
import Logo from "@/organisms/Logo/Logo";
import BaseLoader from "@/atoms/BaseLoader/BaseLoader.vue";
import BaseInfoBox from "@/atoms/BaseInfoBox/BaseInfoBox.vue";
import CallToAction from "@/atoms/CallToAction/CallToAction";
import Link from "@/molecules/Link/Link";
import Tooltip from "@/molecules/Tooltip/Tooltip";
import { isRequired } from "@/utils";

export default {
  name: "LoginPage",
  components: {
    BaseInfoBox,
    BaseLoader,
    Logo,
    Input,
    CallToAction,
    Link,
    Tooltip
  },
  data() {
    return {
      username: null,
      password: null,
      isAuthenticating: false,
      validationMessage: this.makeValidationMessages(),
      pageErrorMessages: "",
      inputModelList: ["username", "password"],
      urls,
      themes,
      typographySize,
      defaultIcons
    };
  },
  computed: {
    ...mapState({
      platformTermsAndConditionsLink: (state) =>
        state.platformSettings.platformTermsAndConditionsLink,
      platformPrivacyPolicyLink: (state) =>
        state.platformSettings.platformPrivacyPolicyLink,
      disablePrivacyPolicy: (state) =>
        state.platformSettings.disablePrivacyPolicy,
      platformSSOURL: (state) => state.platformSettings.platformSSOURL,
      isLoadingPublicPlatformSettings: (state) =>
        state.platformSettings.isLoadingPublicPlatformSettings
    }),
    ...mapGetters({
      permittedLandingPage: "user/permittedLandingPage",
      isUserThirdParty: "user/isUserThirdParty",
      isUserNotUpdated: "user/isUserNotUpdated"
    }),
    isErrorOnPage() {
      return !!this.pageErrorMessages;
    }
  },
  methods: {
    isRequired,
    ...mapActions({
      logIn: "auth/logIn"
    }),
    isAnyLinkAvailable() {
      return !!(
        this.platformTermsAndConditionsLink ||
        this.isPrivacyPolicyLinkAvailable()
      );
    },
    isPrivacyPolicyLinkAvailable() {
      return !!(
        this.platformPrivacyPolicyLink &&
        parseInt(this.disablePrivacyPolicy) === privacyPolicy.IS_DISABLED
      );
    },
    onChangeInput(inputName, val) {
      this[inputName] = val;
      this.validationMessage[inputName] = "";
    },
    validateInputs() {
      this.clearValidationMessage();
      let isValid = true;

      if (!this.isRequired(this.username)) {
        this.validationMessage.username =
          customErrorMessages.login.username[validationKeys.REQUIRED];
        isValid = false;
      }

      if (!this.isRequired(this.password)) {
        this.validationMessage.password =
          customErrorMessages.login.password[validationKeys.REQUIRED];
        isValid = false;
      }

      return isValid;
    },
    makeValidationMessages() {
      return {
        username: "",
        password: ""
      };
    },
    onSubmit() {
      this.clearValidationMessage();
      if (this.validateInputs()) {
        this.authenticate();
      }
    },
    clearValidationMessage() {
      this.validationMessage = this.makeValidationMessages();
      this.pageErrorMessages = "";
    },
    authenticationFailed({ status } = {}) {
      if (status === 401) {
        this.pageErrorMessages =
          customErrorMessages.generic[validationKeys.INVALID_EMAIL_PASSWORD];
      } else if (status === 403) {
        this.handleNavigation(status);
      } else {
        this.pageErrorMessages = "Unknown error.";
      }
    },
    async authenticate() {
      try {
        this.pageErrorMessages = "";
        this.isAuthenticating = true;
        const { data = {}, status } = await this.logIn({
          username: this.username,
          password: this.password
        });
        this.authenticationSucceeded(data, status);
      } catch ({ response }) {
        this.authenticationFailed(response);
      } finally {
        this.isAuthenticating = false;
        this.username = null;
        this.password = null;
      }
    },
    authenticationSucceeded(data, status) {
      this.setValuesInLocalStorage(data);
      this.handleNavigation(status);
    },
    handleNavigation(authStatus) {
      if (authStatus === 200) {
        if (this.isUserThirdParty) {
          const path = this.isUserNotUpdated
            ? urls.CREATE_PROFILE
            : urls.DASHBOARD;
          this.$router.push(path);
        } else {
          this.$router.push(this.permittedLandingPage);
        }
      } else if (authStatus === 403) {
        this.$router.push(urls.PLATFORM_SWITCHER);
      }
    },
    setValuesInLocalStorage(data) {
      const { formTranslations, contact } = data;

      localStorage.setItem(
        localStorageItem.FORM_DEFAULT_LANGUAGE,
        this.contactLanguage(contact)
      );
      localStorage.setItem(
        localStorageItem.FORM_TRANSLATIONS,
        Array.isArray(formTranslations) && formTranslations?.length
          ? formTranslations
          : [languages[languagesInitial.ENGLISH]]
      );
    },
    contactLanguage({ language }) {
      if (languages[language]) {
        return languages[language];
      } else {
        return languages[languagesInitial.ENGLISH];
      }
    },
    onOrganizationLogin() {
      window.location = `${
        this.platformSSOURL
      }/landing?tenant=${this.getSubDomain(window.location.href)}`;
    },
    getSubDomain(url) {
      if (regex.CHECK_URL.test(url)) {
        return url.match(regex.CHECK_URL)[2].split(".")[0];
      } else {
        return null;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.login {
  background-color: $wild-sand-gray;
  min-height: 100vh;
  padding: $spacing12;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;

  &__logo {
    margin-bottom: $spacing32;
  }

  &__form {
    padding: $spacing40 $spacing40 $spacing48;
    background-color: $white;
    border-radius: $spacing24;
    width: 400px;
    border: 1px solid $grey;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.21);
    text-align: left;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: end;
  }

  &__button {
    display: flex;
    align-items: center;
    gap: $spacing8;
    height: fit-content;
    width: 100%;
    border-radius: $spacing20;
    border: 1px solid $primary-color;
    margin-top: $spacing12;
    padding: $spacing8 $spacing16;
    justify-content: center;
  }

  &__organization-login {
    text-decoration: underline;

    &-container {
      margin-top: $spacing20;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: $spacing8;
    }
  }

  &__forgot-password {
    margin-top: $spacing12;
    text-align: right;
    text-decoration: underline;
  }

  &__links {
    margin-top: $spacing48;
  }

  &__error {
    text-align: left;
    margin-bottom: $spacing24;
  }
}
</style>
