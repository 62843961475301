import { esgService } from "@/services";

export default {
  async fetchESGReports({ commit }, endpointParameters) {
    const response = await esgService.fetchESGReports(endpointParameters);

    commit("esg/setESGReports", response, { root: true });
  },
  async fetchESGReportById({commit}, endpointParameters) {
    const response = await esgService.fetchESGReportById(endpointParameters);

    commit("esg/setLatestESGReport", response, { root: true });
  }
};
