import RegisterForm from "../pages/register";
import MyTPP from "../pages/mytpp";
import MyTPPQuestionnaires from "../pages/mytpp-questionnaires";
import UserFormPage from "../pages/user-form";
import PreviewPage from "../pages/preview";
import TemplatesPage from "@/pages/templates";
import ClientsConfigurationPage from "@/pages/eb2/clientsConfiguration";
import FormPage from "@/pages/form";
import FormConfigurationContainer from "@/containers/FormConfigurationContainer";
import FormIntroContainer from "@/containers/FormIntroContainer";
import FormDetailsContainer from "@/containers/FormDetailsContainer";
import FormBuilderContainer from "@/containers/FormBuilderContainer";
import FormJsonContainer from "@/containers/FormJsonContainer";
import ClientsConfigurationProfilePage from "@/pages/eb2/clientsConfigurationProfilePage";
import PlatformSettings from "@/pages/eb2/clientConfigurationProfile/platformSettings";
import Settings from "@/pages/eb2/clientConfigurationProfile/settings";
import EmailTemplates from "@/pages/eb2/clientConfigurationProfile/emailTemplates";
import DynamicActions from "@/pages/eb2/clientConfigurationProfile/dynamicActions";
import DynamicColumns from "@/pages/eb2/clientConfigurationProfile/dynamicColumns";
import DynamicBulkOrderActions from "@/pages/eb2/clientConfigurationProfile/dynamicBulkOrderActions";
import DynamicEddConfiguration from "@/pages/eb2/clientConfigurationProfile/dynamicEddConfiguration";
import ActionDetails from "@/pages/eb2/clientConfigurationProfile/accounts/actionDetails";
import ActionSettings from "@/pages/eb2/clientConfigurationProfile/accounts/actionSettings";
import DynamicAnalytics from "@/pages/eb2/clientConfigurationProfile/dynamicAnalytics";
import userTrainingRoutes from "@/routes/userTraining/userTraining.routes";
import ManageProduct from "@/pages/eb2/clientConfigurationProfile/ManageProduct/ManageProduct";

import {
  urls,
  routeNames,
  routeKeys,
  templates
} from "@/constants";
import store from "@/store";

export default [
  {
    path: "/onboarding",
    name: routeNames[routeKeys.ONBOARDING],
    redirect: { name: routeNames[routeKeys.ONBOARDING_TEMPLATES] }
  },
  {
    path: urls.ONBOARDING_TEMPLATES,
    name: routeNames[routeKeys.ONBOARDING_TEMPLATES],
    component: TemplatesPage,
    props: true,
    meta: {
      requiresAuth: true,
      isAdmin: true,
      template: templates.THIRD_PARTY
    },
    beforeEnter: (to, from, next) => {
      if (store.getters["user/isUserThirdParty"]) {
        next(urls.DASHBOARD);
      } else {
        next();
      }
    }
  },
  {
    path: urls.ONBOARDING_CLIENTS,
    name: routeNames[routeKeys.ONBOARDING_CLIENTS],
    component: ClientsConfigurationPage,
    meta: { requiresAuth: true, template: templates.THIRD_PARTY }
  },
  {
    path: "/form",
    name: routeNames[routeKeys.ONBOARDING_FORM],
    component: FormPage,
    props: true,
    meta: {
      requiresAuth: true,
      isAdmin: true,
      template: templates.THIRD_PARTY
    },
    beforeEnter: (to, from, next) => {
      if (store.getters["user/isUserThirdParty"]) {
        next(urls.DASHBOARD);
      } else {
        next();
      }
    },
    children: [
      {
        path: "configuration",
        name: routeNames[routeKeys.ONBOARDING_FORM_CONFIGURATION],
        props: true,
        component: FormConfigurationContainer,
        meta: {
          requiresAuth: true,
          isAdmin: true,
          template: templates.THIRD_PARTY
        }
      },
      {
        path: "intro",
        name: routeNames[routeKeys.ONBOARDING_FORM_INTRO],
        props: true,
        component: FormIntroContainer,
        meta: {
          requiresAuth: true,
          isAdmin: true,
          template: templates.THIRD_PARTY
        }
      },
      {
        path: "details",
        name: routeNames[routeKeys.ONBOARDING_FORM_DETAILS],
        props: true,
        component: FormDetailsContainer,
        meta: {
          requiresAuth: true,
          isAdmin: true,
          template: templates.THIRD_PARTY
        }
      },
      {
        path: "builder",
        name: routeNames[routeKeys.ONBOARDING_FORM_BUILDER],
        props: true,
        component: FormBuilderContainer,
        meta: {
          requiresAuth: true,
          isAdmin: true,
          template: templates.THIRD_PARTY
        }
      },
      {
        path: "json",
        name: routeNames[routeKeys.ONBOARDING_FORM_JSON],
        props: true,
        component: FormJsonContainer,
        meta: {
          requiresAuth: true,
          isAdmin: true,
          template: templates.THIRD_PARTY
        }
      }
    ]
  },
  {
    path: urls.ONBOARDING_CLIENTS_CONFIGURATION_PROFILE(),
    name: routeNames[routeKeys.ONBOARDING_CLIENTS_CONFIGURATION_PROFILE],
    props: true,
    component: ClientsConfigurationProfilePage,
    children: [
      {
        name: routeNames[routeKeys.ONBOARDING_CLIENTS_PLATFORM_SETTINGS],
        path: "platform-settings",
        component: PlatformSettings,
        props: true,
        meta: { requiresAuth: true, template: templates.THIRD_PARTY }
      },
      {
        name: routeNames[routeKeys.ONBOARDING_CLIENTS_SETTINGS],
        path: "settings",
        component: Settings,
        props: true,
        meta: { requiresAuth: true, template: templates.THIRD_PARTY }
      },
      {
        name: routeNames[routeKeys.ONBOARDING_CLIENTS_EMAIL_TEMPLATES],
        path: "email-templates",
        component: EmailTemplates,
        props: true,
        meta: { requiresAuth: true, template: templates.THIRD_PARTY }
      },
      {
        name: routeNames[routeKeys.MANAGE_PRODUCT],
        path: urls.MANAGE_PRODUCT,
        component: ManageProduct,
        meta: { requiresAuth: true, template: templates.THIRD_PARTY }
      },
      {
        name: routeNames[routeKeys.ONBOARDING_CLIENTS_ACTIIONS],
        path: "dynamic-actions",
        component: DynamicActions,
        props: true,
        meta: { requiresAuth: true, template: templates.THIRD_PARTY }
      },
      {
        name: routeNames[routeKeys.ONBOARDING_CLIENTS_COLUMNS],
        path: "dynamic-columns",
        component: DynamicColumns,
        props: true,
        meta: { requiresAuth: true, template: templates.THIRD_PARTY }
      },
      {
        name: routeNames[routeKeys.ONBOARDING_CLIENTS_BULK_ACTIONS],
        path: "dynamic-bulk-order-actions",
        component: DynamicBulkOrderActions,
        props: true,
        meta: { requiresAuth: true, template: templates.THIRD_PARTY }
      },
      {
        name: routeNames[routeKeys.ONBOARDING_CLIENTS_EDD_CONFIGURATION],
        path: "dynamic-edd-configuration",
        component: DynamicEddConfiguration,
        props: true,
        meta: { requiresAuth: true, template: templates.THIRD_PARTY }
      },
      {
        name: routeNames[routeKeys.ONBOARDING_CLIENTS_ACTION_DETAILS],
        path: "action-details",
        component: ActionDetails,
        props: true,
        meta: { requiresAuth: true, template: templates.THIRD_PARTY }
      },
      {
        name: routeNames[routeKeys.ONBOARDING_CLIENTS_ACTION_SETTINGS],
        path: "action-settings",
        component: ActionSettings,
        props: true,
        meta: { requiresAuth: true, template: templates.THIRD_PARTY }
      },
      {
        name: routeNames[routeKeys.ONBOARDING_CLIENTS_ANALYTICS],
        path: "dynamic-analytics",
        component: DynamicAnalytics,
        props: true,
        meta: { requiresAuth: true, template: templates.THIRD_PARTY }
      }
    ]
  },
  {
    path: "/create-profile",
    name: routeNames[routeKeys.CREATE_PROFILE],
    component: RegisterForm,
    meta: { requiresAuth: true, template: templates.THIRD_PARTY },
    beforeEnter: (_, __, next) => {
      if (store.getters["user/isUserThirdParty"]) {
        if (!store.getters["user/isUserNotUpdated"]) {
          next(urls.DASHBOARD);
        }
        next();
      }
    }
  },
  {
    path: "/onboarding/profile",
    name: routeNames[routeKeys.UPDATE_PROFILE],
    component: RegisterForm,
    meta: { requiresAuth: true, template: templates.THIRD_PARTY }
  },
  {
    path: "/dashboard",
    name: routeNames[routeKeys.DASHBOARD_THIRD_PARTY],
    component: MyTPP,
    meta: { requiresAuth: true, template: templates.THIRD_PARTY }
  },
  {
    path: "/my-questionnaires",
    name: routeNames[routeKeys.MY_TPP_QUESTIONNAIRES],
    component: MyTPPQuestionnaires,
    meta: { requiresAuth: true, template: templates.THIRD_PARTY }
  },
  {
    path: urls.USER_FORM(),
    name: routeNames[routeKeys.USER_FORM],
    props: true,
    component: UserFormPage,
    meta: {
      requiresAuth: true,
      template: templates.THIRD_PARTY
    },
    beforeEnter: (to, from, next) => {
      const activeTaskStatus = localStorage.getItem("activeTaskStatus");

      if (
        (from.name && from.name === "mytppquestionnaires") ||
        activeTaskStatus
      ) {
        next();
      } else {
        next(urls.DASHBOARD);
      }
    }
  },
  {
    path: "/preview",
    name: routeNames[routeKeys.PREVIEW],
    props: true,
    component: PreviewPage,
    meta: { requiresAuth: true, isAdmin: true, template: templates.THIRD_PARTY }
  },
  userTrainingRoutes
];
