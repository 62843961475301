<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-overlay :show="isLoading" rounded="sm">
          <b-navbar toggleable="lg">
            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">
              <b-nav-form>
                <b-button
                  variant="outline-info"
                  size="sm"
                  class="mr-4"
                  @click="reloadTable"
                >
                  <font-awesome-icon icon="sync" />
                </b-button>
                <b-form-group>
                  <b-input-group size="sm">
                    <b-form-input
                      v-model="filter"
                      type="search"
                      id="filterInput"
                      placeholder="Type to Search"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''"
                        >Clear</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-nav-form>
            </b-navbar-nav>
          </b-navbar>
          <div>
            <div class="tableHeading">Email Templates</div>
            <b-table
              :busy.sync="isLoading"
              sticky-header="calc(100vh - 300px)"
              bordered
              ref="table"
              id="email-template-table"
              :fields="staticFields"
              :items="getEmailTemplates"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :filter="filter"
              no-sort-reset
              style="margintop: 15px"
            >
              <template v-slot:cell(status)="data">
                <b-badge
                  :variant="data.item.is_active == 1 ? 'success' : 'danger'"
                >
                  {{ data.item.is_active == 1 ? "Active" : "Deactive" }}
                </b-badge>
              </template>
              <template v-slot:cell(actions)="data">
                <b-dropdown
                  size="sm"
                  variant="outline"
                  toggle-class="text-decoration-none"
                  no-caret
                  dropleft
                >
                  <template #button-content>
                    <font-awesome-icon icon="ellipsis-h" />
                  </template>
                  <b-dropdown-item-button
                    @click="editEmailTemplate(data)"
                    class="text-secondary"
                    >Edit</b-dropdown-item-button
                  >
                  <b-dropdown-item-button
                    @click="onShowDeactivateModal(data.item)"
                    class="text-secondary"
                    ><span v-if="data.item.is_active == 1">Deactivate</span>
                    <span v-else>Activate</span>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button
                    @click="triggerEmailTemplate(data)"
                    class="text-secondary"
                    >Preview</b-dropdown-item-button
                  >
                </b-dropdown>
              </template>
            </b-table>
          </div>
          <div class="text-center" v-if="totalRows == 0">
            <b> No data available </b>
          </div>
          <b-row>
            <b-col cols="3"> </b-col>
            <b-col cols="6" v-if="totalRows > 0">
              <p class="text-center">
                <b-button
                  class="m-1"
                  size="sm"
                  @click="goToPreviousPage"
                  :disabled="this.currentPage <= 1"
                  variant="light"
                >
                  <font-awesome-icon icon="chevron-left" />
                </b-button>
                Showing Result From
                <b>{{ fromRows }}</b>
                to
                <b>{{ toRows }}</b>
                of
                <b>{{ totalRows }}</b>
                <b-button
                  class="m-1 ml-2"
                  size="sm"
                  @click="goToNextPage"
                  :disabled="this.currentPage >= this.last_page"
                  variant="light"
                >
                  <font-awesome-icon icon="chevron-right" />
                </b-button>
              </p>
            </b-col>
          </b-row>
        </b-overlay>
      </b-col>
    </b-row>
    <b-modal
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      size="lg"
      :title="isActiveRow == 0 ? 'Activate' : 'Deactivate'"
      v-model="showDeactivateModal"
    >
      Are you sure you want to
      {{ isActiveRow == 0 ? "Activate" : "Deactivate" }}
      <b>{{ templateName }}</b> ?
      <template v-slot:modal-footer>
        <b-button
          :disabled="isSubmitting"
          size="md"
          variant="outline-danger"
          @click="onCloseDeactivateModal"
          >{{ $t("closeBtnText") }}</b-button
        >
        <b-button
          :disabled="isSubmitting"
          size="md"
          variant="outline-success"
          @click="onDeactivateRow()"
          >Save
          <span v-if="isSubmitting">
            <b-spinner small></b-spinner>
          </span>
        </b-button>
      </template>
    </b-modal>
    <EmailTemplateFormModal :templateName="emailTemplateName" />
    <TriggerEmailModal :templateName="emailTemplateName" />
  </div>
</template>
<script>
import { EventBus } from "@/event-bus.js";
import { HTTP, makeAuthorizationHeader } from "@/utils";
import EmailTemplateFormModal from "./emailTemplateFormModal.vue";
import TriggerEmailModal from "./triggerEmailModal.vue";
import { mapGetters, mapState } from "vuex";

export default {
  name: "PlatformsSetting",
  components: {
    EmailTemplateFormModal,
    TriggerEmailModal
  },
  data() {
    return {
      showActiveEmailTemplate: false,
      showDeativeEmailTemplate: false,
      emailTemplateId: null,
      emailTemplateName: null,
      processing: false,
      counter: 1,
      interval: null,
      isLoading: false,
      totalRows: 0,
      filter: null,
      perPage: 10,
      last_page: 0,
      toRows: 0,
      fromRows: 0,
      currentPage: 1,
      sortBy: "id",
      sortDesc: true,
      pageOptions: [5, 10, 25, 50, 75, 100],
      staticFields: [
        { key: "template_name", label: "Template Name", sortable: true },
        { key: "email_type_title", label: "Email Type", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "actions", label: "Action", sortable: false }
      ],
      previewModal: false,
      isActiveRow: null,
      showDeactivateModal: false,
      rowId: null,
      templateName: null,
      isSubmitting: false
    };
  },
  computed: {
    ...mapGetters({
      fullName: "user/getFullName"
    }),
    ...mapState({
      companyId: (state) => state.company.companyId,
      companyName: (state) => state.company.companyName,
      userId: (state) => state.user.userId
    }),
    rows() {
      return this.totalRows;
    }
  },
  created() {
    EventBus.on("onReloadTable", () => {
      this.reloadTable();
    });
  },
  beforeDestroy() {
    this.clearInterval();
  },
  methods: {
    openPreviewModal() {
      this.previewModal = true;
    },
    goToPreviousPage() {
      this.showTableData = false;
      this.currentPage--;
      this.tableLoaderTop = true;
      this.tableLoader = true;
      setTimeout(() => {
        this.showTableData = true;
      }, 10);
    },
    goToNextPage() {
      this.showTableData = false;
      this.currentPage++;
      this.tableLoader = true;
      setTimeout(() => {
        this.showTableData = true;
      }, 10);
    },
    clearInterval() {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }
    },
    reloadTable() {
      this.$root.$emit("bv::refresh::table", "email-template-table");
    },
    onAddEmailSettingClick() {
      EventBus.emit("onShowAddModal", "");
    },
    editEmailTemplate(data) {
      this.emailTemplateName = data.item.template_name;
      EventBus.emit("onShowEditModal", data);
    },
    dulicateEmailTemplate(data) {
      EventBus.emit("onShowDuplicateModal", data);
    },
    triggerEmailTemplate(data) {
      this.emailTemplateName = data.item.template_name;
      EventBus.emit("triggerEmailModal", data);
    },
    async getEmailTemplates() {
      let formData = {
        company_id: this.companyId,
        filter: this.filter,
        sort: `${this.sortBy}|${this.sortDesc ? "desc" : "asc"}`,
        per_page: this.perPage,
        page: this.currentPage
      };
      this.isLoading = true;
      let result = HTTP(
        "post",
        "v1/dynamic-templates/getclienttemplatelist",
        formData,
        makeAuthorizationHeader()
      );

      return result
        .then((response) => {
          this.last_page = response.data.last_page;
          const items = response.data.data;
          this.isLoading = false;
          this.fromRows = response.data.from;
          this.toRows = response.data.to;
          this.totalRows = response.data.total;
          return items;
        })
        .catch((error) => {
          this.isLoading = false;
          return [error];
        });
    },
    onShowDeactivateModal(data) {
      this.isActiveRow = data.is_active;
      this.showDeactivateModal = true;
      this.rowId = data.id;
      this.templateName = data.template_name;
    },
    onCloseDeactivateModal() {
      this.showDeactivateModal = false;
      this.rowId = null;
      this.isActiveRow = null;
      this.templateName = null;
    },
    onDeactivateRow() {
      this.isSubmitting = true;
      let formData = {
        is_active: this.isActiveRow == 0 ? 1 : 0,
        template_name: this.templateName,
        company_id: this.companyId,
        user_name: this.fullName,
        user_id: this.userId,
        comany_name: this.companyName
      };
      let result = HTTP(
        "put",
        `v1/dynamic-templates/update/${this.rowId}`,
        formData,
        makeAuthorizationHeader()
      );
      return result
        .then((response) => {
          this.$bvToast.toast(response.data.message, {
            title: "",
            variant: "success",
            autoHideDelay: 2000,
            appendToast: false
          });
          this.reloadTable();
          this.onCloseDeactivateModal();
          this.isSubmitting = false;
        })
        .catch((error) => {
          this.$bvToast.toast("Action did not performed", {
            title: "",
            variant: "danger",
            autoHideDelay: 2000,
            appendToast: false
          });
          this.isSubmitting = false;

          return [error];
        });
    }
  }
};
</script>

<style></style>
