import { customAttributesService } from "@/services";

export const actions = {
  async fetchCustomAttributesList({ commit }, payload) {
    try {
      commit("customAttributes/setCustomAttributeListIsLoading", true, {
        root: true
      });

      const { data = {} } = await customAttributesService.fetchCustomAttributes(
        payload
      );
      const attributeList = data?.response || [];

      commit("customAttributes/setCustomAttributesList", attributeList, {
        root: true
      });

      return data;
    } catch (error) {
      commit("customAttributes/setCustomAttributesList", [], {
        root: true
      });
      return error;
    } finally {
      commit("customAttributes/setCustomAttributeListIsLoading", false, {
        root: true
      });
    }
  }
};
