import {
  expectedDataTypes,
  expectedDataInstanceTypes,
  expectedDataTypesMapText,
  expectedDataEntitiesTypes,
  booleanOptions
} from "@/constants";
import {
  isCustomAttributeRiskScale,
  isCustomAttributeStringList,
  isCustomAttributeMultiSelectStringList,
  isExpectedDataPrimitiveType
} from "@/utils";

export const getters = {
  getExpectedDataDDQTasks: (state) =>
    state.expectedData.filter(
      ({ entity = "" } = {}) => entity === expectedDataTypes.DDQ_TASK
    ),
  getExpectedDataEntities: (state) =>
    state.expectedData.filter(({ entity = "" } = {}) =>
      [
        expectedDataTypes.DDQ_TASK,
        expectedDataTypes.THIRD_PARTY,
        expectedDataTypes.IDD_IDD_PLUS,
        expectedDataTypes.TRAINING_ASSIGNMENT,
        expectedDataTypes.EDD
      ].some((value) => value === entity)
    ),
  getExpectedDataRiskRatings: (state) =>
    state.expectedData.filter(({ subType = "" } = {}) =>
      isCustomAttributeRiskScale(subType)
    ),
  getExpectedDataStringLists: (state) =>
    state.expectedData.filter(({ subType = "" } = {}) =>
      isCustomAttributeStringList(subType)
    ),
  getExpectedDataMultiSelectStringLists: (state) =>
    state.expectedData.filter(({ subType = "" } = {}) =>
      isCustomAttributeMultiSelectStringList(subType)
    ),
  getExpectedDataPrimitiveTypes: (state) =>
    state.expectedData.filter(({ subType = "", type = "" } = {}) =>
      isExpectedDataPrimitiveType(subType || type)
    ),
  getExpectedDataGroupedOptions: (_, getters) => ({
    types: {
      ...getters.getExpectedDataPrimitiveTypes.reduce(
        (acc, expectedDataPrimitiveType) => {
          let type;
          if (expectedDataPrimitiveType.isArray) {
            type = expectedDataPrimitiveType.uniqueType;
          } else {
            type =
              expectedDataPrimitiveType.subType ||
              expectedDataPrimitiveType.type;
          }

          acc[type] = {
            text:
              expectedDataTypesMapText[type] || expectedDataPrimitiveType.name,
            value: type
          };

          return acc;
        },
        []
      ),
      ...getters.getExpectedDataEntities.reduce(
        (acc, expectedDataEntityType) => {
          acc[expectedDataEntityType.entity] = {
            text:
              expectedDataTypesMapText[expectedDataEntityType.entity] ||
              expectedDataEntityType.name,
            value: expectedDataEntityType.entity
          };
          return acc;
        },
        []
      ),
      ...(getters.getExpectedDataStringLists.length && {
        [expectedDataInstanceTypes.STRING_LIST]: {
          text: expectedDataTypesMapText[expectedDataInstanceTypes.STRING_LIST],
          value: expectedDataInstanceTypes.STRING_LIST
        }
      }),
      ...(getters.getExpectedDataRiskRatings.length && {
        [expectedDataInstanceTypes.RISK_RATING]: {
          text: expectedDataTypesMapText[expectedDataInstanceTypes.RISK_RATING],
          value: expectedDataInstanceTypes.RISK_RATING
        }
      }),
      ...(getters.getExpectedDataMultiSelectStringLists.length && {
        [expectedDataInstanceTypes.MULTI_SELECT_STRING_LIST]: {
          text: expectedDataTypesMapText[
            expectedDataInstanceTypes.MULTI_SELECT_STRING_LIST
          ],
          value: expectedDataInstanceTypes.MULTI_SELECT_STRING_LIST
        }
      })
    },
    subTypes: {
      [expectedDataInstanceTypes.STRING_LIST]:
        getters.getExpectedDataStringLists.map(({ name = "" } = {}) => ({
          text: name,
          value: name
        })),
      [expectedDataInstanceTypes.RISK_RATING]:
        getters.getExpectedDataRiskRatings.map(({ name = "" } = {}) => ({
          text: name,
          value: name
        })),
      [expectedDataInstanceTypes.MULTI_SELECT_STRING_LIST]:
        getters.getExpectedDataMultiSelectStringLists.map(
          ({ name = "" } = {}) => ({
            text: name,
            value: name
          })
        ),
      [expectedDataEntitiesTypes.DDQ_TASK]: getters.getExpectedDataDDQTasks.map(
        ({ name = "" } = {}) => ({
          text: name,
          value: name
        })
      )
    }
  }),
  getExpectedDataMapOptions: (state, _, rootState) =>
    state.expectedData.reduce(
      (mapOptions, expectedDataObject) => {
        if (expectedDataObject?.options) {
          mapOptions[expectedDataObject.subType] = expectedDataObject?.options;
        }
        return mapOptions;
      },
      {
        country: rootState.country.countries.map((country) => ({
          ...country,
          text: country.name,
          value: country.name
        })),
        boolean: booleanOptions
      }
    )
};
