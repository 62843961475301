import {
  actionKeys,
  actionStepType,
  emptyOption,
  expectedDataMappingOptions,
  expectedDataTypesMapText,
  inputType,
  operations,
  taskTemplateKeysDTO,
  triggerDataType,
  variableAssignmentOptions,
  variableAssignmentType,
  variableType
} from "@/constants";
import {
  getSelectedOption,
  isCustomAttributeMultiSelectStringList,
  isExpectedDataEntityType,
  isExpectedDataInstanceSubType,
  isExpectedDataPrimitiveType,
  makeMultiSelectType
} from "@/utils";
import TaskStep from "@/molecules/TaskStep/TaskStep";
import { mapGetters, mapState } from "vuex";
import { TaskStepClass } from "@/molecules/TaskStep/TaskStep.class";
import { makeOptionsForSelect } from "@/molecules/Select/Select.dto";
import { ExpectedDataMappingClass } from "@/molecules/ExpectedDataMapping/ExpectedDataMapping.class";
import { getSelectedValuesFromDestinationKey } from "@/organisms/AddEditUserEmailTemplate/AddEditUserEmailTemplate.logic";
import Input from "@/molecules/Input/Input";
import Select from "@/molecules/Select/Select";
import { makeMultiSelectSelectedValues } from "@/molecules/MultiSelect/MultiSelect.dto";

export default {
  data() {
    return {
      taskStepList: [],
      taskExpectedDataList: []
    };
  },
  components: {
    TaskStep,
    Input,
    Select
  },
  computed: {
    ...mapState({
      taskTemplates: (state) => state.taskTemplates.taskTemplates
    }),
    ...mapGetters({
      getDestinationBaseOptions: "userEmailTemplates/getDestinationBaseOptions",
      getTaskTemplatesOptions: "taskTemplates/getTaskTemplatesOptions",
      getTaskTemplateBasedById: "taskTemplates/getTaskTemplateBasedById",
      getExpectedDataMapOptions: "expectedData/getExpectedDataMapOptions"
    })
  },
  methods: {
    isExpectedDataEntityType,
    isExpectedDataInstanceSubType,
    isExpectedDataPrimitiveType,
    getSelectedOption,
    makeMultiSelectType,
    makeOptionsForSelect,
    getSelectedValuesFromDestinationKey,
    isCustomAttributeMultiSelectStringList,
    makeMultiSelectSelectedValues,
    deleteTaskStepIndexFromList(index) {
      this.taskStepList = this.taskStepList.filter(
        ({ stepIndex }) => stepIndex !== index
      );
      this.deleteTaskExpectedDataStepIndexFromList(index);
    },
    deleteTaskStepIdFromList({ id, stepIndex }) {
      this.taskStepList = this.taskStepList.filter(
        ({ taskStep }) => taskStep.id !== id
      );
      this.deleteTaskExpectedDataStepIndexFromList(stepIndex);
    },
    deleteTaskExpectedDataStepIndexFromList(index = -1) {
      this.taskExpectedDataList = this.taskExpectedDataList.filter(
        ({ stepIndex }) => stepIndex !== index
      );
    },
    getValueDataTypeForExisting({ entity = "", subType = "", type = "" } = {}) {
      return entity || subType || type;
    },
    getValueDataType({ expectedData = {}, isLiteral = true } = {}) {
      if (isLiteral) {
        return expectedData?.triggeringData?.data?.type;
      } else {
        return this.getValueDataTypeForExisting({
          entity: expectedData?.triggeringData?.data?.data?.data?.entity,
          subType: expectedData?.triggeringData?.data?.data?.data?.subType,
          type: expectedData?.triggeringData?.data?.data?.type
        });
      }
    },
    getValueFromCachedTaskExpectedData({ triggeringData }) {
      if (triggeringData.type === triggerDataType.PROXY) {
        return triggeringData.data.name;
      } else if (triggeringData.type === triggerDataType.LITERAL) {
        return triggeringData.data.data.value;
      } else if (triggeringData.type === triggerDataType.VARIABLE) {
        return triggeringData.data.data.data.name;
      }
    },
    makeTaskStepExpectedData({
      cachedTaskStepExpectedDataList = [],
      stepIndex = 0,
      taskTemplateId = 0
    } = {}) {
      const selectedTaskTemplate =
        this.getTaskTemplateBasedById(taskTemplateId);
      const taskStepExpectedDataInstances = this.makeEmptyTaskStepExpectedData({
        expectedDataDefinition: selectedTaskTemplate.expectedDataDefinition,
        stepIndex
      });

      cachedTaskStepExpectedDataList.forEach((cachedTaskStepExpectedData) => {
        const taskStepExpectedDataInstance = taskStepExpectedDataInstances.find(
          ({ expectedDataName }) =>
            expectedDataName ===
            cachedTaskStepExpectedData.triggeredExpectedDataVariableName
        );
        if (taskStepExpectedDataInstance) {
          const valueType = this.isLiteralValueType(
            cachedTaskStepExpectedData?.triggeringData?.type
          )
            ? variableAssignmentType.LITERAL
            : variableAssignmentType.ENTITY;
          this.taskExpectedDataChangeValueType({
            value: valueType,
            id: taskStepExpectedDataInstance?.id,
            stepIndex
          });
          const value = this.getValueFromCachedTaskExpectedData(
            cachedTaskStepExpectedData
          );
          if (Array.isArray(value)) {
            this.taskExpectedDataChangeValue({
              value: {
                eventType: operations.ADD,
                value
              },
              id: taskStepExpectedDataInstance?.id,
              stepIndex
            });
          } else {
            this.taskExpectedDataChangeValue({
              value,
              id: taskStepExpectedDataInstance?.id,
              stepIndex
            });
          }
        }
      });
      return taskStepExpectedDataInstances;
    },
    makeTaskExpectedDataAndExpendList({ options, stepIndex }) {
      const taskExpectedDataInstance = new ExpectedDataMappingClass(options);
      this.taskExpectedDataList.push({
        stepIndex,
        taskExpectedData: taskExpectedDataInstance
      });
      return taskExpectedDataInstance;
    },
    makeTaskExpectedDataForLiteral({ componentValue, componentOptions }) {
      return {
        component: Input,
        componentOptions: {
          ...componentOptions,
          value: componentValue
        }
      };
    },
    makeOptionsForExpectedDataMapping({
      stepIndex = 0,
      type = "",
      subType = "",
      isArray = false
    } = {}) {
      const options =
        this.getListOfAllOptionsPerEachStep[stepIndex]?.filter(
          (option) =>
            option?.type === type &&
            option?.subType === subType &&
            option?.isArray === isArray
        ) || [];
      return [
        {
          ...emptyOption,
          text: "Select..."
        },
        ...options
      ];
    },
    makeTaskExpectedDataForEntity({
      type = "",
      subType = "",
      isArray = false,
      componentValue = "",
      componentOptions = {},
      stepIndex = 0
    } = {}) {
      return {
        component: Select,
        componentOptions: {
          ...componentOptions,
          options: this.makeOptionsForSelect(
            componentValue,
            this.makeOptionsForExpectedDataMapping({
              stepIndex,
              type,
              subType: subType || type,
              isArray
            })
          )
        }
      };
    },
    makeEmptyTaskStep() {
      const taskStep = new TaskStepClass({});
      taskStep.setTaskTemplatesOptions(
        this.makeOptionsForSelect("", this.getTaskTemplatesOptions)
      );
      return taskStep;
    },
    updateTaskExpectedDataList() {
      this.taskExpectedDataList.forEach(({ taskExpectedData, stepIndex }) => {
        const { value: valueType } =
          taskExpectedData.getSelectedValueTypeOption();
        if (!this.isLiteralValueType(valueType)) {
          const { component, componentOptions } =
            this.makeTaskExpectedDataForEntity({
              type: taskExpectedData.expectedDataType,
              subType: taskExpectedData.expectedDataSubType,
              isArray: taskExpectedData.isExpectedDataArray,
              componentValue: taskExpectedData.componentValue,
              componentOptions: this.getSharedComponentOptions(stepIndex),
              stepIndex
            });

          taskExpectedData.setComponent(component);
          taskExpectedData.setComponentOptions(componentOptions);
        }
      });
    },
    makeSuccessTaskStep({ taskStep, stepIndex } = {}) {
      return {
        stepType: actionStepType.TASK,
        component: TaskStep,
        componentOptions: this.makeTaskStepAndExtendTaskStepList({
          functionData: taskStep,
          stepIndex
        })
      };
    },
    makeTaskStep({ functionData = {}, stepIndex } = {}) {
      const taskTemplateExpectedData = this.makeTaskStepExpectedData({
        cachedTaskStepExpectedDataList: functionData?.expectedDataMapping,
        taskTemplateId: functionData.templateId,
        stepIndex
      });
      const taskTemplatesOptions = this.makeOptionsForSelect(
        functionData?.templateId,
        this.getTaskTemplatesOptions
      );
      const taskTemplateSearchValue =
        taskTemplatesOptions.find(({ selected }) => selected)?.text || "";
      const assigneeList = functionData?.assignee
        ? [functionData?.assignee]
        : [];
      const defaultAssigneeValue =
        this.getSelectedValuesFromDestinationKey(assigneeList)
          ?.selectedValues[0] || "";
      const taskTemplateAssigneeOptions = this.makeOptionsForSelect(
        defaultAssigneeValue,
        this.getDestinationBaseOptions
      );
      const taskTemplateInformation = this.makeTaskTemplateInformation({
        cachedTaskStepExpectedDataList: functionData?.expectedDataMapping,
        taskTemplateId: functionData.templateId
      });
      return new TaskStepClass({
        comment: functionData?.comment,
        hasComment: !!functionData?.comment,
        taskTemplatesOptions,
        taskTemplateSearchValue,
        taskTemplateExpectedData,
        taskTemplateAssigneeOptions,
        taskTemplateInformation
      });
    },
    makeTaskTemplateInformation({
      cachedTaskStepExpectedDataList = [],
      taskTemplateId = 0
    } = {}) {
      if (taskTemplateId) {
        const message =
          "The task template expected data has changed, please save this configuration to align this and the action config";
        const selectedTaskTemplate =
          this.getTaskTemplateBasedById(taskTemplateId);

        if (
          selectedTaskTemplate.expectedDataDefinition?.length ===
          cachedTaskStepExpectedDataList?.length
        ) {
          const listToIterate = selectedTaskTemplate.expectedDataDefinition.map(
            ({ name }) => name
          );
          const listToCompare = cachedTaskStepExpectedDataList.map(
            ({ triggeredExpectedDataVariableName }) =>
              triggeredExpectedDataVariableName
          );
          const uniqueList = this.getUniqueList({
            listToIterate,
            listToCompare
          });
          return uniqueList.length ? message : "";
        } else {
          return message;
        }
      } else {
        return "";
      }
    },
    makeTaskStepAndExtendTaskStepList({
      functionData = {},
      stepIndex = this.functionSteps.length
    } = {}) {
      const taskStepInstance = this.makeTaskStep({
        functionData,
        stepIndex
      });
      this.taskStepList.push({
        stepIndex,
        taskStep: taskStepInstance
      });
      return taskStepInstance;
    },
    makeNewTaskStep(stepIndex = this.functionSteps.length - 1) {
      this.updateTaskStepsIndexes({
        editType: operations.ADD,
        index: stepIndex
      });
      this.functionSteps.splice(
        stepIndex,
        0,
        this.makeTaskStepAndExtendTaskStepList({
          functionData: {},
          stepIndex: this.addBlockIndex
        })
      );
    },
    makeSuccessTaskStepForIfBlock() {
      const { index: stepIndex, event } = this.successStepEvent;
      const successSetStep = this.makeSuccessTaskStep({
        stepIndex
      });
      if (event.property === actionKeys.ELSE_BLOCK) {
        this.functionSteps[stepIndex][event.property] = [
          ...(this.functionSteps[stepIndex]?.[event.property] || []),
          successSetStep
        ];
      } else {
        this.functionSteps[stepIndex][event.property][event.index][
          actionKeys.SUCCESS_STEPS
        ] = [
          ...(this.functionSteps[stepIndex]?.[event.property]?.[event.index]
            ?.successSteps || []),
          successSetStep
        ];
      }
    },
    updateTaskStepsIndexes({ editType, index }) {
      for (
        let taskStepsIndex = 0;
        taskStepsIndex < this.taskStepList.length;
        taskStepsIndex++
      ) {
        if (this.taskStepList[taskStepsIndex].stepIndex >= index) {
          if (
            editType === operations.ADD &&
            this.taskStepList[taskStepsIndex].stepIndex >= index
          ) {
            this.taskStepList[taskStepsIndex].stepIndex += 1;
          } else if (
            editType === operations.DELETE &&
            this.taskStepList[taskStepsIndex].stepIndex > index
          ) {
            this.taskStepList[taskStepsIndex].stepIndex -= 1;
          }
        }
      }
    },
    updateTaskExpectedDataIndexes({ editType, index }) {
      for (
        let taskExpectedDataIndex = 0;
        taskExpectedDataIndex < this.taskExpectedDataList.length;
        taskExpectedDataIndex++
      ) {
        if (
          this.taskExpectedDataList[taskExpectedDataIndex].stepIndex >= index
        ) {
          if (
            editType === operations.ADD &&
            this.taskExpectedDataList[taskExpectedDataIndex].stepIndex >= index
          ) {
            this.taskExpectedDataList[taskExpectedDataIndex].stepIndex += 1;
          } else if (
            editType === operations.DELETE &&
            this.taskExpectedDataList[taskExpectedDataIndex].stepIndex > index
          ) {
            this.taskExpectedDataList[taskExpectedDataIndex].stepIndex -= 1;
          }
        }
      }
    },
    getTaskStepBasedOnId(id) {
      return (
        this.taskStepList.find(({ taskStep }) => taskStep.id === id)
          ?.taskStep || {}
      );
    },
    getTaskStepExpectedDataBasedOnId(id) {
      return (
        this.taskExpectedDataList.find(
          ({ taskExpectedData }) => taskExpectedData.id === id
        )?.taskExpectedData || {}
      );
    },
    getExpectedDataValueDataType({
      isArray = false,
      type = "",
      subType = "",
      entity = ""
    } = {}) {
      let valueDataType = this.getValueDataTypeForExisting({
        type,
        subType,
        entity
      });
      return isArray && valueDataType === variableType.COUNTRY
        ? this.makeMultiSelectType(valueDataType)
        : valueDataType;
    },
    getTaskStepExpectedDataValueDataTypeText({
      valueDataType = "",
      templateName = ""
    } = {}) {
      return expectedDataTypesMapText[valueDataType] || templateName;
    },
    makeEmptyTaskStepExpectedData({
      expectedDataDefinition = [],
      stepIndex = 0
    } = {}) {
      return expectedDataDefinition.map((taskTemplateExpectedData) => {
        const isArray = taskTemplateExpectedData.isArray || false;
        const valueDataType = this.getExpectedDataValueDataType(
          taskTemplateExpectedData
        );
        const type =
          taskTemplateExpectedData?.entity || taskTemplateExpectedData?.type;
        const isMiddleSelectHidden = this.isExpectedDataEntityType(type);
        const subType = this.getValueDataTypeForExisting({
          type: taskTemplateExpectedData.type,
          subType: taskTemplateExpectedData.subType
        });
        const componentValue = "";
        const valueDataTypeOptions = this.makeOptionsForSelect(
          valueDataType,
          expectedDataMappingOptions
        );
        const sharedComponentOptions =
          this.getSharedComponentOptions(stepIndex);
        const valueDataTypeText = this.getTaskStepExpectedDataValueDataTypeText(
          { valueDataType, templateName: taskTemplateExpectedData.templateName }
        );

        const valueTypeOptions = this.makeOptionsForSelect(
          variableAssignmentType.ENTITY,
          variableAssignmentOptions
        );
        const isReadonly = true;
        const expectedDataName = taskTemplateExpectedData.name;
        const { component, componentOptions } =
          this.makeTaskExpectedDataForEntity({
            type,
            subType,
            isArray,
            componentValue,
            componentOptions: sharedComponentOptions,
            stepIndex
          });
        const taskExpectedDataInstance = this.makeTaskExpectedDataAndExpendList(
          {
            options: {
              expectedDataName,
              expectedDataType: type,
              expectedDataSubType: subType,
              isExpectedDataArray: isArray,
              valueDataType,
              valueDataTypeOptions,
              valueDataTypeText,
              valueTypeOptions,
              isReadonly,
              isMiddleSelectHidden,
              component,
              componentOptions,
              componentValue
            },
            stepIndex
          }
        );
        return taskExpectedDataInstance;
      });
    },
    setTaskStep({ event = {}, stepIndex = 0 } = {}) {
      this.deleteTaskExpectedDataStepIndexFromList(stepIndex);
      const taskStepInstance = this.getTaskStepBasedOnId(event.id);
      const selectedTaskTemplate = this.getTaskTemplateBasedById(event.event);
      const taskStepExpectedData = this.makeEmptyTaskStepExpectedData({
        expectedDataDefinition: selectedTaskTemplate.expectedDataDefinition,
        stepIndex
      });
      const taskTemplatesOptions = this.makeOptionsForSelect(
        event.event,
        this.getTaskTemplatesOptions
      );
      const taskTemplateSearchValue =
        taskTemplatesOptions.find(({ selected }) => selected)?.text || "";
      const defaultAssigneeValue =
        this.getSelectedValuesFromDestinationKey(
          selectedTaskTemplate?.taskDefaultAssignee
        )?.selectedValues[0] || "";
      const taskTemplateAssigneeOptions = this.makeOptionsForSelect(
        defaultAssigneeValue,
        this.getDestinationBaseOptions
      );
      taskStepInstance.setTaskTemplatesOptions(taskTemplatesOptions);
      taskStepInstance.setTaskTemplateSearchValue(taskTemplateSearchValue);
      taskStepInstance.setTaskTemplateExpectedData(taskStepExpectedData);
      taskStepInstance.setTaskTemplateAssigneeOptions(
        taskTemplateAssigneeOptions
      );
      taskStepInstance.setTaskTemplateError("");
      taskStepInstance.setTaskTemplateAssigneeError("");
    },
    resetTaskStep({ event, stepIndex } = {}) {
      const taskStepInstance = this.getTaskStepBasedOnId(event.id);
      const emptyTaskStepInstance = this.makeEmptyTaskStep();

      taskStepInstance.setComment(emptyTaskStepInstance.comment);
      taskStepInstance.setHasComment(emptyTaskStepInstance.hasComment);
      taskStepInstance.setTaskTemplatesOptions(
        emptyTaskStepInstance.taskTemplatesOptions
      );
      taskStepInstance.setTaskTemplateError(
        emptyTaskStepInstance.taskTemplateError
      );
      taskStepInstance.setTaskTemplateSearchValue(
        emptyTaskStepInstance.taskTemplateSearchValue
      );
      taskStepInstance.setTaskTemplateExpectedData(
        emptyTaskStepInstance.taskTemplateExpectedData
      );
      taskStepInstance.setTaskTemplateAssigneeOptions(
        emptyTaskStepInstance.taskTemplateAssigneeOptions
      );
      taskStepInstance.setTaskTemplateAssigneeError(
        emptyTaskStepInstance.taskTemplateAssigneeError
      );
      this.deleteTaskExpectedDataStepIndexFromList(stepIndex);
    },
    taskTemplateIdChange({ event = {}, stepIndex = 0 } = {}) {
      if (event.event) {
        this.setTaskStep({ event, stepIndex });
      } else {
        this.resetTaskStep({ event, stepIndex });
      }
    },
    onTaskStepChange({ event = {}, stepIndex = 0 } = {}) {
      if (event.name === taskTemplateKeysDTO.TEMPLATE_TASK_ID) {
        this.taskTemplateIdChange({ event, stepIndex });
      } else if (
        event.name === taskTemplateKeysDTO.TASK_TEMPLATE_EXPECTED_DATA
      ) {
        this.taskExpectedDataChange({ ...event.event, stepIndex });
      } else if (event.name === taskTemplateKeysDTO.TASK_DEFAULT_ASSIGNEE) {
        this.taskTemplateAssigneeChange(event);
      }
    },
    taskTemplateAssigneeChange({ id = 0, event = "" } = {}) {
      const taskStepInstance = this.getTaskStepBasedOnId(id);
      const taskTemplateAssigneeOptions = this.makeOptionsForSelect(
        event,
        this.getDestinationBaseOptions
      );
      taskStepInstance.setTaskTemplateAssigneeOptions(
        taskTemplateAssigneeOptions
      );
      taskStepInstance.setTaskTemplateAssigneeError("");
    },
    taskExpectedDataChange({ event, id, name, stepIndex }) {
      if (name === actionKeys.VALUE_TYPE) {
        this.taskExpectedDataChangeValueType({
          value: event,
          id,
          stepIndex
        });
      } else if (name === actionKeys.VALUE) {
        this.taskExpectedDataChangeValue({
          value: event,
          id,
          stepIndex
        });
      }
    },
    taskExpectedDataChangeValueType({
      value = "",
      id = 0,
      stepIndex = 0
    } = {}) {
      const taskExpectedDataInstance =
        this.getTaskStepExpectedDataBasedOnId(id);
      taskExpectedDataInstance.setValueTypeOptions(
        this.makeOptionsForSelect(value, variableAssignmentOptions)
      );
      this.taskExpectedDataChangeValue({ stepIndex, id });
    },
    taskExpectedDataChangeValueForLiteral({
      id,
      expectedDataSubType,
      value
    } = {}) {
      if (this.isExpectedDataPrimitiveType(expectedDataSubType)) {
        this.taskExpectedDataChangeValueForPrimitiveTypeLiteral({
          id,
          value
        });
      } else if (
        this.isExpectedDataInstanceSubType({ value: expectedDataSubType })
      ) {
        this.taskExpectedDataChangeValueForInstanceTypeLiteral({
          id,
          value
        });
      }
    },
    taskExpectedDataChangeValueForPrimitiveTypeLiteral({
      id = 0,
      value = ""
    } = {}) {
      const taskExpectedDataInstance =
        this.getTaskStepExpectedDataBasedOnId(id);
      const { expectedDataSubType, isExpectedDataArray } =
        taskExpectedDataInstance;
      if (
        this.isStringValueDataType(expectedDataSubType) ||
        this.isNumericValueDataType(expectedDataSubType) ||
        this.isDatetimeValueDataType(expectedDataSubType)
      ) {
        this.setTaskExpectedDataValueForInput({ id, value });
      } else if (this.isBooleanValueDataType(expectedDataSubType)) {
        this.setTaskExpectedDataValueForSelect({ id, value });
      } else if (this.isCountryValueDataType(expectedDataSubType)) {
        if (isExpectedDataArray) {
          this.setTaskExpectedDataValueForMultiSelect({ id, value });
        } else {
          this.setTaskExpectedDataValueForSelect({ id, value });
        }
      }
    },
    setTaskExpectedDataValueForSelect({ id = 0, value = "" } = {}) {
      const taskExpectedDataInstance =
        this.getTaskStepExpectedDataBasedOnId(id);
      const { component, componentOptions } = this.makeSelectInput({
        options: this.makeOptionsForSelect(value, [
          { ...emptyOption, text: "Select..." },
          ...this.getExpectedDataMapOptions[
            taskExpectedDataInstance.expectedDataSubType
          ]
        ])
      });
      taskExpectedDataInstance.setComponentValue(value);
      taskExpectedDataInstance.setComponent(component);
      taskExpectedDataInstance.setComponentOptions(componentOptions);
    },
    taskExpectedDataChangeValueForInstanceTypeLiteral({
      id = 0,
      value = ""
    } = {}) {
      const taskExpectedDataInstance =
        this.getTaskStepExpectedDataBasedOnId(id);
      if (
        this.isCustomAttributeMultiSelectStringList(
          taskExpectedDataInstance.expectedDataSubType
        )
      ) {
        this.setTaskExpectedDataValueForMultiSelect({ id, value });
      } else {
        this.setTaskExpectedDataValueForSelect({ id, value });
      }
    },
    setTaskExpectedDataValueForMultiSelect({ id = 0, value = {} } = {}) {
      const taskExpectedDataInstance =
        this.getTaskStepExpectedDataBasedOnId(id);
      const selectedValues = this.makeMultiSelectSelectedValues({
        options: taskExpectedDataInstance.componentOptions.options,
        eventType: value.eventType,
        value: value.value
      });
      const { component, componentOptions } = this.makeMultiSelectInput(
        selectedValues,
        selectedValues,
        this.getExpectedDataMapOptions[
          taskExpectedDataInstance.expectedDataSubType
        ]
      );
      taskExpectedDataInstance.setComponentValue(selectedValues);
      taskExpectedDataInstance.setComponent(component);
      taskExpectedDataInstance.setComponentOptions(componentOptions);
    },
    setTaskExpectedDataValueForInput({ id = 0, value = "" } = {}) {
      let result;
      const taskExpectedDataInstance =
        this.getTaskStepExpectedDataBasedOnId(id);
      const { expectedDataSubType } = taskExpectedDataInstance;
      if (this.isStringValueDataType(expectedDataSubType)) {
        result = this.makeInputComponent(value, inputType.STRING);
      } else if (
        this.isNumericValueDataType(expectedDataSubType) ||
        this.isDatetimeValueDataType(expectedDataSubType)
      ) {
        result = this.makeInputComponent(value, inputType.NUMBER);
      }

      taskExpectedDataInstance.setComponent(result.component);
      taskExpectedDataInstance.setComponentOptions(result.componentOptions);
      taskExpectedDataInstance.setComponentValue(value);
    },
    taskExpectedDataChangeValueForEntity({
      id = 0,
      stepIndex = 0,
      value = ""
    } = {}) {
      const taskExpectedDataInstance =
        this.getTaskStepExpectedDataBasedOnId(id);
      const { component, componentOptions } =
        this.makeTaskExpectedDataForEntity({
          type: taskExpectedDataInstance.expectedDataType,
          subType: taskExpectedDataInstance.expectedDataSubType,
          isArray: taskExpectedDataInstance.isExpectedDataArray,
          componentValue: value,
          componentOptions: this.getSharedComponentOptions(stepIndex),
          stepIndex
        });
      taskExpectedDataInstance.setComponentValue(value);
      taskExpectedDataInstance.setComponent(component);
      taskExpectedDataInstance.setComponentOptions(componentOptions);
    },
    taskExpectedDataChangeValue({ value = "", stepIndex = 0, id = 0 } = {}) {
      const taskExpectedDataInstance =
        this.getTaskStepExpectedDataBasedOnId(id);
      const selectedValueType =
        this.getSelectedOption(taskExpectedDataInstance.valueTypeOptions)
          ?.value || "";
      if (this.isLiteralValueType(selectedValueType)) {
        this.taskExpectedDataChangeValueForLiteral({
          id,
          expectedDataSubType: taskExpectedDataInstance.expectedDataSubType,
          value
        });
      } else if (this.isEntityValueType(selectedValueType)) {
        this.taskExpectedDataChangeValueForEntity({ id, stepIndex, value });
      }
    },
    amendSuccessTaskStep({ event, stepIndex } = {}) {
      this.onTaskStepChange({ event: event.event, stepIndex });
    }
  }
};
