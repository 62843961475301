<template>
  <div>
    <BaseLoader v-if="isUBODataLoading" />
    <b-tabs
      v-else-if="hasUBOData"
      small
      nav-class="third-party-ultimate-beneficial-owners-tab__tabs"
      data-test-id="third-party-ultimate-beneficial-owners-tab"
    >
      <b-tab
        class="third-party-ultimate-beneficial-owners-tab__tab"
        data-test-id="third-party-ultimate-beneficial-owners-tab__tab"
        title="Table"
      >
        <UltimateBeneficialOwnersList
          :third-party-id="thirdParty.id"
          :beneficial-owners-list="thirdPartyUBOList.beneficialOwners"
          :relationships="thirdPartyUBOList.relationships"
        />
      </b-tab>
      <b-tab
        class="third-party-ultimate-beneficial-owners-tab__tab"
        data-test-id="third-party-ultimate-beneficial-owners-tab_tab"
        title="Chart"
      >
        <UltimateBeneficialOwnersVisualisationChart
          :relationships="thirdPartyUBOList.relationships"
          :beneficial-owners="thirdPartyUBOList.beneficialOwners"
          :third-party="thirdParty"
        />
      </b-tab>
    </b-tabs>
    <div
      v-else
      class="third-party-ultimate-beneficial-owners-tab__not-available-text"
      data-test-id="third-party-ultimate-beneficial-owners-tab__not-available-text"
    >
      <BaseInfoBox
        :icon-options="defaultIcons.INFO"
        :text="thirdPartyUBOError"
        :theme="themes.INFO"
      />
    </div>
  </div>
</template>

<script>
import UltimateBeneficialOwnersList from "@/organisms/UltimateBeneficialOwnersList/UltimateBeneficialOwnersList";
import BaseLoader from "@/atoms/BaseLoader/BaseLoader";
import { mapActions, mapState } from "vuex";
import { defaultIcons, themes } from "@/constants";
import UltimateBeneficialOwnersVisualisationChart from "@/organisms/UltimateBeneficialOwnersVisualisationChart/UltimateBeneficialOwnersVisualisationChart";
import BaseInfoBox from "@/atoms/BaseInfoBox/BaseInfoBox.vue";
import { isEqual } from "lodash";
import { makeUBOQueryParameters } from "@/organisms/ThirdPartyUltimateBeneficialOwnersTab/ThirdPartyUltimateBeneficialOwnersTab.logic";

export default {
  name: "ThirdPartyUltimateBeneficialOwnersTab",
  components: {
    BaseInfoBox,
    UltimateBeneficialOwnersVisualisationChart,
    BaseLoader,
    UltimateBeneficialOwnersList
  },
  data() {
    return {
      defaultIcons,
      themes
    };
  },
  props: {
    thirdParty: {
      type: Object,
      default: () => ({})
    },
    companyId: {
      type: Number,
      default: 0
    }
  },
  watch: {
    companyId() {
      this.fetchThirdPartiesUBOs(
        makeUBOQueryParameters({
          thirdPartyId: this.thirdParty.id,
          companyId: this.companyId
        })
      );
    },
    thirdParty: {
      deep: true,
      handler(newValue, oldValue) {
        if (!isEqual(newValue, oldValue)) {
          this.fetchThirdPartiesUBOs(
            makeUBOQueryParameters({
              thirdPartyId: newValue.id,
              companyId: this.companyId
            })
          );
        }
      }
    }
  },
  computed: {
    ...mapState({
      isUBODataLoading: (state) => state.thirdParty.isUBODataLoading,
      thirdPartyUBOList: (state) => state.thirdParty.thirdPartyUBOList,
      thirdPartyUBOError: (state) => state.thirdParty.thirdPartyUBOError
    }),
    hasUBOData() {
      return !!this.thirdPartyUBOList?.beneficialOwners?.length;
    }
  },
  created() {
    if (!this.hasUBOData && this.companyId && this.thirdParty?.id) {
      this.fetchThirdPartiesUBOs(
        makeUBOQueryParameters({
          thirdPartyId: this.thirdParty.id,
          companyId: this.companyId
        })
      );
    }
  },
  methods: {
    ...mapActions({
      fetchThirdPartiesUBOs: "thirdParty/fetchThirdPartyUBOs"
    })
  }
};
</script>

<style lang="scss">
.third-party-ultimate-beneficial-owners-tab {
  &__tabs {
    background: $grey;
    margin-bottom: 20px;
  }

  &__not-available-text {
    text-align: center;
  }
}
</style>
