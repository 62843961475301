export const mutations = {
  setOnboardingTasks(state, payload) {
    state.onboardingTasks = payload;
  },
  setOnboardingTask(state, payload) {
    state.onboardingTask = payload;
  },
  resetOnboardingTasks(state) {
    state.onboardingTasks = [];
  },
  resetOnboardingTask(state) {
    state.onboardingTask = {};
  },
  setIsOnboardingTasksLoading(state, payload) {
    state.isOnboardingTasksLoading = payload;
  }
};
