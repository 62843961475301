import { permissionsService } from "@/services";

export const actions = {
  async fetchFeaturePermissionsData({ commit, rootState }) {
    try {
      commit("permissions/setIsFeaturePermissionsLoading", true, {
        root: true
      });

      const { data } = await permissionsService.fetchCompanyPermissions(
        rootState.company.companyId
      );

      commit("permissions/setFeaturePermissions", data?.permissions, {
        root: true
      });

      return data;
    } finally {
      commit("permissions/setIsFeaturePermissionsLoading", false, {
        root: true
      });
    }
  },
  async updateFeaturePermissionsData({ commit, rootState }, payload) {
    try {
      commit("permissions/setIsUpdatingFeaturePermissions", true, {
        root: true
      });

      await permissionsService.updateCompanyPermissions({
        companyId: rootState.company.companyId,
        payload
      });

      commit("permissions/setFeaturePermissions", payload, {
        root: true
      });

      return true;
    } catch {
      return false;
    } finally {
      commit("permissions/setIsUpdatingFeaturePermissions", false, {
        root: true
      });
    }
  },
  async fetchUserPermissionsData({ commit, rootState }) {
    try {
      commit("permissions/setIsUserPermissionsLoading", true, {
        root: true
      });

      const { data } = await permissionsService.fetchUserPermissions(
        rootState.company.companyId
      );

      commit("permissions/setPermissions", data?.permissions, {
        root: true
      });

      return data;
    } finally {
      commit("permissions/setIsUserPermissionsLoading", false, {
        root: true
      });
    }
  },
  async fetchUserPermissionsSubsetData({ commit, rootState }, payload) {
    try {
      commit("permissions/setIsUserPermissionsSubsetLoading", true, {
        root: true
      });

      const { data } = await permissionsService.fetchUserPermissionsSubset({
        companyId: rootState.company.companyId,
        payload
      });
      return data;
    } finally {
      commit("permissions/setIsUserPermissionsSubsetLoading", false, {
        root: true
      });
    }
  }
};
