<template>
  <section class="roles">
    <BaseLoader v-if="isLoadingTableData" />
    <Modal
      class="roles__modal"
      v-if="isModalVisible"
      title="Delete role"
      @close-modal="closeModal"
    >
      <div class="roles__modal-text">
        <BaseText :text="getModalText" />
      </div>
      <template #left>
        <CallToAction
          type="button"
          theme="error-inverse"
          value="Cancel"
          data-test-id="roles__modal-cancel-cta"
          @click="onCancelDeleteRole"
        />
      </template>
      <template #right>
        <CallToAction
          type="button"
          value="Delete"
          data-test-id="roles__modal-delete-cta"
          @click="onDeleteRole"
        />
      </template>
    </Modal>
    <div class="roles__operations">
      <CallToAction
        theme="strong"
        value="Add"
        data-test-id="roles__operations--add"
        @click="onCTAAdd"
      />
    </div>
    <BaseText
      :text="operationFeedback"
      theme="success-inverse"
      data-test-id="roles__operations-feedback"
    />
    <InputErrorMessage
      :error="errorMessagesApi"
      data-test-id="roles__operations-error-message-api"
    />
    <StickyTable
      v-if="isDataAvailable"
      caption="Roles"
      class="roles__table"
      data-test-id="roles__table"
      :table-headers="rolesHeaders"
      :table-rows="rolesRows"
      @table-row-click="onTableRowClick"
    />
    <BaseText
      v-else
      text="You do not have any roles. Click the Add button to create one."
      data-test-id="roles__table-fallback-message"
    />
  </section>
</template>

<script>
import StickyTable from "@/molecules/StickyTable/StickyTable";
import BaseText from "@/atoms/BaseText/BaseText";
import InputErrorMessage from "@/molecules/InputErrorMessage/InputErrorMessage";
import {
  externalUrls,
  operations,
  roleEvents,
  roleTableHeaders,
  typographySize,
  urls,
  userRolesKeysDTO
} from "@/constants";
import BaseLoader from "@/atoms/BaseLoader/BaseLoader";
import { mapActions, mapState } from "vuex";
import DropDownList from "@/molecules/DropDownList/DropDownList";
import Modal from "@/molecules/Modal/Modal";
import CallToAction from "@/atoms/CallToAction/CallToAction";
import { hasStatus404, hasStatus500, isNotAuthorized } from "@/utils";

export default {
  name: "Roles",
  components: {
    CallToAction,
    Modal,
    BaseLoader,
    InputErrorMessage,
    BaseText,
    StickyTable
  },
  data() {
    return {
      externalUrls,
      operationFeedback: "",
      errorMessagesApi: "",
      isLoadingTableData: false,
      isModalVisible: false,
      selectedRole: {
        id: "",
        name: ""
      },
      tableHeaders: [
        userRolesKeysDTO.NAME,
        userRolesKeysDTO.DESCRIPTION,
        userRolesKeysDTO.ACTIONS
      ]
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.company.companyId,
      roles: (state) => state.roles.roles
    }),
    isDataAvailable() {
      return !!this.rolesHeaders.length && !!this.rolesRows.length;
    },
    rolesHeaders() {
      return this.tableHeaders.map((headerName) => ({
        value: headerName,
        component: BaseText,
        componentOptions: {
          tag: "span",
          text: roleTableHeaders[headerName],
          size: typographySize.BODY_TEXT_BOLD,
          styles: {
            margin: "0"
          }
        },
        styles: {
          verticalAlign: "top",
          background: "white",
          padding: "5px"
        }
      }));
    },
    rolesRows() {
      return this.roles?.length
        ? this.roles.map((role, index) =>
            this.tableHeaders.reduce((tableRow, tableKey) => {
              if (
                tableKey === userRolesKeysDTO.ACTIONS &&
                !role[userRolesKeysDTO.IS_SYSTEM]
              ) {
                tableRow[tableKey] = {
                  component: DropDownList,
                  componentOptions: {
                    dropDownList: [
                      {
                        value: JSON.stringify({
                          operation: operations.EDIT,
                          id: this.roles[index][userRolesKeysDTO.ID]
                        }),
                        text: "Edit"
                      },
                      {
                        value: JSON.stringify({
                          operation: operations.DELETE,
                          id: this.roles[index][userRolesKeysDTO.ID],
                          name: this.roles[index][userRolesKeysDTO.NAME]
                        }),
                        text: "Delete"
                      }
                    ],
                    style: {
                      display: "inline-block"
                    }
                  }
                };
              } else {
                const isMenuDisabled =
                  role[userRolesKeysDTO.IS_SYSTEM] &&
                  tableKey === userRolesKeysDTO.ACTIONS;

                tableRow[tableKey] = {
                  component: BaseText,
                  componentOptions: {
                    tag: "span",
                    text: isMenuDisabled ? "" : role[tableKey],
                    styles: {
                      padding: "0px 5px"
                    }
                  }
                };
              }

              return tableRow;
            }, {})
          )
        : [];
    },
    getModalText() {
      return `Are you sure you want to delete the role, ${this.selectedRole.name}?`;
    }
  },
  created() {
    this.operationFeedback = history.state?.message || "";
  },
  methods: {
    ...mapActions({
      deleteRole: "roles/deleteRole"
    }),
    onCancelDeleteRole() {
      this.resetSelectedRole();
      this.closeModal();
    },
    closeModal() {
      this.isModalVisible = false;
    },
    onCTAAdd() {
      this.$router.push(urls.ADD_ROLE);
    },
    onTableRowClick(tableRowObj) {
      const { operation, id, name } = JSON.parse(tableRowObj);
      if (operation === operations.EDIT) {
        this.$emit(roleEvents.EDIT_ROLE, id);
        this.$router.push(urls.EDIT_ROLE_ID(id));
      } else if (operation === operations.DELETE) {
        this.setSelectedRole(id, name);
        this.isModalVisible = true;
      }
    },
    setSelectedRole(id = "", name = "") {
      this.selectedRole.id = id;
      this.selectedRole.name = name;
    },
    resetSelectedRole() {
      this.setSelectedRole("", "");
    },
    async onDeleteRole() {
      this.closeModal();
      this.isLoadingTableData = true;
      this.operationFeedback = "";
      this.errorMessagesApi = "";

      try {
        await this.deleteRole(this.selectedRole.id);
        this.operationFeedback = `${this.selectedRole.name} deleted successfully`;
      } catch (error) {
        this.deleteRoleFailure(error);
      } finally {
        this.resetSelectedRole();
        this.isLoadingTableData = false;
      }
    },
    deleteRoleFailure({ response } = {}) {
      if (hasStatus500(response)) {
        this.errorMessagesApi = "There is a server error.";
      } else if (hasStatus404(response)) {
        this.errorMessagesApi = "Failed to find role and delete it.";
      } else if (!isNotAuthorized(response) && response?.status) {
        this.errorMessagesApi = `There is an unknown error with status of ${response.status}. Please contact support.`;
      } else {
        this.errorMessagesApi = `There is an unknown error. Please contact support.`;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.roles {
  height: calc(100vh - 130px);
  overflow-y: scroll;
  padding: 0 20px 0 0;
  text-align: left;

  &__operations {
    display: flex;
    justify-content: flex-end;
  }

  &__modal-text {
    padding: 20px 20px 0;
  }
}
</style>
