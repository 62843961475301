import { userService } from "@/services";

export const actions = {
  async fetchRoles({ commit, rootState }, isLoadingSuppressed = false) {
    try {
      if (!isLoadingSuppressed) {
        commit("roles/setIsLoadingRoles", true, { root: true });
      }
      const data = await userService.fetchRoles(rootState.company.companyId);
      commit("roles/setRoles", data, { root: true });
      // eslint-disable-next-line no-useless-catch
    } catch (error) {
      throw error;
    } finally {
      if (!isLoadingSuppressed) {
        commit("roles/setIsLoadingRoles", false, { root: true });
      }
    }
  },
  async fetchRole({ rootState }, roleId) {
    return userService.fetchRole(rootState.company.companyId, roleId);
  },
  async createRole({ rootState, dispatch }, role) {
    const data = await userService.createRole(
      rootState.company.companyId,
      role
    );
    await dispatch("roles/fetchRoles", true, { root: true });
    return data;
  },
  async updateRole({ rootState, dispatch }, { role, roleId }) {
    const data = await userService.updateRole(
      rootState.company.companyId,
      role,
      roleId
    );
    await dispatch("roles/fetchRoles", true, { root: true });
    return data;
  },
  async deleteRole({ rootState, dispatch }, roleId) {
    const data = await userService.deleteRole(
      rootState.company.companyId,
      roleId
    );
    await dispatch("roles/fetchRoles", true, { root: true });
    return data;
  }
};
