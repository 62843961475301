const expectedDataKeysDTO = {
  KEY: "key",
  ELEMENTS: "elements",
  SECTIONS: "sections",
  TITLE: "title",
  ID: "id",
  SUB_TYPE: "subType",
  NAME: "name",
  ATTRIBUTES: "attributes",
  TYPE: "type",
  DATA: "data",
  ENTITY: "entity",
  CONTEXT: "context",
  FORM_ID: "formId",
  PROXY_NAME: "proxyName",
  ATTRIBUTE_NAME: "attributeName",
  REFERENCE_URL: "referenceUrl",
  IS_READ_ONLY: "isReadOnly",
  IS_ENUM: "isEnum",
  IS_ARRAY: "isArray",
  UNIQUE_TYPE: "uniqueType",
  TEMPLATE_TYPE: "templateType",
  TEMPLATE_NAME: "templateName",
  TEMPLATE_IDENTIFIER: "templateIdentifier",
  IDENTIFIER: "identifier",
  IS_CUSTOM_ATTRIBUTE: "isCustomAttribute",
  OPTIONS: "options",
  REFERENCES: "references",
  TEXT: "text",
  VALUE: "value",
  COMPANY_ID: "companyId",
  DDQ_ID: "ddqId",
  EXPECTED_DATA_DEFINITION: "expectedDataDefinition",
  VARIABLES: "variables",
  COURSE_ID: "courseId",
  EDD_REPORT_TYPE_ID: "eddReportTypeId"
};

const expectedDataKeysAPIDTO = {
  KEY: "key",
  ELEMENTS: "elements",
  SECTIONS: "sections",
  TITLE: "title",
  ID: "id",
  SUB_TYPE: "sub_type",
  NAME: "name",
  ATTRIBUTES: "attributes",
  TYPE: "type",
  DATA: "data",
  ENTITY: "entity",
  CONTEXT: "context",
  FORM_ID: "form_id",
  PROXY_NAME: "proxy_name",
  ATTRIBUTE_NAME: "attribute_name",
  REFERENCE_URL: "reference_url",
  IS_READ_ONLY: "is_read_only",
  IS_ENUM: "is_enum",
  IS_ARRAY: "is_array",
  TEMPLATE_TYPE: "template_type",
  TEMPLATE_NAME: "template_name",
  TEMPLATE_IDENTIFIER: "template_identifier",
  IDENTIFIER: "identifier",
  IS_CUSTOM_ATTRIBUTE: "is_custom_attribute",
  OPTIONS: "options",
  REFERENCES: "references",
  TEXT: "text",
  VALUE: "value",
  COMPANY_ID: "company_id",
  DDQ_ID: "ddq_id",
  EXPECTED_DATA_DEFINITION: "expected_data_definition",
  VARIABLES: "variables",
  COURSE_ID: "course_id",
  EDD_REPORT_TYPE_ID: "edd_report_type_id"
};

export { expectedDataKeysDTO, expectedDataKeysAPIDTO };
