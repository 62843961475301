import { productService } from "@/services";
import { infoMessages } from "@/constants";

export const actions = {
  async fetchPlatformProductList({ commit, rootState }) {
    const { tenantId } = rootState.onboardingClient.client;
    if (tenantId) {
      try {
        commit("product/setIsLoadingProductList", true, {
          root: true
        });
        const { data = [] } = await productService.fetchPlatformProductList({
          tenantId
        });

        commit("product/setPlatformProductList", data, {
          root: true
        });

        if (!data?.length) {
          return infoMessages.thirdParty.NO_TRAINING_LIST_FEEDBACK;
        }
      } finally {
        commit("product/setIsLoadingProductList", false, {
          root: true
        });
      }
    } else {
      return infoMessages.thirdParty.NO_TRAINING_LIST_FEEDBACK;
    }
  },
  async fetchAvailableProductList({ commit }) {
    const { data = [] } = await productService.fetchAvailableProductList();

    commit("product/setAvailableProductList", data, {
      root: true
    });
  },
  async assignAvailableProductToPlatform(
    { dispatch, rootState },
    { courseIds }
  ) {
    const { tenantId } = rootState.onboardingClient.client;
    await productService.assignAvailableProductToPlatform({
      tenantId,
      courseIds
    });

    await dispatch(
      "product/fetchPlatformProductList",
      { tenantId },
      { root: true }
    );
  }
};
