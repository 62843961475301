<template>
  <div class="questions-reference">
    <InputErrorMessage v-if="errorMessageApi" :error="errorMessageApi" />
    <div v-else>
      <BaseLoader v-if="isLoading" />
      <div v-else-if="references">
        <h1>{{ references.title }}</h1>
        <div v-for="section in references.sections" :key="section.id">
          <h2>{{ section.title }}</h2>
          <div v-for="element in section.elements" :key="element.id">
            <BaseText
              class="questions-reference__element-key"
              :text="element.key"
              :size="typographySize.BODY_TEXT_BOLD"
            />
            <BaseText :text="element.value" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { typographySize } from "@/constants";
import InputErrorMessage from "@/molecules/InputErrorMessage/InputErrorMessage";
import BaseLoader from "@/atoms/BaseLoader/BaseLoader";
import BaseText from "@/atoms/BaseText/BaseText";
import { makeUUID } from "@/utils";

export default {
  name: "QuestionsReference",
  props: {
    id: {
      default: null
    }
  },
  data() {
    return {
      isLoading: false,
      expectedDataIndex: -1,
      errorMessageApi: "",
      typographySize
    };
  },
  components: {
    InputErrorMessage,
    BaseLoader,
    BaseText
  },
  computed: {
    ...mapState({
      expectedData: (state) => state.expectedData.expectedData
    }),
    references() {
      return this.expectedData?.[this.expectedDataIndex]?.references;
    }
  },
  methods: {
    makeUUID,
    ...mapActions({
      fetchEntitiesDynamicReference:
        "expectedData/fetchEntitiesDynamicReference"
    }),
    async fetchEntitiesDynamicReferenceWrapper() {
      try {
        this.isLoading = true;
        this.errorMessageApi = "";
        this.expectedDataIndex = await this.fetchEntitiesDynamicReference(
          parseInt(this.id)
        );
      } catch {
        this.errorMessageApi = "Could not retrieve the questions reference.";
      } finally {
        this.isLoading = false;
      }
    }
  },
  async created() {
    await this.fetchEntitiesDynamicReferenceWrapper();
  }
};
</script>

<style lang="scss" scoped>
.questions-reference {
  height: calc(100vh - 130px);
  overflow-y: scroll;
  padding: 0 20px 0 0;
  text-align: left;

  &:deep(.questions-reference__element-key) {
    margin: 0;
  }
}
</style>
