<template>
  <div class="third-party-profile">
    <BaseLoader v-if="isLoadingThirdPartyDetails" />
    <template v-else>
      <BaseInfoBox
        v-if="feedbackMessage"
        :text="feedbackMessage"
        :theme="themes.INFO"
        :icon-options="defaultIcons.INFO"
      />
      <BaseInfoBox
        v-if="errorMessage"
        :text="errorMessage"
        :theme="themes.ERROR"
        :icon-options="defaultIcons.HIGH"
      />
      <ProfileHeader
        :heading="thirdPartyDetails.comp_name_eng || ''"
        :subheading="thirdPartyDetails.country || ''"
      />
      <ThirdPartyRiskAlerts />
    </template>
  </div>
</template>

<script>
import ThirdPartyRiskAlerts from "@/organisms/ThirdPartyRiskAlerts/ThirdPartyRiskAlerts";
import ProfileHeader from "@/molecules/ProfileHeader/ProfileHeader";
import BaseInfoBox from "@/atoms/BaseInfoBox/BaseInfoBox";
import BaseLoader from "@/atoms/BaseLoader/BaseLoader";
import { defaultIcons, themes } from "@/constants";
import { mapActions, mapState } from "vuex";
import { isEmpty } from "lodash";

export default {
  name: "ThirdPartyProfileTemplate",
  components: { BaseLoader, BaseInfoBox, ProfileHeader, ThirdPartyRiskAlerts },
  data() {
    return {
      themes,
      defaultIcons,
      feedbackMessage: "",
      errorMessage: ""
    };
  },
  computed: {
    ...mapState({
      thirdPartyDetails: (state) => state.thirdParty.thirdPartyDetails,
      isLoadingThirdPartyDetails: (state) =>
        state.thirdParty.isLoadingThirdPartyDetails
    })
  },
  async created() {
    if (isEmpty(this.thirdPartyDetails)) {
      await this.fetchThirdPartyDetails(this.$route.params.id);
    }
  },
  methods: {
    ...mapActions({
      fetchThirdPartyDetails: "thirdParty/fetchThirdPartyDetails"
    })
  }
};
</script>

<style scoped lang="scss">
.third-party-profile {
  padding: $spacing24 0;
}
</style>
